import { FC } from "react";
interface SettingIconProps {
  width?: string;
  height?: string;

}
export const BearIcon: FC<SettingIconProps> = ({
  width = "312px",
  height = "296px",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 312 296"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      
    >
      <g clipPath="url(#clip0_195_50053)">
        <path
          d="M128.305 59.6927L127.473 58.8908C127.658 58.6981 146.05 39.7129 167.214 34.4124L167.495 35.5342C146.654 40.7498 128.489 59.4999 128.305 59.6888V59.6927Z"
          fill="#5E8474"
        />
        <path
          d="M147.759 44.0804L146.743 43.5369C146.962 43.1283 152.171 33.4795 159.421 28.5492L160.072 29.5052C153.053 34.2775 147.813 43.9841 147.763 44.0843L147.759 44.0804Z"
          fill="#5E8474"
        />
        <path
          d="M143.439 49.9167C141.079 49.9167 139.635 49.828 139.582 49.8242L139.659 48.6716C139.839 48.6831 157.985 49.8126 171.722 43.8299L172.184 44.89C162.058 49.2999 149.615 49.9167 143.439 49.9167Z"
          fill="#5E8474"
        />
        <path
          d="M161.954 28.3988C161.954 29.748 160.861 30.8428 159.513 30.8428C158.166 30.8428 157.073 29.748 157.073 28.3988C157.073 27.0496 158.166 25.9548 159.513 25.9548C160.861 25.9548 161.954 27.0496 161.954 28.3988Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M159.949 30.0798C157.484 30.0798 157.415 26.6451 159.406 25.9628C158.108 26.0207 157.073 27.0885 157.073 28.3991C157.073 29.7098 158.166 30.8431 159.513 30.8431C160.753 30.8431 161.773 29.9179 161.931 28.7191C161.646 29.4746 160.977 30.0798 159.949 30.0798Z"
            fill="#F4627D" 
          />
        </g>
        <path
          opacity="0.39"
          d="M160.595 29.2238C161.035 29.2238 161.392 28.8665 161.392 28.4258C161.392 27.9851 161.035 27.6278 160.595 27.6278C160.155 27.6278 159.798 27.9851 159.798 28.4258C159.798 28.8665 160.155 29.2238 160.595 29.2238Z"
          fill="white"
        />
        <path
          d="M169.239 35.3491C169.239 36.6983 168.145 37.7931 166.798 37.7931C165.45 37.7931 164.357 36.6983 164.357 35.3491C164.357 33.9999 165.45 32.9052 166.798 32.9052C168.145 32.9052 169.239 33.9999 169.239 35.3491Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M167.233 37.0301C164.769 37.0301 164.7 33.5955 166.69 32.9131C165.393 32.971 164.357 34.0388 164.357 35.3494C164.357 36.6601 165.45 37.7934 166.798 37.7934C168.038 37.7934 169.058 36.8682 169.216 35.6694C168.931 36.4249 168.261 37.0301 167.233 37.0301Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M168.677 35.3761C168.677 35.8156 168.319 36.1741 167.88 36.1741C167.441 36.1741 167.083 35.8156 167.083 35.3761C167.083 34.9367 167.441 34.5782 167.88 34.5782C168.319 34.5782 168.677 34.9367 168.677 35.3761Z"
          fill="white"
        />
        <path
          d="M173.57 44.6394C173.57 45.9886 172.476 47.0834 171.129 47.0834C169.781 47.0834 168.688 45.9886 168.688 44.6394C168.688 43.2902 169.781 42.1954 171.129 42.1954C172.476 42.1954 173.57 43.2902 173.57 44.6394Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M171.564 46.3204C169.1 46.3204 169.031 42.8857 171.021 42.2034C169.724 42.2612 168.688 43.329 168.688 44.6397C168.688 45.9503 169.781 47.0837 171.129 47.0837C172.369 47.0837 173.389 46.1585 173.547 44.9596C173.262 45.7152 172.592 46.3204 171.564 46.3204Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M173.008 44.6664C173.008 45.1058 172.65 45.4643 172.211 45.4643C171.772 45.4643 171.414 45.1058 171.414 44.6664C171.414 44.2269 171.772 43.8684 172.211 43.8684C172.65 43.8684 173.008 44.2269 173.008 44.6664Z"
          fill="white"
        />
        <path
          d="M284.152 213.282L283.475 212.877C283.567 212.723 292.88 197.242 306.152 190.446L306.51 191.148C293.446 197.84 284.245 213.128 284.152 213.278V213.282Z"
          fill="#5E8474"
        />
        <path
          d="M294.698 199.883L293.936 199.678C294.016 199.374 295.999 192.146 300.065 187.744L300.642 188.28C296.708 192.539 294.717 199.809 294.698 199.883Z"
          fill="#5E8474"
        />
        <path
          d="M292.719 204.435C291.148 204.802 290.174 204.963 290.139 204.967L290.012 204.189C290.135 204.169 302.386 202.115 310.606 196.012L311.076 196.649C305.016 201.147 296.831 203.483 292.719 204.435Z"
          fill="#5E8474"
        />
        <path
          d="M301.724 187.254C301.932 188.152 301.374 189.051 300.477 189.259C299.58 189.467 298.683 188.908 298.475 188.01C298.267 187.112 298.825 186.213 299.722 186.005C300.619 185.797 301.516 186.356 301.724 187.254Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M300.65 188.68C299.01 189.062 298.432 186.788 299.653 186.024C298.798 186.263 298.275 187.135 298.475 188.006C298.683 188.904 299.58 189.463 300.477 189.255C301.301 189.062 301.84 188.291 301.759 187.466C301.686 188.014 301.332 188.519 300.65 188.68Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M301.355 187.358C301.424 187.651 301.239 187.944 300.946 188.014C300.654 188.083 300.361 187.898 300.292 187.605C300.223 187.312 300.407 187.019 300.7 186.95C300.993 186.88 301.285 187.065 301.355 187.358Z"
          fill="white"
        />
        <path
          d="M307.646 190.75C307.854 191.649 307.296 192.547 306.399 192.755C305.501 192.963 304.604 192.404 304.396 191.506C304.189 190.608 304.747 189.71 305.644 189.502C306.541 189.293 307.438 189.852 307.646 190.75Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M306.568 192.181C304.927 192.562 304.35 190.288 305.57 189.525C304.716 189.764 304.192 190.635 304.392 191.506C304.6 192.404 305.497 192.963 306.394 192.755C307.218 192.562 307.757 191.791 307.676 190.966C307.603 191.514 307.249 192.019 306.568 192.177V192.181Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M307.276 190.858C307.345 191.151 307.16 191.444 306.868 191.514C306.575 191.583 306.283 191.398 306.213 191.105C306.144 190.812 306.329 190.519 306.621 190.45C306.914 190.38 307.207 190.565 307.276 190.858Z"
          fill="white"
        />
        <path
          d="M311.957 196.267C312.165 197.165 311.607 198.063 310.71 198.271C309.813 198.48 308.916 197.921 308.708 197.022C308.5 196.124 309.058 195.226 309.955 195.018C310.852 194.81 311.75 195.369 311.957 196.267Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M310.883 197.693C309.243 198.075 308.666 195.8 309.886 195.037C309.032 195.276 308.508 196.147 308.708 197.019C308.916 197.917 309.813 198.476 310.71 198.268C311.534 198.075 312.073 197.304 311.992 196.479C311.919 197.026 311.565 197.531 310.883 197.693Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M311.588 196.371C311.657 196.664 311.472 196.957 311.18 197.026C310.887 197.096 310.595 196.911 310.525 196.618C310.456 196.325 310.641 196.032 310.933 195.962C311.226 195.893 311.519 196.078 311.588 196.371Z"
          fill="white"
        />
        <path
          d="M253.209 242.367C268.521 229.835 279.778 212.727 285.299 193.715C285.715 192.285 283.486 191.672 283.07 193.098C277.653 211.752 266.596 228.436 251.577 240.729C250.425 241.67 252.069 243.3 253.209 242.363V242.367Z"
          fill="#94BDAE"
        />
        <mask
          id="mask0_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="281"
          y="180"
          width="13"
          height="19"
        >
          <path
            d="M283.213 198.784C289.369 196.945 293.75 190.396 293.1 183.997C293.003 183.037 292.788 182.046 292.179 181.298C291.202 180.088 289.377 179.81 287.906 180.319C286.435 180.832 285.272 181.985 284.348 183.237C281.426 187.192 280.441 192.516 281.757 197.258L283.213 198.784Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_195_50053)">
          <path
            d="M283.213 198.784C289.369 196.945 293.75 190.396 293.1 183.997C293.003 183.037 292.788 182.046 292.179 181.298C291.202 180.088 289.377 179.81 287.906 180.319C286.435 180.832 285.272 181.985 284.348 183.237C281.426 187.192 280.441 192.516 281.757 197.258L283.213 198.784Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M283.509 198.414C287.609 197.192 290.524 192.828 290.089 188.569C290.023 187.929 289.881 187.27 289.477 186.772C288.826 185.967 287.609 185.782 286.631 186.121C285.653 186.46 284.88 187.231 284.264 188.064C282.319 190.697 281.665 194.243 282.539 197.396L283.509 198.41V198.414Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M282.408 199.609C282.408 199.609 291.005 182.786 290.825 178.315"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M293.866 185.323C293.866 185.323 288.383 187.559 288.048 187.574C287.713 187.59 284.768 181.549 284.768 181.549"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M292.572 191.61L285.034 194.312C285.034 194.312 280.934 188.349 280.46 187.347"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M287.906 180.319C289.377 179.807 291.198 180.084 292.18 181.298C292.291 181.433 292.384 181.58 292.468 181.73C291.444 180.863 289.881 180.705 288.588 181.152C287.117 181.665 285.954 182.817 285.03 184.07C282.142 187.983 281.149 193.229 282.397 197.932L281.754 197.258C280.441 192.516 281.422 187.192 284.345 183.237C285.269 181.985 286.431 180.828 287.902 180.319H287.906Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask1_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="268"
          y="189"
          width="13"
          height="20"
        >
          <path
            d="M278.1 208.279C281.519 202.832 280.498 195.014 275.794 190.631C275.089 189.976 274.273 189.37 273.326 189.209C271.79 188.943 270.238 189.937 269.468 191.294C268.698 192.651 268.586 194.285 268.717 195.839C269.125 200.742 271.89 205.395 276.002 208.094L278.1 208.279Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_195_50053)">
          <path
            d="M278.1 208.279C281.519 202.832 280.498 195.014 275.794 190.631C275.089 189.976 274.273 189.37 273.326 189.209C271.79 188.943 270.238 189.937 269.468 191.294C268.698 192.651 268.586 194.285 268.717 195.839C269.125 200.742 271.89 205.395 276.002 208.094L278.1 208.279Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M278.077 207.801C280.352 204.177 279.674 198.969 276.54 196.055C276.071 195.619 275.528 195.218 274.896 195.107C273.876 194.929 272.841 195.592 272.328 196.494C271.816 197.396 271.739 198.483 271.828 199.52C272.101 202.785 273.942 205.881 276.675 207.677L278.073 207.801H278.077Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M278.038 209.427C278.038 209.427 273.426 191.105 270.346 187.859"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M277.241 191.124C277.241 191.124 274.593 196.425 274.346 196.656C274.104 196.887 267.913 194.293 267.913 194.293"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M280.414 196.706L276.525 203.714C276.525 203.714 269.518 201.937 268.498 201.494"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M269.468 191.294C270.238 189.937 271.79 188.943 273.326 189.209C273.499 189.239 273.665 189.286 273.83 189.343C272.49 189.37 271.208 190.28 270.534 191.471C269.764 192.828 269.653 194.463 269.78 196.016C270.184 200.866 272.895 205.465 276.929 208.175L276.002 208.094C271.89 205.395 269.125 200.742 268.717 195.839C268.586 194.285 268.702 192.651 269.472 191.294H269.468Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask2_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="261"
          y="202"
          width="12"
          height="20"
        >
          <path
            d="M270.596 221.493C274.015 216.046 272.995 208.228 268.29 203.845C267.585 203.19 266.769 202.585 265.822 202.423C264.286 202.157 262.734 203.152 261.964 204.509C261.194 205.865 261.082 207.5 261.213 209.053C261.621 213.957 264.386 218.61 268.498 221.308L270.596 221.493Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_195_50053)">
          <path
            d="M270.596 221.493C274.015 216.046 272.995 208.228 268.29 203.845C267.585 203.19 266.769 202.585 265.822 202.423C264.286 202.157 262.734 203.152 261.964 204.509C261.194 205.865 261.082 207.5 261.213 209.053C261.621 213.957 264.386 218.61 268.498 221.308L270.596 221.493Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M270.577 221.019C272.852 217.395 272.174 212.187 269.04 209.273C268.571 208.838 268.028 208.437 267.396 208.325C266.376 208.148 265.341 208.811 264.828 209.713C264.316 210.615 264.239 211.702 264.328 212.739C264.601 216.004 266.442 219.099 269.175 220.896L270.573 221.019H270.577Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M270.534 222.646C270.534 222.646 265.922 204.323 262.842 201.078"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M269.741 204.339C269.741 204.339 267.093 209.639 266.846 209.871C266.604 210.102 260.413 207.508 260.413 207.508"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M272.91 209.921L269.022 216.929C269.022 216.929 262.014 215.152 260.994 214.709"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M261.964 204.512C262.734 203.155 264.286 202.161 265.822 202.427C265.995 202.458 266.161 202.504 266.326 202.562C264.986 202.589 263.704 203.499 263.031 204.69C262.26 206.047 262.149 207.681 262.276 209.235C262.68 214.084 265.391 218.683 269.426 221.393L268.498 221.312C264.39 218.613 261.621 213.961 261.213 209.057C261.082 207.504 261.198 205.869 261.968 204.512H261.964Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask3_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="278"
          y="200"
          width="20"
          height="12"
        >
          <path
            d="M278.481 209.238C280.868 203.267 287.787 199.497 294.093 200.727C295.04 200.912 296.003 201.216 296.696 201.891C297.812 202.978 297.92 204.821 297.277 206.239C296.634 207.658 295.379 208.71 294.051 209.52C289.85 212.072 284.464 212.569 279.867 210.827L278.485 209.235L278.481 209.238Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_195_50053)">
          <path
            d="M278.481 209.238C280.868 203.267 287.787 199.497 294.093 200.727C295.04 200.912 296.003 201.216 296.696 201.891C297.812 202.978 297.92 204.821 297.277 206.239C296.634 207.658 295.379 208.71 294.051 209.52C289.85 212.072 284.464 212.569 279.867 210.827L278.485 209.235L278.481 209.238Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M278.878 208.972C280.468 204.998 285.073 202.489 289.269 203.306C289.897 203.429 290.54 203.633 291.002 204.081C291.745 204.805 291.818 206.031 291.391 206.976C290.963 207.92 290.128 208.622 289.242 209.161C286.447 210.861 282.859 211.193 279.802 210.033L278.882 208.972H278.878Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M277.588 209.963C277.588 209.963 295.102 202.924 299.533 203.51"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M292.842 199.844C292.842 199.844 290.12 205.106 290.074 205.441C290.027 205.777 295.768 209.262 295.768 209.262"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M286.466 200.557L283.094 207.828C283.094 207.828 288.649 212.457 289.604 213.024"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M297.273 206.239C297.916 204.821 297.805 202.978 296.692 201.891C296.565 201.768 296.43 201.664 296.288 201.563C297.058 202.666 297.073 204.239 296.507 205.484C295.864 206.902 294.609 207.955 293.281 208.764C289.127 211.289 283.817 211.798 279.255 210.125L279.867 210.827C284.464 212.569 289.846 212.072 294.051 209.52C295.383 208.71 296.634 207.658 297.277 206.239H297.273Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask4_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="270"
          y="214"
          width="21"
          height="12"
        >
          <path
            d="M270.538 221.505C273.768 215.942 281.157 213.221 287.217 215.356C288.125 215.676 289.038 216.119 289.623 216.887C290.57 218.124 290.412 219.963 289.569 221.273C288.726 222.584 287.332 223.444 285.9 224.049C281.372 225.965 275.974 225.672 271.678 223.282L270.538 221.505Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_195_50053)">
          <path
            d="M270.538 221.505C273.768 215.942 281.157 213.221 287.217 215.356C288.125 215.676 289.038 216.119 289.623 216.887C290.57 218.124 290.412 219.963 289.569 221.273C288.726 222.584 287.332 223.444 285.9 224.049C281.372 225.965 275.974 225.672 271.678 223.282L270.538 221.505Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M270.97 221.3C273.118 217.6 278.039 215.784 282.073 217.206C282.678 217.419 283.286 217.715 283.675 218.224C284.306 219.049 284.199 220.271 283.64 221.146C283.078 222.017 282.154 222.592 281.199 222.996C278.185 224.272 274.593 224.076 271.732 222.484L270.974 221.3H270.97Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M269.549 222.091C269.549 222.091 287.898 217.673 292.199 218.899"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M286.112 214.296C286.112 214.296 282.655 219.107 282.562 219.431C282.47 219.755 287.641 224.037 287.641 224.037"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M279.702 214.076L275.309 220.78C275.309 220.78 280.133 226.169 280.999 226.867"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M289.569 221.27C290.412 219.959 290.57 218.12 289.623 216.883C289.515 216.744 289.396 216.617 289.269 216.501C289.869 217.704 289.658 219.261 288.918 220.414C288.075 221.724 286.682 222.584 285.249 223.189C280.772 225.082 275.443 224.816 271.173 222.495L271.678 223.282C275.971 225.672 281.372 225.965 285.9 224.049C287.336 223.444 288.726 222.584 289.569 221.273V221.27Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask5_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="261"
          y="227"
          width="20"
          height="11"
        >
          <path
            d="M261.009 233.967C264.24 228.405 271.628 225.683 277.688 227.819C278.597 228.139 279.505 228.582 280.094 229.349C281.041 230.587 280.883 232.425 280.04 233.736C279.197 235.047 277.803 235.906 276.371 236.512C271.843 238.427 266.446 238.135 262.149 235.745L261.009 233.967Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_195_50053)">
          <path
            d="M261.009 233.967C264.24 228.405 271.628 225.683 277.688 227.819C278.597 228.139 279.505 228.582 280.094 229.349C281.041 230.587 280.883 232.425 280.04 233.736C279.197 235.047 277.803 235.906 276.371 236.512C271.843 238.427 266.446 238.135 262.149 235.745L261.009 233.967Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M261.441 233.763C263.589 230.062 268.51 228.247 272.545 229.669C273.149 229.881 273.757 230.178 274.146 230.687C274.778 231.512 274.67 232.734 274.112 233.609C273.553 234.484 272.625 235.054 271.671 235.459C268.656 236.735 265.064 236.539 262.203 234.947L261.445 233.763H261.441Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M260.02 234.553C260.02 234.553 278.37 230.136 282.67 231.362"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M276.579 226.759C276.579 226.759 273.122 231.57 273.03 231.893C272.937 232.217 278.108 236.5 278.108 236.5"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M270.169 226.539L265.776 233.243C265.776 233.243 270.6 238.632 271.466 239.33"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M280.037 233.732C280.88 232.422 281.038 230.583 280.091 229.345C279.983 229.207 279.864 229.079 279.737 228.964C280.337 230.167 280.125 231.724 279.386 232.876C278.543 234.187 277.149 235.047 275.717 235.652C271.239 237.545 265.911 237.279 261.641 234.958L262.145 235.745C266.438 238.135 271.84 238.427 276.368 236.512C277.804 235.906 279.194 235.047 280.037 233.736V233.732Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask6_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="281"
          y="202"
          width="12"
          height="16"
        >
          <path
            d="M292.25 217.303C289.277 216.119 285.666 213.406 283.618 210.942C282.586 209.701 281.685 208.259 281.531 206.648C281.377 205.041 282.158 203.256 283.664 202.67C284.78 202.234 286.086 202.527 287.083 203.198C288.08 203.869 288.811 204.859 289.427 205.892C291.164 208.803 292.122 212.176 292.176 215.564L292.25 217.303Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_195_50053)">
          <path
            d="M292.25 217.303C289.277 216.119 285.666 213.406 283.618 210.942C282.586 209.701 281.685 208.259 281.531 206.648C281.377 205.041 282.158 203.256 283.664 202.67C284.78 202.234 286.086 202.527 287.083 203.198C288.08 203.869 288.811 204.859 289.427 205.892C291.164 208.803 292.122 212.176 292.176 215.564L292.25 217.303Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M291.075 215.445C291.075 215.445 285.627 209.743 286.944 209.158C288.261 208.572 291.075 215.445 291.075 215.445Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M292.85 217.927C292.85 217.927 281.839 209.358 281.331 203.048"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M292.311 218.575C292.311 218.575 291.614 208.228 284.068 201.783"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask7_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="291"
          y="203"
          width="11"
          height="17"
        >
          <path
            d="M291.506 219.134C291.148 215.954 291.841 211.482 293.046 208.514C293.655 207.018 294.502 205.542 295.845 204.647C297.189 203.753 299.13 203.603 300.354 204.655C301.263 205.438 301.621 206.729 301.501 207.924C301.382 209.119 300.851 210.233 300.231 211.262C298.483 214.165 295.965 216.601 293.004 218.243L291.506 219.13V219.134Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_195_50053)">
          <path
            d="M291.506 219.134C291.148 215.954 291.841 211.482 293.046 208.514C293.655 207.018 294.502 205.542 295.845 204.647C297.189 203.753 299.13 203.603 300.354 204.655C301.263 205.438 301.621 206.729 301.501 207.924C301.382 209.119 300.851 210.233 300.231 211.262C298.483 214.165 295.965 216.601 293.004 218.243L291.506 219.13V219.134Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M292.592 217.222C292.592 217.222 295.049 209.724 296.188 210.611C297.328 211.497 292.592 217.222 292.592 217.222Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M291.237 219.959C291.237 219.959 293.601 206.197 298.922 202.778"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M290.413 219.789C290.413 219.789 299.199 214.304 301.324 204.601"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask8_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="277"
          y="207"
          width="16"
          height="12"
        >
          <path
            d="M292.623 218.182C289.431 218.386 285.003 217.476 282.104 216.127C280.641 215.445 279.209 214.527 278.385 213.14C277.557 211.752 277.503 209.805 278.612 208.629C279.436 207.758 280.741 207.465 281.927 207.639C283.113 207.812 284.199 208.402 285.196 209.069C288.006 210.958 290.313 213.594 291.814 216.636L292.623 218.178V218.182Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask8_195_50053)">
          <path
            d="M292.623 218.182C289.431 218.386 285.003 217.476 282.104 216.127C280.641 215.445 279.209 214.527 278.385 213.14C277.557 211.752 277.503 209.805 278.612 208.629C279.436 207.758 280.741 207.465 281.927 207.639C283.113 207.812 284.199 208.402 285.196 209.069C288.006 210.958 290.313 213.594 291.814 216.636L292.623 218.178V218.182Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M283.879 208.579C283.975 208.533 284.068 208.487 284.164 208.44C283.459 208.059 282.716 207.758 281.927 207.643C280.741 207.465 279.436 207.758 278.612 208.633C277.503 209.809 277.561 211.756 278.385 213.143C278.759 213.772 279.255 214.304 279.825 214.759C280.291 212.288 281.619 209.728 283.879 208.583V208.579Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M292.284 217.924C290.324 218.051 287.606 217.503 285.827 216.682C284.93 216.27 284.053 215.711 283.544 214.863C283.04 214.015 283.005 212.827 283.691 212.107C284.199 211.571 285 211.393 285.727 211.497C286.455 211.605 287.121 211.96 287.733 212.369C289.458 213.517 290.871 215.129 291.787 216.983L292.284 217.924Z"
              fill="white"
            />
          </g>
          <path
            d="M290.771 217.002C290.771 217.002 283.41 214.18 284.349 213.086C285.288 211.991 290.771 217.002 290.771 217.002Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M293.435 218.49C293.435 218.49 279.821 215.46 276.664 209.975"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M293.227 219.304C293.227 219.304 288.18 210.252 278.601 207.654"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask9_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="287"
          y="201"
          width="9"
          height="18"
        >
          <path
            d="M292.392 218.502C290.401 215.996 288.615 211.84 288.06 208.687C287.779 207.095 287.714 205.395 288.38 203.923C289.046 202.45 290.609 201.294 292.207 201.533C293.393 201.71 294.378 202.616 294.914 203.691C295.449 204.767 295.587 205.993 295.61 207.195C295.672 210.584 294.829 213.988 293.193 216.956L292.392 218.502Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask9_195_50053)">
          <path
            d="M292.392 218.502C290.401 215.996 288.615 211.84 288.06 208.687C287.779 207.095 287.714 205.395 288.38 203.923C289.046 202.45 290.609 201.294 292.207 201.533C293.393 201.71 294.378 202.616 294.914 203.691C295.449 204.767 295.587 205.993 295.61 207.195C295.672 210.584 294.829 213.988 293.193 216.956L292.392 218.502Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M295.26 205.831C295.352 205.881 295.445 205.935 295.537 205.985C295.449 205.187 295.268 204.404 294.914 203.691C294.378 202.616 293.393 201.71 292.207 201.533C290.609 201.294 289.046 202.45 288.38 203.923C288.08 204.589 287.929 205.303 287.879 206.031C290.17 205.002 293.027 204.632 295.26 205.831Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M292.403 218.074C291.179 216.536 290.074 213.992 289.732 212.06C289.558 211.085 289.512 210.044 289.92 209.146C290.324 208.248 291.283 207.542 292.261 207.689C292.989 207.801 293.593 208.356 293.924 209.015C294.255 209.674 294.344 210.426 294.355 211.162C294.398 213.236 293.89 215.318 292.892 217.129L292.407 218.074H292.403Z"
              fill="white"
            />
          </g>
          <path
            d="M292.296 216.304C292.296 216.304 290.397 208.645 291.834 208.791C293.27 208.938 292.296 216.304 292.296 216.304Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M292.599 219.346C292.599 219.346 287.302 206.428 289.993 200.7"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M291.814 219.635C291.814 219.635 296.346 210.314 293 200.962"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask10_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="291"
          y="207"
          width="16"
          height="12"
        >
          <path
            d="M291.953 218.467C293.196 215.518 295.987 211.96 298.486 209.963C299.749 208.957 301.208 208.086 302.818 207.966C304.427 207.847 306.19 208.668 306.745 210.187C307.157 211.316 306.833 212.615 306.148 213.598C305.459 214.581 304.45 215.294 303.407 215.888C300.461 217.565 297.077 218.452 293.689 218.432L291.949 218.471L291.953 218.467Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask10_195_50053)">
          <path
            d="M291.953 218.467C293.196 215.518 295.987 211.96 298.486 209.963C299.749 208.957 301.208 208.086 302.818 207.966C304.427 207.847 306.19 208.668 306.745 210.187C307.157 211.316 306.833 212.615 306.148 213.598C305.459 214.581 304.45 215.294 303.407 215.888C300.461 217.565 297.077 218.452 293.689 218.432L291.949 218.471L291.953 218.467Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M304.439 214.924C304.439 215.032 304.439 215.136 304.435 215.24C305.093 214.782 305.69 214.25 306.148 213.594C306.837 212.611 307.157 211.309 306.745 210.183C306.19 208.664 304.427 207.843 302.818 207.962C302.09 208.016 301.393 208.225 300.731 208.529C302.729 210.052 304.423 212.384 304.439 214.921V214.924Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M292.334 218.274C293.096 216.463 294.802 214.273 296.33 213.047C297.1 212.426 297.994 211.891 298.975 211.817C299.957 211.744 301.035 212.245 301.374 213.178C301.624 213.872 301.428 214.67 301.004 215.275C300.581 215.88 299.969 216.316 299.33 216.682C297.532 217.711 295.464 218.259 293.397 218.251L292.334 218.274Z"
              fill="white"
            />
          </g>
          <path
            d="M293.835 217.334C293.835 217.334 299.645 212.002 300.203 213.332C300.762 214.662 293.835 217.334 293.835 217.334Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M291.317 219.057C291.317 219.057 300.107 208.217 306.421 207.843"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M290.682 218.502C290.682 218.502 301.027 218.024 307.626 210.607"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask11_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="7"
          y="170"
          width="23"
          height="11"
        >
          <path
            d="M29.3494 176.923C25.7842 179.101 20.1169 180.743 15.9434 180.951C13.8374 181.056 11.6274 180.867 9.82943 179.764C8.03144 178.662 6.78786 176.445 7.35382 174.414C7.77733 172.902 9.10946 171.769 10.5879 171.253C12.0663 170.736 13.6795 170.751 15.235 170.917C19.6318 171.387 23.8977 173.034 27.4744 175.639L29.3456 176.927L29.3494 176.923Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask11_195_50053)">
          <path
            d="M29.3494 176.923C25.7842 179.101 20.1169 180.743 15.9434 180.951C13.8374 181.056 11.6274 180.867 9.82943 179.764C8.03144 178.662 6.78786 176.445 7.35382 174.414C7.77733 172.902 9.10946 171.769 10.5879 171.253C12.0663 170.736 13.6795 170.751 15.235 170.917C19.6318 171.387 23.8977 173.034 27.4744 175.639L29.3456 176.927L29.3494 176.923Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M26.4927 176.688C26.4927 176.688 16.2746 177.906 16.6942 176.071C17.1139 174.232 26.4927 176.688 26.4927 176.688Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M30.4776 176.788C30.4776 176.788 12.9019 181.568 5.92554 177.147"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M30.7279 177.856C30.7279 177.856 19.3971 170.462 6.75342 173.292"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask12_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="18"
          y="158"
          width="14"
          height="22"
        >
          <path
            d="M30.6738 179.136C27.074 177.012 22.9582 172.779 20.8137 169.194C19.7319 167.382 18.8579 165.343 18.9811 163.235C19.1043 161.126 20.4711 158.983 22.5309 158.528C24.0594 158.188 25.6841 158.832 26.8391 159.896C27.9903 160.956 28.7372 162.39 29.3224 163.844C30.978 167.949 31.5362 172.494 30.9279 176.877L30.6738 179.136Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask12_195_50053)">
          <path
            d="M30.6738 179.136C27.074 177.012 22.9582 172.779 20.8137 169.194C19.7319 167.382 18.8579 165.343 18.9811 163.235C19.1043 161.126 20.4711 158.983 22.5309 158.528C24.0594 158.188 25.6841 158.832 26.8391 159.896C27.9903 160.956 28.7372 162.39 29.3224 163.844C30.978 167.949 31.5362 172.494 30.9279 176.877L30.6738 179.136Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M29.5381 176.503C29.5381 176.503 23.6514 168.049 25.4686 167.556C27.2858 167.062 29.5381 176.503 29.5381 176.503Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M31.3244 180.065C31.3244 180.065 18.8347 166.793 19.4468 158.547"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M30.5007 180.793C30.5007 180.793 31.6788 167.305 23.2317 157.468"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask13_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="9"
          y="176"
          width="22"
          height="13"
        >
          <path
            d="M30.5622 177.22C28.2714 180.716 23.8476 184.629 20.167 186.599C18.3074 187.593 16.2283 188.368 14.1339 188.141C12.0394 187.913 9.96421 186.445 9.61 184.363C9.34819 182.817 10.0682 181.221 11.1808 180.123C12.2974 179.02 13.7604 178.346 15.2388 177.829C19.4162 176.372 23.9747 176.033 28.3176 176.854L30.5584 177.216L30.5622 177.22Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask13_195_50053)">
          <path
            d="M30.5622 177.22C28.2714 180.716 23.8476 184.629 20.167 186.599C18.3074 187.593 16.2283 188.368 14.1339 188.141C12.0394 187.913 9.96421 186.445 9.61 184.363C9.34819 182.817 10.0682 181.221 11.1808 180.123C12.2974 179.02 13.7604 178.346 15.2388 177.829C19.4162 176.372 23.9747 176.033 28.3176 176.854L30.5584 177.216L30.5622 177.22Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M13.6913 178.824C13.7144 178.689 13.7413 178.55 13.7721 178.415C12.8211 178.851 11.9241 179.39 11.181 180.123C10.0645 181.225 9.34451 182.817 9.61017 184.363C9.96438 186.445 12.0357 187.913 14.134 188.141C15.0812 188.245 16.0283 188.141 16.9484 187.906C14.7423 185.477 13.1138 182.081 13.6913 178.82V178.824Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M30.027 177.378C28.6218 179.529 25.9151 181.934 23.6628 183.153C22.5271 183.765 21.2565 184.243 19.9744 184.109C18.6962 183.974 17.4295 183.072 17.2178 181.796C17.0599 180.847 17.5027 179.868 18.1842 179.19C18.8656 178.511 19.7627 178.095 20.6675 177.779C23.2201 176.881 26.0075 176.665 28.6603 177.162L30.0309 177.382L30.027 177.378Z"
              fill="white"
            />
          </g>
          <path
            d="M27.8787 178.23C27.8787 178.23 19.1621 183.708 18.7579 181.869C18.3536 180.026 27.8787 178.23 27.8787 178.23Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M31.521 176.615C31.521 176.615 17.6761 188.465 9.47925 187.455"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M32.2063 177.474C32.2063 177.474 18.8079 175.643 8.58594 183.615"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask14_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="11"
          y="163"
          width="20"
          height="15"
        >
          <path
            d="M30.7123 177.717C26.5388 177.833 20.806 176.434 17.0829 174.537C15.2041 173.581 13.383 172.313 12.3704 170.462C11.3578 168.612 11.3771 166.068 12.8825 164.588C13.999 163.489 15.7161 163.169 17.2562 163.454C18.7962 163.739 20.1861 164.557 21.4528 165.478C25.0334 168.076 27.9171 171.627 29.7305 175.666L30.7161 177.717H30.7123Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask14_195_50053)">
          <path
            d="M30.7123 177.717C26.5388 177.833 20.806 176.434 17.0829 174.537C15.2041 173.581 13.383 172.313 12.3704 170.462C11.3578 168.612 11.3771 166.068 12.8825 164.588C13.999 163.489 15.7161 163.169 17.2562 163.454C18.7962 163.739 20.1861 164.557 21.4528 165.478C25.0334 168.076 27.9171 171.627 29.7305 175.666L30.7161 177.717H30.7123Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M19.7547 164.773C19.8818 164.715 20.0088 164.661 20.1359 164.607C19.235 164.071 18.2801 163.647 17.256 163.458C15.716 163.173 13.9988 163.493 12.8823 164.591C11.3808 166.072 11.3615 168.616 12.3702 170.466C12.8284 171.303 13.4521 172.02 14.1721 172.644C14.8998 169.445 16.7555 166.164 19.7547 164.776V164.773Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M30.2772 177.366C27.713 177.44 24.194 176.595 21.9071 175.439C20.7559 174.857 19.6355 174.086 19.0156 172.956C18.3958 171.827 18.4112 170.273 19.3352 169.368C20.0205 168.693 21.0793 168.496 22.0226 168.67C22.9658 168.843 23.8206 169.341 24.5983 169.9C26.7967 171.484 28.5639 173.65 29.6727 176.114L30.2733 177.363L30.2772 177.366Z"
              fill="white"
            />
          </g>
          <path
            d="M28.3483 176.09C28.3483 176.09 18.877 172.062 20.1514 170.674C21.4296 169.291 28.3483 176.09 28.3483 176.09Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M31.7556 178.157C31.7556 178.157 14.1337 173.558 10.2759 166.245"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M31.4438 179.209C31.4438 179.209 25.2836 167.155 12.9094 163.312"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask15_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="26"
          y="156"
          width="11"
          height="23"
        >
          <path
            d="M30.3349 178.145C28.2366 174.529 26.7158 168.82 26.6003 164.642C26.5426 162.533 26.7774 160.324 27.9171 158.547C29.0567 156.77 31.2974 155.571 33.3149 156.184C34.8126 156.639 35.9176 157.996 36.4027 159.488C36.8878 160.979 36.8377 162.591 36.6375 164.148C36.0716 168.539 34.339 172.775 31.6632 176.299L30.3388 178.145H30.3349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask15_195_50053)">
          <path
            d="M30.3349 178.145C28.2366 174.529 26.7158 168.82 26.6003 164.642C26.5426 162.533 26.7774 160.324 27.9171 158.547C29.0567 156.77 31.2974 155.571 33.3149 156.184C34.8126 156.639 35.9176 157.996 36.4027 159.488C36.8878 160.979 36.8377 162.591 36.6375 164.148C36.0716 168.539 34.339 172.775 31.6632 176.299L30.3388 178.145H30.3349Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M36.4411 162.317C36.5528 162.398 36.6606 162.487 36.7684 162.571C36.8069 161.527 36.7222 160.478 36.3988 159.488C35.9137 157.996 34.8087 156.639 33.311 156.184C31.2936 155.571 29.0528 156.77 27.9132 158.547C27.3973 159.349 27.07 160.243 26.866 161.172C30.0192 160.282 33.7846 160.339 36.4373 162.317H36.4411Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M30.435 177.598C29.1413 175.377 28.2019 171.881 28.1211 169.317C28.0787 168.026 28.2212 166.673 28.9142 165.586C29.6072 164.499 30.9778 163.77 32.2137 164.148C33.1339 164.429 33.8115 165.266 34.1118 166.18C34.4121 167.093 34.3852 168.08 34.2658 169.032C33.927 171.723 32.8759 174.313 31.2435 176.464L30.435 177.594V177.598Z"
              fill="white"
            />
          </g>
          <path
            d="M30.6313 175.289C30.6313 175.289 29.6303 165.031 31.4591 165.493C33.284 165.952 30.6313 175.289 30.6313 175.289Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M30.4465 179.275C30.4465 179.275 26.0459 161.581 30.6121 154.688"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M29.3723 179.502C29.3723 179.502 36.9955 168.319 34.4429 155.602"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M59.8844 238.131C48.9386 221.478 33.0839 208.483 14.642 200.981C13.2636 200.422 12.6669 202.658 14.0259 203.213C31.875 210.476 47.2985 223.193 57.8862 239.303C58.6986 240.54 60.7006 239.384 59.8806 238.135L59.8844 238.131Z"
          fill="#94BDAE"
        />
        <mask
          id="mask16_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="1"
          y="192"
          width="19"
          height="14"
        >
          <path
            d="M19.5739 203.618C18.3842 197.296 12.3242 192.262 5.89839 192.25C4.93587 192.25 3.931 192.362 3.12248 192.886C1.8173 193.738 1.35528 195.523 1.70949 197.042C2.06755 198.56 3.09168 199.836 4.24285 200.885C7.87349 204.204 13.0596 205.731 17.9068 204.909L19.5701 203.614L19.5739 203.618Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask16_195_50053)">
          <path
            d="M19.5739 203.618C18.3842 197.296 12.3242 192.262 5.89839 192.25C4.93587 192.25 3.931 192.362 3.12248 192.886C1.8173 193.738 1.35528 195.523 1.70949 197.042C2.06755 198.56 3.09168 199.836 4.24285 200.885C7.87349 204.204 13.0596 205.731 17.9068 204.909L19.5701 203.614L19.5739 203.618Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M19.2352 203.283C18.442 199.073 14.4071 195.723 10.1335 195.716C9.49058 195.716 8.82451 195.789 8.2855 196.14C7.41538 196.706 7.10737 197.894 7.34607 198.907C7.58478 199.917 8.26625 200.765 9.03242 201.467C11.4503 203.676 14.9038 204.694 18.1263 204.146L19.2352 203.287V203.283Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M20.3132 204.505C20.3132 204.505 4.48545 194.208 0.0231934 193.927"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M7.29592 191.622C7.29592 191.622 8.95146 197.311 8.93221 197.647C8.91295 197.982 2.61035 200.291 2.61035 200.291"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M13.4139 193.557L15.3236 201.344C15.3236 201.344 8.97862 204.813 7.9314 205.183"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M1.71347 197.042C1.35541 195.523 1.82127 193.738 3.12645 192.886C3.27276 192.79 3.42676 192.713 3.58847 192.643C2.62209 193.576 2.30253 195.114 2.61439 196.448C2.97245 197.967 3.99657 199.243 5.14775 200.291C8.73604 203.572 13.849 205.098 18.6462 204.339L17.9108 204.909C13.0635 205.731 7.87747 204.2 4.24683 200.885C3.09565 199.833 2.07153 198.557 1.71347 197.042Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask17_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="8"
          y="206"
          width="21"
          height="12"
        >
          <path
            d="M28.4792 209.69C23.4202 205.723 15.5506 205.935 10.711 210.168C9.9872 210.804 9.30573 211.551 9.04393 212.48C8.62427 213.98 9.45204 215.63 10.7187 216.536C11.9854 217.442 13.5986 217.723 15.154 217.754C20.0667 217.85 24.9756 215.576 28.0788 211.76L28.4792 209.69Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask17_195_50053)">
          <path
            d="M28.4792 209.69C23.4202 205.723 15.5506 205.935 10.711 210.168C9.9872 210.804 9.30573 211.551 9.04393 212.48C8.62427 213.98 9.45204 215.63 10.7187 216.536C11.9854 217.442 13.5986 217.723 15.154 217.754C20.0667 217.85 24.9756 215.576 28.0788 211.76L28.4792 209.69Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M28.0055 209.659C24.6367 207.018 19.3967 207.161 16.178 209.979C15.6967 210.403 15.2424 210.9 15.0692 211.517C14.7881 212.515 15.3387 213.614 16.1857 214.215C17.0289 214.816 18.103 215.005 19.1387 215.025C22.4113 215.09 25.6762 213.575 27.7437 211.035L28.0093 209.655L28.0055 209.659Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M29.6149 209.867C29.6149 209.867 10.9381 212.573 7.396 215.306"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M11.3501 208.772C11.3501 208.772 16.3437 211.956 16.5477 212.222C16.7518 212.488 13.537 218.386 13.537 218.386"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M17.2214 206.189L23.782 210.784C23.782 210.784 21.2948 217.58 20.752 218.548"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M10.7187 216.536C9.45204 215.63 8.62427 213.98 9.04393 212.48C9.09013 212.311 9.15558 212.149 9.22873 211.995C9.11708 213.332 9.89095 214.701 11.0036 215.499C12.2703 216.405 13.8835 216.686 15.4389 216.717C20.2977 216.813 25.1489 214.589 28.2559 210.85L28.0788 211.767C24.9756 215.584 20.0667 217.858 15.154 217.762C13.5986 217.731 11.9854 217.449 10.7187 216.543V216.536Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask18_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="21"
          y="215"
          width="20"
          height="12"
        >
          <path
            d="M40.8381 218.521C35.7791 214.554 27.9095 214.766 23.0699 218.999C22.3461 219.635 21.6646 220.383 21.4028 221.312C20.9832 222.811 21.8109 224.461 23.0776 225.367C24.3443 226.273 25.9575 226.555 27.5129 226.585C32.4256 226.682 37.3345 224.407 40.4377 220.591L40.8381 218.521Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask18_195_50053)">
          <path
            d="M40.8381 218.521C35.7791 214.554 27.9095 214.766 23.0699 218.999C22.3461 219.635 21.6646 220.383 21.4028 221.312C20.9832 222.811 21.8109 224.461 23.0776 225.367C24.3443 226.273 25.9575 226.555 27.5129 226.585C32.4256 226.682 37.3345 224.407 40.4377 220.591L40.8381 218.521Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M40.3607 218.494C36.9918 215.853 31.7518 215.996 28.5332 218.814C28.0519 219.238 27.5976 219.735 27.4243 220.352C27.1433 221.35 27.6977 222.449 28.5409 223.05C29.384 223.652 30.4582 223.841 31.4939 223.86C34.7665 223.925 38.0313 222.411 40.0988 219.87L40.3645 218.49L40.3607 218.494Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M41.9738 218.702C41.9738 218.702 23.297 221.408 19.7549 224.141"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M23.709 217.607C23.709 217.607 28.7026 220.791 28.9066 221.057C29.1107 221.323 25.8958 227.221 25.8958 227.221"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M29.5803 215.025L36.1409 219.62C36.1409 219.62 33.6537 226.416 33.1109 227.383"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M23.0776 225.367C21.8109 224.461 20.9832 222.811 21.4028 221.312C21.449 221.142 21.5145 220.98 21.5876 220.826C21.476 222.164 22.2498 223.532 23.3625 224.33C24.6292 225.236 26.2424 225.518 27.7978 225.548C32.6566 225.645 37.5078 223.42 40.6148 219.681L40.4377 220.599C37.3345 224.415 32.4256 226.689 27.5129 226.593C25.9575 226.562 24.3443 226.281 23.0776 225.375V225.367Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask19_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="22"
          y="189"
          width="11"
          height="21"
        >
          <path
            d="M29.4724 209.408C23.7859 206.413 20.752 199.139 22.627 192.986C22.908 192.065 23.3123 191.136 24.0515 190.515C25.245 189.517 27.0892 189.598 28.4329 190.384C29.7766 191.171 30.6929 192.528 31.359 193.938C33.4611 198.387 33.3995 203.799 31.1973 208.198L29.4724 209.412V209.408Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask19_195_50053)">
          <path
            d="M29.4724 209.408C23.7859 206.413 20.752 199.139 22.627 192.986C22.908 192.065 23.3123 191.136 24.0515 190.515C25.245 189.517 27.0892 189.598 28.4329 190.384C29.7766 191.171 30.6929 192.528 31.359 193.938C33.4611 198.387 33.3995 203.799 31.1973 208.198L29.4724 209.412V209.408Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M29.2529 208.984C25.4683 206.991 23.447 202.146 24.6944 198.052C24.8831 197.439 25.1487 196.818 25.6415 196.406C26.4385 195.739 27.6628 195.793 28.5599 196.317C29.457 196.841 30.0653 197.743 30.5081 198.684C31.9095 201.644 31.8671 205.249 30.4003 208.175L29.2529 208.984Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M30.0998 210.372C30.0998 210.372 24.9099 192.204 25.9494 187.852"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M21.6182 194.135C21.6182 194.135 26.5655 197.389 26.8928 197.47C27.22 197.55 31.2742 192.192 31.2742 192.192"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M21.6721 200.557L28.5484 204.667C28.5484 204.667 33.7191 199.609 34.3813 198.715"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M28.429 190.38C27.0854 189.594 25.2412 189.513 24.0476 190.511C23.9129 190.623 23.7935 190.75 23.6819 190.882C24.8562 190.23 26.4193 190.377 27.6013 191.067C28.945 191.853 29.8613 193.21 30.5274 194.621C32.6064 199.019 32.5679 204.358 30.4349 208.73L31.1973 208.194C33.3995 203.795 33.4611 198.383 31.359 193.935C30.6929 192.528 29.7766 191.167 28.4329 190.38H28.429Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask20_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="35"
          y="198"
          width="11"
          height="21"
        >
          <path
            d="M40.8418 218.579C35.6518 214.789 33.7037 207.149 36.4488 201.336C36.8608 200.465 37.3921 199.601 38.216 199.1C39.5443 198.287 41.3538 198.634 42.5705 199.605C43.7871 200.58 44.4955 202.053 44.9537 203.545C46.3898 208.252 45.5427 213.598 42.7245 217.63L40.8418 218.583V218.579Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask20_195_50053)">
          <path
            d="M40.8418 218.579C35.6518 214.789 33.7037 207.149 36.4488 201.336C36.8608 200.465 37.3921 199.601 38.216 199.1C39.5443 198.287 41.3538 198.634 42.5705 199.605C43.7871 200.58 44.4955 202.053 44.9537 203.545C46.3898 208.252 45.5427 213.598 42.7245 217.63L40.8418 218.583V218.579Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M40.6838 218.128C37.2264 215.607 35.9328 210.518 37.7616 206.648C38.0349 206.066 38.3891 205.495 38.9359 205.16C39.8175 204.616 41.0265 204.848 41.835 205.495C42.6435 206.143 43.1171 207.126 43.4212 208.117C44.376 211.251 43.8139 214.809 41.9389 217.492L40.6876 218.124L40.6838 218.128Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M41.323 219.623C41.323 219.623 38.8243 200.893 40.4837 196.737"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M35.2822 202.327C35.2822 202.327 39.706 206.266 40.0178 206.39C40.3297 206.517 45.1154 201.806 45.1154 201.806"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M34.4043 208.691L40.6106 213.756C40.6106 213.756 46.4628 209.504 47.2444 208.718"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M42.5664 199.601C41.3498 198.626 39.5402 198.279 38.212 199.096C38.0618 199.189 37.927 199.293 37.7961 199.409C39.0513 198.934 40.5759 199.304 41.6462 200.16C42.8629 201.136 43.5713 202.608 44.0294 204.1C45.4501 208.753 44.6339 214.034 41.8926 218.047L42.7243 217.627C45.5425 213.594 46.3896 208.248 44.9535 203.541C44.4992 202.049 43.7869 200.573 42.5703 199.601H42.5664Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask21_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="46"
          y="209"
          width="12"
          height="21"
        >
          <path
            d="M52.238 229.353C47.0481 225.564 45.0999 217.923 47.8451 212.11C48.257 211.239 48.7883 210.376 49.6122 209.875C50.9405 209.061 52.7501 209.408 53.9667 210.38C55.1833 211.355 55.8917 212.827 56.3499 214.319C57.786 219.026 56.939 224.373 54.1207 228.405L52.238 229.357V229.353Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask21_195_50053)">
          <path
            d="M52.238 229.353C47.0481 225.564 45.0999 217.923 47.8451 212.11C48.257 211.239 48.7883 210.376 49.6122 209.874C50.9405 209.061 52.7501 209.408 53.9667 210.379C55.1833 211.355 55.8917 212.827 56.3499 214.319C57.786 219.026 56.939 224.373 54.1207 228.405L52.238 229.357V229.353Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M52.0839 228.902C48.6266 226.381 47.3329 221.293 49.1617 217.422C49.4351 216.84 49.7893 216.27 50.336 215.934C51.2177 215.391 52.4266 215.622 53.2351 216.27C54.0436 216.917 54.5172 217.9 54.8214 218.891C55.7762 222.025 55.2141 225.583 53.3391 228.266L52.0878 228.898L52.0839 228.902Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M52.7232 230.398C52.7232 230.398 50.2245 211.667 51.8839 207.511"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M46.6824 213.105C46.6824 213.105 51.1061 217.045 51.418 217.168C51.7298 217.291 56.5155 212.584 56.5155 212.584"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M45.8044 219.465L52.0108 224.531C52.0108 224.531 57.8629 220.279 58.6445 219.492"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M53.9666 210.376C52.7499 209.4 50.9404 209.053 49.6121 209.871C49.4619 209.963 49.3272 210.071 49.1963 210.183C50.4514 209.709 51.9761 210.079 53.0464 210.935C54.263 211.906 54.9714 213.382 55.4296 214.874C56.8503 219.527 56.0341 224.808 53.2928 228.821L54.1244 228.401C56.9427 224.369 57.7897 219.022 56.3536 214.315C55.8993 212.824 55.187 211.347 53.9704 210.376H53.9666Z"
              fill="white"
            />
          </g>
        </g>
        <path
          d="M258.884 222.534C189.486 222.534 133.233 166.207 133.233 96.7267C133.233 57.9583 150.754 23.2876 178.29 0.212344C175.688 0.0774236 173.066 0.00418108 170.425 0.00418108C88.7872 0.000326207 22.6079 66.2617 22.6079 148C22.6079 229.739 88.7872 296 170.425 296C228.057 296 277.981 262.972 302.36 214.794C288.811 219.797 274.165 222.53 258.88 222.53L258.884 222.534Z"
          fill="#FFC96A"
        />
        <path
          opacity="0.28"
          d="M165.173 0.208163C137.633 23.2873 120.115 57.9541 120.115 96.7225C120.115 166.203 176.373 222.53 245.767 222.53C247.95 222.53 250.122 222.468 252.278 222.357C185.952 218.918 133.233 163.982 133.233 96.7225C133.233 57.9541 150.755 23.2834 178.29 0.208163C175.688 0.0732425 173.066 0 170.425 0C168.219 0 166.02 0.0501133 163.837 0.146485C164.284 0.165759 164.727 0.185034 165.173 0.208163Z"
          fill="white"
        />
        <path
          opacity="0.21"
          d="M190.41 279.52C108.773 279.52 42.5937 213.259 42.5937 131.52C42.5937 90.1577 59.5419 52.7578 86.866 25.9009C48.0609 52.5766 22.6079 97.3123 22.6079 148C22.6079 229.739 88.7872 296 170.425 296C210.747 296 247.295 279.829 273.961 253.616C250.195 269.953 221.419 279.52 190.41 279.52Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M101.388 51.9675C105.771 51.9675 109.323 47.0936 109.323 41.0813C109.323 35.0691 105.771 30.1952 101.388 30.1952C97.006 30.1952 93.4534 35.0691 93.4534 41.0813C93.4534 47.0936 97.006 51.9675 101.388 51.9675Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M209.261 268.787C210.663 264.63 207.188 259.701 201.499 257.777C195.81 255.854 190.061 257.664 188.659 261.821C187.257 265.979 190.732 270.908 196.421 272.832C202.11 274.755 207.859 272.945 209.261 268.787Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M110.794 71.4808C113.316 71.4808 115.36 68.6745 115.36 65.2128C115.36 61.7511 113.316 58.9448 110.794 58.9448C108.272 58.9448 106.228 61.7511 106.228 65.2128C106.228 68.6745 108.272 71.4808 110.794 71.4808Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M224.722 253.554C228.053 254.487 230.197 257.216 229.52 259.645C228.838 262.077 225.589 263.291 222.262 262.359C218.932 261.426 216.787 258.696 217.465 256.268C218.146 253.835 221.396 252.621 224.722 253.554Z"
          fill="#DF8941"
        />
        <path
          d="M104.511 172.27C102.316 172.27 100.129 171.773 98.2853 170.836C93.5574 168.435 89.8959 163.497 87.9786 156.936C87.8592 156.527 88.0941 156.099 88.5022 155.98C88.9103 155.86 89.3377 156.095 89.457 156.504C90.4966 160.066 93.1185 166.48 98.9822 169.46C103.521 171.765 110.332 171.091 113.243 166.411C114.991 163.593 115.122 159.611 113.586 155.756C112.165 152.186 109.647 149.141 107.218 146.196C106.948 145.868 106.994 145.383 107.321 145.109C107.649 144.839 108.134 144.881 108.407 145.213C110.917 148.251 113.509 151.392 115.022 155.185C116.735 159.491 116.562 163.994 114.556 167.224C112.407 170.686 108.442 172.27 104.519 172.27H104.511Z"
          fill="#6B3E3D"
        />
        <path
          d="M92.0483 174.907C91.9174 174.907 91.7865 174.872 91.6633 174.803C91.2936 174.591 91.1704 174.117 91.3822 173.75L94.8203 167.799C95.0321 167.428 95.5056 167.305 95.8714 167.517C96.241 167.729 96.3642 168.203 96.1525 168.57L92.7143 174.521C92.5719 174.768 92.3139 174.907 92.0483 174.907Z"
          fill="#6B3E3D"
        />
        <path
          d="M103.922 179.795C103.498 179.795 103.152 179.448 103.152 179.024V172.336C103.152 171.912 103.498 171.565 103.922 171.565C104.345 171.565 104.692 171.912 104.692 172.336V179.024C104.692 179.448 104.345 179.795 103.922 179.795Z"
          fill="#6B3E3D"
        />
        <path
          d="M140.829 202.693C136.401 202.693 133.475 201.679 133.341 201.633C132.94 201.49 132.728 201.051 132.871 200.65C133.013 200.249 133.452 200.037 133.853 200.18C134.045 200.245 148.183 204.979 160.934 192.624C161.238 192.327 161.727 192.335 162.024 192.643C162.32 192.948 162.313 193.437 162.004 193.734C154.97 200.55 147.567 202.342 142.596 202.643C141.984 202.681 141.395 202.697 140.833 202.697L140.829 202.693Z"
          fill="#6B3E3D"
        />
        <path
          opacity="0.58"
          d="M101.073 184.536C102.193 186.483 100.284 189.69 96.807 191.703C93.3304 193.715 89.5996 193.765 88.4754 191.818C87.355 189.872 89.2647 186.664 92.7413 184.652C96.218 182.64 99.9487 182.59 101.073 184.536Z"
          fill="#F4627D"
        />
        <path
          d="M131.261 205.95C131.08 205.95 130.896 205.885 130.749 205.754C130.434 205.468 130.407 204.983 130.688 204.667C130.726 204.624 134.38 200.43 133.098 195.507C132.99 195.095 133.236 194.675 133.648 194.567C134.06 194.459 134.48 194.706 134.588 195.118C136.082 200.854 132.008 205.499 131.835 205.696C131.685 205.865 131.473 205.954 131.261 205.954V205.95Z"
          fill="#6B3E3D"
        />
        <path
          opacity="0.28"
          d="M126.687 204.944C130.251 204.944 133.14 202.157 133.14 198.719C133.14 195.28 130.251 192.493 126.687 192.493C123.124 192.493 120.235 195.28 120.235 198.719C120.235 202.157 123.124 204.944 126.687 204.944Z"
          fill="white"
        />
        <path
          d="M268.382 173.492C264.617 164.368 257.779 155.737 248.208 153.262C242.194 151.708 235.811 152.745 229.658 151.893C221.661 150.787 214.185 146.339 209.407 139.847C208.186 138.189 207.104 136.374 205.468 135.121C201.468 132.06 195.739 133.351 190.988 135.04C176.45 140.205 162.786 147.815 150.747 157.452C150.593 157.117 149.988 158.744 149.272 160.995C149.507 162.656 149.799 164.306 150.158 165.944C152.552 176.892 158.385 187.208 165.281 195.935C169.05 200.704 173.254 205.094 177.801 209.107C179.179 210.11 180.589 211.077 182.025 212.006C197.356 221.909 215.886 227.133 234.082 225.545C247.476 224.373 261.51 218.718 268.097 207.022C273.776 196.938 272.795 184.193 268.378 173.496L268.382 173.492Z"
          fill="#DF8941"
        />
        <path
          opacity="0.77"
          d="M218.716 198.553C216.152 194.351 211.655 191.888 207.366 189.768C202.249 187.243 197.148 184.941 192.354 181.807C187.707 178.77 183.318 175.327 179.283 171.507C172.392 164.977 166.94 157.302 162.955 148.686C158.728 151.381 154.643 154.303 150.731 157.444C150.577 157.109 149.98 158.724 149.268 160.964C149.503 162.633 149.799 164.291 150.157 165.937C152.552 176.885 158.385 187.2 165.281 195.928C169.177 200.858 173.539 205.384 178.259 209.504C179.453 210.364 180.666 211.197 181.901 211.999C191.296 218.089 201.899 222.403 212.883 224.461C217.861 221.111 220.984 215.294 221.28 209.061C221.453 205.391 220.626 201.698 218.708 198.553H218.716Z"
          fill="#FFF2E2"
        />
        <path
          d="M262.661 175.131C268.272 175.131 272.821 170.07 272.821 163.828C272.821 157.586 268.272 152.526 262.661 152.526C257.049 152.526 252.5 157.586 252.5 163.828C252.5 170.07 257.049 175.131 262.661 175.131Z"
          fill="#DF8941"
        />
        <path
          d="M247.014 165.729C245.089 165.902 243.695 168.747 243.437 172.556C240.123 170.67 236.985 170.262 235.776 171.773C234.251 173.685 236.376 177.929 240.531 181.256C242.929 183.18 245.474 184.367 247.495 184.664C247.904 184.872 248.323 184.972 248.743 184.933C249.136 184.899 249.501 184.741 249.844 184.502C250.233 184.348 250.568 184.128 250.81 183.823C251.076 183.492 251.222 183.087 251.276 182.632C252.143 180.782 252.558 177.999 252.285 174.938C251.808 169.634 249.447 165.513 247.014 165.732V165.729Z"
          fill="#F4627D"
        />
        <g mode="overlay" opacity="0.31">
          <path
            d="M246.498 169.626C245.266 169.738 244.373 171.561 244.208 173.997C242.086 172.791 240.076 172.529 239.302 173.496C238.325 174.718 239.688 177.436 242.348 179.568C243.884 180.797 245.513 181.56 246.806 181.749C247.068 181.88 247.334 181.946 247.607 181.923C247.857 181.9 248.092 181.799 248.312 181.645C248.562 181.549 248.774 181.406 248.932 181.21C249.101 180.998 249.197 180.739 249.228 180.446C249.782 179.263 250.048 177.482 249.871 175.52C249.567 172.124 248.054 169.487 246.498 169.63V169.626Z"
            fill="white"
          />
        </g>
        <path
          d="M243.749 170.27C243.595 170.979 243.487 171.746 243.433 172.559C242.725 172.158 242.028 171.823 241.351 171.557"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M237.196 170.948C236.596 171.079 236.111 171.353 235.776 171.773C234.251 173.685 236.376 177.929 240.531 181.256C242.929 183.18 245.474 184.367 247.495 184.664C247.904 184.872 248.323 184.972 248.743 184.933C249.136 184.899 249.501 184.741 249.844 184.502C250.233 184.348 250.568 184.128 250.814 183.823C251.08 183.492 251.226 183.087 251.28 182.632C252.146 180.782 252.562 177.999 252.289 174.938C251.811 169.634 249.451 165.513 247.018 165.732C245.94 165.829 245.028 166.765 244.388 168.238"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M231.333 192.003C231.333 192.003 233.023 224.161 231.756 227.121C230.49 230.082 223.305 233.046 231.333 239.391C239.36 245.736 277.395 234.738 271.054 182.698L231.333 192.007V192.003Z"
          fill="#DF8941"
        />
        <path
          opacity="0.48"
          d="M262.792 162.71C262.792 162.71 267.466 168.5 268.382 173.488"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M265.872 188.761C265.872 188.761 267.243 192.874 266.557 195.958"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M263.82 193.561C263.82 193.561 263.477 196.988 263.135 199.389"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.25"
          d="M174.617 183.966C181.952 183.418 189.24 181.961 195.985 178.97C202.646 176.017 208.309 171.638 212.899 165.971C216.833 161.114 219.96 155.652 222.054 149.769C217.061 147.645 212.621 144.211 209.407 139.843C208.186 138.185 207.104 136.37 205.468 135.117C201.468 132.056 195.739 133.348 190.988 135.036C176.45 140.202 162.786 147.811 150.747 157.448C150.593 157.113 149.988 158.74 149.272 160.991C149.507 162.652 149.799 164.302 150.158 165.941C151.424 171.731 153.657 177.339 156.495 182.636C162.039 185.018 168.757 184.405 174.613 183.97L174.617 183.966Z"
          fill="#D86B21"
        />
        <path
          d="M122.776 142.364C132.251 142.364 139.932 134.37 139.932 124.508C139.932 114.647 132.251 106.653 122.776 106.653C113.301 106.653 105.62 114.647 105.62 124.508C105.62 134.37 113.301 142.364 122.776 142.364Z"
          fill="#DF8941"
        />
        <path
          opacity="0.28"
          d="M108.357 124.508C108.357 115.126 115.31 107.439 124.146 106.714C123.696 106.676 123.238 106.653 122.776 106.653C113.301 106.653 105.62 114.648 105.62 124.508C105.62 134.369 113.301 142.364 122.776 142.364C123.238 142.364 123.692 142.341 124.146 142.302C115.31 141.574 108.357 133.891 108.357 124.508Z"
          fill="white"
        />
        <path
          opacity="0.77"
          d="M122.776 135.468C128.591 135.468 133.306 130.561 133.306 124.508C133.306 118.456 128.591 113.549 122.776 113.549C116.96 113.549 112.246 118.456 112.246 124.508C112.246 130.561 116.96 135.468 122.776 135.468Z"
          fill="#FFF2E2"
        />
        <path
          opacity="0.15"
          d="M130.464 125.719C127.484 128.706 122.156 129.443 118.24 128.309C115.772 127.596 113.793 126.097 112.249 124.192C112.249 124.3 112.242 124.404 112.242 124.512C112.242 130.564 116.954 135.472 122.772 135.472C128.589 135.472 133.302 130.564 133.302 124.512C133.302 123.491 133.163 122.504 132.913 121.563C132.443 123.105 131.662 124.52 130.464 125.719Z"
          fill="#D86B21"
        />
        <path
          d="M191.261 106.321C200.736 106.321 208.417 98.3268 208.417 88.4654C208.417 78.6039 200.736 70.6096 191.261 70.6096C181.786 70.6096 174.105 78.6039 174.105 88.4654C174.105 98.3268 181.786 106.321 191.261 106.321Z"
          fill="#DF8941"
        />
        <path
          opacity="0.28"
          d="M176.326 90.6935C176.326 80.8327 184.007 72.8377 193.482 72.8377C197.544 72.8377 201.271 74.3103 204.209 76.762C201.063 72.9958 196.428 70.6096 191.257 70.6096C181.782 70.6096 174.101 78.6046 174.101 88.4654C174.101 94.1012 176.611 99.1241 180.531 102.397C177.913 99.2629 176.326 95.1728 176.326 90.6935Z"
          fill="white"
        />
        <path
          opacity="0.77"
          d="M191.261 99.4248C197.077 99.4248 201.791 94.5181 201.791 88.4654C201.791 82.4127 197.077 77.506 191.261 77.506C185.445 77.506 180.731 82.4127 180.731 88.4654C180.731 94.5181 185.445 99.4248 191.261 99.4248Z"
          fill="#FFF2E2"
        />
        <path
          opacity="0.15"
          d="M195.138 91.6726C190.533 89.2826 186.525 84.8341 184.87 79.7572C182.356 81.7579 180.735 84.9112 180.735 88.4615C180.735 94.5136 185.447 99.4209 191.265 99.4209C195.361 99.4209 198.907 96.9846 200.648 93.4304C198.73 93.2107 196.851 92.5592 195.142 91.6688L195.138 91.6726Z"
          fill="#D86B21"
        />
        <path
          d="M228.503 130.603C228.172 129.396 227.752 128.228 227.256 127.11L227.302 127.095C208.448 78.0649 164.299 91.6495 164.299 91.6495C106.374 106.714 123.58 160.74 123.58 160.74C127.096 173.5 140.097 181.499 154.755 181.086L154.762 181.109C154.762 181.109 179.357 181.769 213.603 163.466L213.549 163.25C225.454 155.899 231.884 142.888 228.499 130.603H228.503Z"
          fill="#DF8941"
        />
        <path
          opacity="0.28"
          d="M128.031 160.74C128.031 160.74 110.825 106.714 168.75 91.6495C168.75 91.6495 172.946 90.3581 179.303 90.0112C179.203 89.9842 179.103 89.9572 179.003 89.9341C170.386 89.7799 164.299 91.6533 164.299 91.6533C106.374 106.718 123.58 160.744 123.58 160.744C127.096 173.504 140.097 181.503 154.755 181.09L154.762 181.113C154.762 181.113 155.444 181.133 156.737 181.079C143.108 180.581 131.342 172.787 128.027 160.744L128.031 160.74Z"
          fill="white"
        />
        <path
          opacity="0.28"
          d="M228.503 130.603C228.172 129.396 227.752 128.228 227.256 127.11L227.302 127.095C218.165 103.334 203.089 94.2785 189.975 91.2563C197.602 95.2654 204.032 101.391 208.352 109.05C214.184 119.389 216.083 131.825 213.83 143.459C211.52 155.394 204.725 165.944 194.203 172.162C193.821 172.386 193.44 172.602 193.055 172.818C199.362 170.443 206.261 167.394 213.603 163.47L213.549 163.254C225.454 155.902 231.883 142.892 228.499 130.607L228.503 130.603Z"
          fill="#D86B21"
        />
        <path
          opacity="0.15"
          d="M182.676 166.006C191.138 162.728 195.72 154.175 192.91 146.902C190.099 139.628 180.961 136.389 172.499 139.667C164.038 142.945 159.456 151.498 162.266 158.771C165.076 166.044 174.214 169.283 182.676 166.006Z"
          fill="#D86B21"
        />
        <path
          d="M183.834 135.321C182.964 135.321 182.079 135.132 181.22 134.751C179.557 134.015 178.194 132.646 177.378 130.896C177.197 130.51 177.366 130.052 177.751 129.871C178.136 129.689 178.594 129.859 178.775 130.244C179.434 131.655 180.523 132.754 181.844 133.34C183.13 133.91 184.481 133.922 185.652 133.375C186.822 132.827 187.681 131.783 188.069 130.426C188.47 129.034 188.327 127.492 187.669 126.081C187.488 125.696 187.658 125.237 188.043 125.056C188.428 124.875 188.886 125.044 189.067 125.43C189.883 127.176 190.052 129.103 189.548 130.854C189.036 132.642 187.881 134.034 186.302 134.77C185.521 135.136 184.685 135.317 183.834 135.317V135.321Z"
          fill="#6B3E3D"
        />
        <path
          d="M150.793 150.976C149.923 150.976 149.037 150.787 148.179 150.405C146.515 149.669 145.153 148.301 144.336 146.551C144.155 146.165 144.325 145.706 144.71 145.525C145.095 145.344 145.553 145.514 145.734 145.899C146.392 147.31 147.482 148.409 148.802 148.995C150.088 149.565 151.44 149.577 152.61 149.029C155.063 147.884 155.971 144.612 154.631 141.736C154.45 141.35 154.62 140.892 155.005 140.71C155.39 140.529 155.848 140.699 156.029 141.084C157.727 144.727 156.487 148.917 153.265 150.425C152.483 150.791 151.648 150.972 150.797 150.972L150.793 150.976Z"
          fill="#6B3E3D"
        />
        <path
          d="M191.635 129.932C191.635 129.932 191.612 129.932 191.6 129.932L189.201 129.828C188.778 129.809 188.447 129.45 188.466 129.022C188.485 128.598 188.847 128.275 189.267 128.286L191.666 128.39C192.089 128.41 192.42 128.768 192.401 129.196C192.382 129.608 192.043 129.932 191.631 129.932H191.635Z"
          fill="#6B3E3D"
        />
        <path
          d="M145.252 151.851C145.056 151.851 144.86 151.774 144.71 151.624C144.409 151.323 144.409 150.833 144.71 150.533L146.689 148.551C146.989 148.251 147.478 148.251 147.778 148.551C148.078 148.852 148.078 149.341 147.778 149.642L145.799 151.624C145.649 151.774 145.453 151.851 145.256 151.851H145.252Z"
          fill="#6B3E3D"
        />
        <path
          d="M147.859 153.104C147.786 153.104 147.716 153.092 147.643 153.073C147.235 152.953 147 152.526 147.12 152.117L147.851 149.611C147.971 149.203 148.398 148.968 148.806 149.087C149.214 149.207 149.449 149.634 149.33 150.043L148.598 152.549C148.502 152.884 148.194 153.104 147.859 153.104Z"
          fill="#6B3E3D"
        />
        <path
          d="M153.569 155.178C154.138 156.311 152.949 158.057 150.916 159.079C148.883 160.1 146.777 160.008 146.207 158.875C145.637 157.741 146.827 155.995 148.86 154.973C150.893 153.952 152.999 154.044 153.569 155.178Z"
          fill="#F4627D"
        />
        <path
          opacity="0.48"
          d="M173.447 178.82C173.447 178.82 209.049 172.652 223.767 152.086"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.77"
          d="M180.623 165.664C189.085 162.386 193.667 153.833 190.857 146.56C188.046 139.287 178.908 136.048 170.446 139.325C161.985 142.603 157.403 151.156 160.213 158.429C163.024 165.703 172.161 168.942 180.623 165.664Z"
          fill="#FFF2E2"
        />
        <path
          d="M176.323 143.736C176.9 145.275 175.403 147.26 172.981 148.173C170.556 149.087 168.122 148.582 167.545 147.044C166.967 145.506 168.465 143.521 170.887 142.607C173.312 141.693 175.745 142.198 176.323 143.736Z"
          fill="#6B3E3D"
        />
        <path
          d="M166.463 155.598C164.118 155.598 162.158 154.761 162.035 154.707C161.646 154.538 161.465 154.083 161.635 153.694C161.804 153.304 162.258 153.123 162.647 153.293C162.69 153.312 166.555 154.946 169.462 153.354C171.141 152.433 172.215 150.579 172.657 147.842C172.727 147.422 173.119 147.137 173.539 147.206C173.959 147.275 174.244 147.668 174.174 148.089C173.651 151.323 172.311 153.551 170.19 154.707C168.977 155.371 167.664 155.594 166.459 155.594L166.463 155.598Z"
          fill="#6B3E3D"
        />
        <path
          d="M178.29 150.274C176.746 150.274 175.071 149.781 173.285 148.798C172.912 148.594 172.777 148.123 172.981 147.749C173.185 147.376 173.655 147.241 174.028 147.445C176.454 148.779 178.575 149.072 180.335 148.316C183.376 147.005 184.47 142.942 184.481 142.904C184.589 142.491 185.005 142.245 185.421 142.353C185.832 142.461 186.079 142.881 185.971 143.289C185.921 143.486 184.689 148.112 180.951 149.727C180.115 150.089 179.226 150.271 178.29 150.271V150.274Z"
          fill="#6B3E3D"
        />
        <path
          d="M190.803 132.75C190.765 132.75 190.726 132.75 190.688 132.742L188.601 132.43C188.181 132.368 187.892 131.975 187.954 131.555C188.016 131.135 188.412 130.842 188.828 130.907L190.911 131.22C191.331 131.281 191.619 131.675 191.558 132.095C191.5 132.476 191.173 132.75 190.799 132.75H190.803Z"
          fill="#6B3E3D"
        />
        <path
          d="M197.348 133.367C197.918 134.5 196.728 136.246 194.695 137.268C192.663 138.29 190.557 138.197 189.987 137.064C189.417 135.93 190.607 134.184 192.64 133.163C194.672 132.141 196.778 132.234 197.348 133.367Z"
          fill="#F4627D"
        />
        <path
          opacity="0.48"
          d="M200.833 108.557C200.833 108.557 204.598 109.929 206.311 113.356"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M207.678 110.272C207.678 110.272 210.758 112.67 211.786 114.729"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M208.706 118.156C208.706 118.156 210.077 119.871 211.101 122.612"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M214.55 180.921L202.965 175.123C201.256 179.039 197.641 181.861 194.014 184.348C189.533 188.218 181.132 192.265 165.593 195.064C159.355 196.19 156.564 214.955 173.27 216.763C186.68 218.216 201.256 208.132 212.556 194.413C213.399 192.732 214.192 191.028 214.754 189.239C215.663 186.348 215.548 183.611 214.55 180.917V180.921Z"
          fill="#DF8941"
        />
        <path
          opacity="0.48"
          d="M188.928 213.317C188.928 213.317 210.307 204.852 214.727 187.381"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M230.162 192.543C230.162 192.543 219.521 206.548 234.725 218.725C234.725 218.725 234.116 223.293 232.596 225.421"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M228.099 233.023C228.099 233.023 231.806 243.975 248.489 236.55"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M231.252 236.731C231.252 236.731 231.806 232.649 234.96 230.421"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M239.591 238.589C239.591 238.589 239.591 233.948 242.741 232.094"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M160.996 204.069C160.996 204.069 165.631 201.101 168.411 202.77"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M165.073 212.604C165.073 212.604 167.853 208.891 172.118 209.635"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <mask
          id="mask22_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="175"
          y="78"
          width="12"
          height="16"
        >
          <path
            d="M175.553 93.3726C175.426 90.2309 176.423 85.9057 177.813 83.0839C178.514 81.6615 179.449 80.2776 180.828 79.4989C182.21 78.7202 184.119 78.7125 185.244 79.8266C186.079 80.6554 186.337 81.9468 186.137 83.1071C185.937 84.2674 185.336 85.3236 184.658 86.2873C182.741 89.005 180.104 91.21 177.085 92.6093L175.557 93.3687L175.553 93.3726Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask22_195_50053)">
          <path
            d="M175.553 93.3726C175.426 90.2309 176.423 85.9057 177.813 83.0839C178.514 81.6615 179.449 80.2776 180.828 79.4989C182.21 78.7202 184.119 78.7125 185.244 79.8266C186.079 80.6554 186.337 81.9468 186.137 83.1071C185.937 84.2674 185.336 85.3236 184.658 86.2873C182.741 89.005 180.104 91.21 177.085 92.6093L175.557 93.3687L175.553 93.3726Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M176.75 91.5801C176.75 91.5801 179.688 84.4139 180.739 85.3622C181.79 86.3105 176.75 91.5801 176.75 91.5801Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M175.229 94.1628C175.229 94.1628 178.517 80.8558 183.973 77.8837"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M174.437 93.9354C174.437 93.9354 183.43 89.1862 186.199 79.8381"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask23_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="166"
          y="78"
          width="10"
          height="17"
        >
          <path
            d="M174.81 94.3826C172.172 92.6749 169.216 89.3674 167.714 86.6073C166.956 85.2119 166.363 83.6506 166.524 82.074C166.682 80.4935 167.776 78.9284 169.339 78.6509C170.498 78.4466 171.699 78.9785 172.53 79.8112C173.362 80.6438 173.878 81.7425 174.271 82.8527C175.387 85.9867 175.664 89.4175 175.072 92.6942L174.81 94.3826Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask23_195_50053)">
          <path
            d="M174.81 94.3826C172.172 92.6749 169.216 89.3674 167.714 86.6073C166.956 85.2119 166.363 83.6506 166.524 82.074C166.682 80.4935 167.776 78.9284 169.339 78.6509C170.498 78.4466 171.699 78.9785 172.53 79.8112C173.362 80.6438 173.878 81.7425 174.271 82.8527C175.387 85.9867 175.664 89.4175 175.072 92.6942L174.81 94.3826Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M174.036 92.3703C174.036 92.3703 169.878 85.8363 171.256 85.5241C172.634 85.2118 174.036 92.3703 174.036 92.3703Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M175.268 95.0996C175.268 95.0996 166.301 84.7415 167.017 78.566"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M174.629 95.62C174.629 95.62 175.934 85.5241 169.897 77.8722"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask24_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="163"
          y="92"
          width="12"
          height="16"
        >
          <path
            d="M174.405 92.9293C174.405 96.071 173.243 100.358 171.745 103.122C170.99 104.517 170.001 105.862 168.592 106.587C167.183 107.312 165.273 107.246 164.191 106.086C163.386 105.223 163.182 103.923 163.429 102.771C163.675 101.618 164.314 100.589 165.03 99.6522C167.052 97.0116 169.778 94.9107 172.846 93.6309L174.405 92.9332V92.9293Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask24_195_50053)">
          <path
            d="M174.405 92.9293C174.405 96.071 173.243 100.358 171.745 103.122C170.99 104.517 170.001 105.862 168.592 106.587C167.183 107.312 165.273 107.246 164.191 106.086C163.386 105.223 163.182 103.923 163.429 102.771C163.675 101.618 164.314 100.589 165.03 99.6522C167.052 97.0116 169.778 94.9107 172.846 93.6309L174.405 92.9332V92.9293Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M173.139 94.6756C173.139 94.6756 169.924 101.722 168.911 100.732C167.899 99.7409 173.139 94.6756 173.139 94.6756Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M174.756 92.1545C174.756 92.1545 170.948 105.323 165.385 108.075"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M175.541 92.4089C175.541 92.4089 166.37 96.7996 163.236 106.032"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask25_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="159"
          y="89"
          width="17"
          height="8"
        >
          <path
            d="M175.661 94.4905C172.885 95.9554 168.553 96.9191 165.415 96.8767C163.829 96.8536 162.181 96.6069 160.884 95.6971C159.586 94.7835 158.759 93.0642 159.278 91.5647C159.667 90.4506 160.718 89.6642 161.854 89.3481C162.986 89.0282 164.195 89.1168 165.358 89.3173C168.634 89.8801 171.757 91.3141 174.317 93.4381L175.661 94.4944V94.4905Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask25_195_50053)">
          <path
            d="M175.661 94.4905C172.885 95.9554 168.553 96.9191 165.415 96.8767C163.829 96.8536 162.181 96.6069 160.884 95.6971C159.586 94.7835 158.759 93.0642 159.278 91.5647C159.667 90.4506 160.718 89.6642 161.854 89.3481C162.986 89.0282 164.195 89.1168 165.358 89.3173C168.634 89.8801 171.757 91.3141 174.317 93.4381L175.661 94.4944V94.4905Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M173.527 94.1783C173.527 94.1783 165.804 94.61 166.205 93.2493C166.609 91.8923 173.527 94.1783 173.527 94.1783Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M176.511 94.4404C176.511 94.4404 163.101 97.1966 158.073 93.5461"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M176.65 95.2538C176.65 95.2538 168.499 89.1708 158.878 90.6935"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask26_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="173"
          y="90"
          width="18"
          height="9"
        >
          <path
            d="M173.616 93.4343C176.323 91.8461 180.608 90.6857 183.746 90.5855C185.328 90.5354 186.988 90.7089 188.324 91.5608C189.66 92.4127 190.568 94.0973 190.114 95.6161C189.775 96.7456 188.759 97.5782 187.642 97.9483C186.526 98.3184 185.313 98.2837 184.146 98.1372C180.847 97.7247 177.663 96.4295 175.01 94.4288L173.62 93.4343H173.616Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask26_195_50053)">
          <path
            d="M173.616 93.4343C176.323 91.8461 180.608 90.6857 183.746 90.5855C185.328 90.5354 186.988 90.7089 188.324 91.5608C189.66 92.4127 190.568 94.0973 190.114 95.6161C189.775 96.7456 188.759 97.5782 187.642 97.9483C186.526 98.3184 185.313 98.2837 184.146 98.1372C180.847 97.7247 177.663 96.4295 175.01 94.4288L173.62 93.4343H173.616Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M175.76 93.6502C175.76 93.6502 183.457 92.8676 183.118 94.2438C182.779 95.62 175.76 93.6502 175.76 93.6502Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M172.769 93.523C172.769 93.523 186.04 90.1615 191.226 93.5808"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M172.596 92.7134C172.596 92.7134 181.016 98.4186 190.553 96.4642"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask27_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="174"
          y="92"
          width="9"
          height="17"
        >
          <path
            d="M175.075 92.513C177.582 94.4095 180.292 97.9213 181.586 100.786C182.24 102.231 182.718 103.831 182.44 105.392C182.167 106.953 180.962 108.437 179.387 108.599C178.217 108.719 177.058 108.098 176.288 107.208C175.518 106.317 175.087 105.184 174.771 104.047C173.889 100.839 173.859 97.3971 174.69 94.1744L175.075 92.5091V92.513Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask27_195_50053)">
          <path
            d="M175.075 92.513C177.582 94.4095 180.292 97.9213 181.586 100.786C182.24 102.231 182.718 103.831 182.44 105.392C182.167 106.953 180.962 108.437 179.387 108.599C178.217 108.719 177.058 108.098 176.288 107.208C175.518 106.317 175.087 105.184 174.771 104.047C173.889 100.839 173.859 97.3971 174.69 94.1744L175.075 92.5091V92.513Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M175.699 94.5792C175.699 94.5792 179.368 101.398 177.97 101.61C176.573 101.822 175.699 94.5792 175.699 94.5792Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M174.667 91.7651C174.667 91.7651 182.856 102.751 181.69 108.858"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M175.345 91.291C175.345 91.291 173.308 101.264 178.771 109.336"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask28_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="172"
          y="77"
          width="9"
          height="18"
        >
          <path
            d="M174.914 94.0588C173.459 91.2717 172.511 86.935 172.561 83.7932C172.588 82.205 172.839 80.5552 173.755 79.2599C174.671 77.9647 176.392 77.1397 177.886 77.6679C178.995 78.0611 179.78 79.1173 180.092 80.2545C180.408 81.3917 180.316 82.5982 180.111 83.7624C179.542 87.0429 178.094 90.1653 175.969 92.7211L174.91 94.0626L174.914 94.0588Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask28_195_50053)">
          <path
            d="M174.914 94.0588C173.459 91.2717 172.511 86.935 172.561 83.7932C172.588 82.205 172.839 80.5552 173.755 79.2599C174.671 77.9647 176.392 77.1397 177.886 77.6679C178.995 78.0611 179.78 79.1173 180.092 80.2545C180.408 81.3917 180.316 82.5982 180.111 83.7624C179.542 87.0429 178.094 90.1653 175.969 92.7211L174.91 94.0626L174.914 94.0588Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M180.031 82.3785C180.111 82.444 180.192 82.5134 180.269 82.5789C180.331 81.7925 180.304 81.0061 180.096 80.2506C179.78 79.1134 178.999 78.0572 177.89 77.664C176.396 77.1358 174.675 77.9608 173.759 79.256C173.343 79.842 173.066 80.5011 172.885 81.195C175.283 80.6322 178.109 80.8018 180.034 82.3746L180.031 82.3785Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M175.01 93.6501C174.113 91.9385 173.524 89.2787 173.551 87.3551C173.562 86.3837 173.716 85.3737 174.271 84.5796C174.829 83.7894 175.88 83.2844 176.796 83.612C177.478 83.8549 177.959 84.5025 178.152 85.2002C178.348 85.898 178.294 86.6381 178.171 87.3474C177.828 89.3558 176.95 91.2639 175.653 92.829L175.01 93.6501Z"
              fill="white"
            />
          </g>
          <path
            d="M175.233 91.927C175.233 91.927 174.825 84.1903 176.181 84.5989C177.536 85.0075 175.233 91.927 175.233 91.927Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M174.96 94.9107C174.96 94.9107 172.25 81.4764 175.907 76.4536"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M174.147 95.0495C174.147 95.0495 180.246 86.908 178.756 77.2708"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask29_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="169"
          y="93"
          width="9"
          height="17"
        >
          <path
            d="M174.694 93.2492C176.15 96.0363 177.097 100.373 177.047 103.515C177.02 105.103 176.77 106.753 175.853 108.048C174.937 109.343 173.216 110.168 171.722 109.64C170.613 109.247 169.828 108.191 169.516 107.054C169.2 105.916 169.293 104.71 169.497 103.546C170.067 100.265 171.51 97.1426 173.639 94.5869L174.698 93.2454L174.694 93.2492Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask29_195_50053)">
          <path
            d="M174.694 93.2492C176.15 96.0363 177.097 100.373 177.047 103.515C177.02 105.103 176.77 106.753 175.853 108.048C174.937 109.343 173.216 110.168 171.722 109.64C170.613 109.247 169.828 108.191 169.516 107.054C169.2 105.916 169.293 104.71 169.497 103.546C170.067 100.265 171.51 97.1426 173.639 94.5869L174.698 93.2454L174.694 93.2492Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M169.578 104.93C169.497 104.864 169.416 104.795 169.339 104.729C169.277 105.515 169.304 106.302 169.512 107.057C169.828 108.195 170.61 109.251 171.718 109.644C173.212 110.172 174.933 109.347 175.85 108.052C176.265 107.466 176.543 106.807 176.724 106.113C174.325 106.676 171.499 106.506 169.574 104.933L169.578 104.93Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M174.598 93.6578C175.495 95.3694 176.084 98.0293 176.057 99.9528C176.046 100.924 175.892 101.934 175.337 102.728C174.779 103.519 173.728 104.024 172.812 103.696C172.13 103.453 171.649 102.805 171.456 102.108C171.26 101.41 171.314 100.67 171.437 99.9605C171.78 97.9522 172.658 96.044 173.955 94.4789L174.598 93.6578Z"
              fill="white"
            />
          </g>
          <path
            d="M174.375 95.381C174.375 95.381 174.783 103.118 173.428 102.709C172.072 102.3 174.375 95.381 174.375 95.381Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M174.648 92.3973C174.648 92.3973 177.359 105.832 173.701 110.854"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M175.461 92.2624C175.461 92.2624 169.362 100.404 170.852 110.041"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask30_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="174"
          y="84"
          width="16"
          height="11"
        >
          <path
            d="M174.536 93.9778C175.988 91.1907 178.998 87.9295 181.605 86.1756C182.922 85.2889 184.415 84.5527 186.002 84.5642C187.588 84.5758 189.247 85.5202 189.671 87.0468C189.983 88.1839 189.567 89.4291 188.816 90.3388C188.065 91.2486 187.022 91.8615 185.956 92.3588C182.941 93.7619 179.557 94.3633 176.246 94.0742L174.54 93.974L174.536 93.9778Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask30_195_50053)">
          <path
            d="M174.536 93.9778C175.988 91.1907 178.998 87.9295 181.605 86.1756C182.922 85.2889 184.415 84.5527 186.002 84.5642C187.588 84.5758 189.247 85.5202 189.671 87.0468C189.983 88.1839 189.567 89.4291 188.816 90.3388C188.065 91.2486 187.022 91.8615 185.956 92.3588C182.941 93.7619 179.557 94.3633 176.246 94.0742L174.54 93.974L174.536 93.9778Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M187.034 91.503C187.026 91.6071 187.014 91.7111 187.003 91.8152C187.68 91.4182 188.312 90.944 188.812 90.3427C189.563 89.4329 189.983 88.1878 189.667 87.0506C189.244 85.5202 187.584 84.5796 185.998 84.5681C185.282 84.5642 184.581 84.7107 183.911 84.9574C185.744 86.6073 187.218 89.0243 187.03 91.5068L187.034 91.503Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M174.921 93.8198C175.81 92.1044 177.651 90.0998 179.244 89.0205C180.049 88.4769 180.962 88.022 181.932 88.0259C182.898 88.0298 183.915 88.608 184.169 89.5486C184.357 90.2463 184.103 91.0134 183.641 91.5724C183.183 92.1313 182.544 92.5091 181.89 92.8175C180.049 93.6848 177.982 94.0549 175.957 93.8814L174.917 93.8198H174.921Z"
              fill="white"
            />
          </g>
          <path
            d="M176.465 93.018C176.465 93.018 182.571 88.2611 183.014 89.6103C183.457 90.9595 176.465 93.018 176.465 93.018Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M173.862 94.5021C173.862 94.5021 183.322 84.5951 189.532 84.73"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M173.289 93.9123C173.289 93.9123 183.449 94.2708 190.491 87.5363"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask31_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="159"
          y="93"
          width="17"
          height="10"
        >
          <path
            d="M175.071 93.3302C173.62 96.1173 170.609 99.3785 168.003 101.132C166.686 102.019 165.192 102.755 163.606 102.744C162.02 102.732 160.36 101.788 159.937 100.261C159.625 99.124 160.041 97.8789 160.791 96.9692C161.542 96.0594 162.586 95.4465 163.652 94.9492C166.667 93.5461 170.051 92.9447 173.362 93.2338L175.068 93.334L175.071 93.3302Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask31_195_50053)">
          <path
            d="M175.071 93.3302C173.62 96.1173 170.609 99.3785 168.003 101.132C166.686 102.019 165.192 102.755 163.606 102.744C162.02 102.732 160.36 101.788 159.937 100.261C159.625 99.124 160.041 97.8789 160.791 96.9692C161.542 96.0594 162.586 95.4465 163.652 94.9492C166.667 93.5461 170.051 92.9447 173.362 93.2338L175.068 93.334L175.071 93.3302Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M162.574 95.805C162.582 95.7009 162.593 95.5968 162.605 95.4927C161.927 95.8898 161.296 96.3639 160.795 96.9653C160.044 97.875 159.625 99.1202 159.941 100.257C160.364 101.788 162.023 102.728 163.61 102.74C164.326 102.744 165.026 102.597 165.696 102.351C163.864 100.701 162.389 98.2837 162.578 95.8011L162.574 95.805Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M174.687 93.4882C173.797 95.2036 171.957 97.2082 170.363 98.2875C169.558 98.8311 168.646 99.2859 167.676 99.2821C166.709 99.2782 165.693 98.7 165.439 97.7594C165.25 97.0617 165.504 96.2946 165.966 95.7356C166.424 95.1767 167.063 94.7989 167.718 94.4905C169.558 93.6231 171.626 93.2531 173.651 93.4265L174.691 93.4882H174.687Z"
              fill="white"
            />
          </g>
          <path
            d="M173.142 94.29C173.142 94.29 167.036 99.0469 166.593 97.6977C166.151 96.3524 173.142 94.29 173.142 94.29Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M175.745 92.8059C175.745 92.8059 166.286 102.713 160.075 102.578"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M176.319 93.3957C176.319 93.3957 166.159 93.0372 159.117 99.7717"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask32_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="174"
          y="93"
          width="15"
          height="12"
        >
          <path
            d="M174.394 93.5731C177.532 93.5152 181.832 94.6023 184.62 96.0556C186.025 96.788 187.388 97.7517 188.135 99.1511C188.882 100.55 188.851 102.462 187.712 103.565C186.864 104.386 185.571 104.613 184.416 104.386C183.261 104.159 182.221 103.538 181.274 102.836C178.598 100.859 176.454 98.1719 175.122 95.1227L174.394 93.5769V93.5731Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask32_195_50053)">
          <path
            d="M174.394 93.5731C177.532 93.5152 181.832 94.6023 184.62 96.0556C186.025 96.788 187.388 97.7517 188.135 99.1511C188.882 100.55 188.851 102.462 187.712 103.565C186.864 104.386 185.571 104.613 184.416 104.386C183.261 104.159 182.221 103.538 181.274 102.836C178.598 100.859 176.454 98.1719 175.122 95.1227L174.394 93.5769V93.5731Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M182.541 103.376C182.445 103.418 182.348 103.461 182.256 103.499C182.93 103.908 183.646 104.232 184.412 104.382C185.567 104.61 186.861 104.382 187.708 103.561C188.847 102.459 188.878 100.547 188.131 99.1472C187.792 98.515 187.326 97.9715 186.791 97.4973C186.221 99.8989 184.808 102.351 182.541 103.376Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M174.713 93.8391C176.642 93.8005 179.283 94.4597 180.993 95.3425C181.855 95.7896 182.691 96.3756 183.149 97.2275C183.607 98.0794 183.584 99.2474 182.883 99.922C182.364 100.423 181.567 100.566 180.858 100.427C180.15 100.288 179.511 99.9105 178.929 99.4826C177.289 98.276 175.972 96.6377 175.156 94.7758L174.713 93.8314V93.8391Z"
              fill="white"
            />
          </g>
          <path
            d="M176.157 94.8105C176.157 94.8105 183.249 97.9021 182.283 98.9352C181.313 99.9683 176.157 94.8105 176.157 94.8105Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M173.608 93.2338C173.608 93.2338 186.826 96.8111 189.675 102.331"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M173.851 92.4436C173.851 92.4436 178.402 101.545 187.677 104.517"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask33_195_50053"
          mode="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="160"
          y="82"
          width="16"
          height="12"
        >
          <path
            d="M175.214 93.735C172.076 93.7928 167.776 92.7057 164.988 91.2524C163.583 90.52 162.22 89.5563 161.473 88.157C160.726 86.7576 160.757 84.8456 161.897 83.7431C162.744 82.922 164.037 82.6946 165.192 82.922C166.347 83.1495 167.387 83.7701 168.334 84.4717C171.01 86.4492 173.154 89.1361 174.486 92.1853L175.214 93.7311V93.735Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask33_195_50053)">
          <path
            d="M175.214 93.735C172.076 93.7928 167.776 92.7057 164.988 91.2524C163.583 90.52 162.22 89.5563 161.473 88.157C160.726 86.7576 160.757 84.8456 161.897 83.7431C162.744 82.922 164.037 82.6946 165.192 82.922C166.347 83.1495 167.387 83.7701 168.334 84.4717C171.01 86.4492 173.154 89.1361 174.486 92.1853L175.214 93.7311V93.735Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M167.067 83.932C167.164 83.8896 167.26 83.8472 167.352 83.8087C166.678 83.4 165.962 83.0762 165.196 82.9259C164.041 82.6984 162.748 82.9259 161.9 83.747C160.761 84.8495 160.73 86.7615 161.477 88.1608C161.816 88.793 162.282 89.3365 162.817 89.8107C163.387 87.4091 164.8 84.9574 167.067 83.932Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M174.894 93.4689C172.966 93.5075 170.324 92.8483 168.615 91.9656C167.753 91.5184 166.917 90.9324 166.459 90.0805C166.001 89.2286 166.024 88.0606 166.725 87.386C167.244 86.8848 168.041 86.7422 168.75 86.881C169.458 87.0159 170.097 87.3975 170.679 87.8254C172.319 89.032 173.636 90.6703 174.452 92.5322L174.894 93.4767V93.4689Z"
              fill="white"
            />
          </g>
          <path
            d="M173.451 92.4975C173.451 92.4975 166.359 89.4059 167.325 88.3728C168.292 87.3397 173.451 92.4975 173.451 92.4975Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M176 94.0742C176 94.0742 162.782 90.4969 159.933 84.9767"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M175.757 94.8644C175.757 94.8644 171.206 85.7631 161.931 82.791"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_195_50053">
          <rect width="312" height="296" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
