import { FC } from "react";
interface SettingIconProps {}
export const Star: FC<SettingIconProps> = ({}) => {
  return (
    <svg
      width="1307"
      height="470"
      viewBox="0 0 1307 470"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M827.82 276.022C832.007 276.022 835.4 272.402 835.4 267.937C835.4 263.473 832.007 259.853 827.82 259.853C823.634 259.853 820.24 263.473 820.24 267.937C820.24 272.402 823.634 276.022 827.82 276.022Z"
        fill="white"
      />
      <path
        d="M0.01 297.32H230.21L180.45 290.708C180.45 290.708 177.14 255.896 149.47 285.204C159.11 211.038 114.38 219.368 90.76 275.318C90.76 275.318 57.1 243.354 34.14 292.585L0 297.32H0.01Z"
        fill="white"
      />
      <path
        d="M175 205.418L170.95 200.747C170.36 200.064 169.5 199.744 168.64 199.872L162.73 200.779C160.57 201.109 158.99 198.656 160.05 196.619L162.97 191.063C163.39 190.252 163.42 189.281 163.04 188.45L160.41 182.733C159.45 180.643 161.15 178.275 163.29 178.734L169.14 179.981C169.99 180.163 170.87 179.885 171.49 179.235L175.77 174.798C177.34 173.177 179.97 174.169 180.23 176.483L180.93 182.808C181.03 183.725 181.55 184.535 182.32 184.962L187.6 187.927C189.53 189.015 189.46 191.99 187.47 192.972L182.05 195.638C181.26 196.022 180.7 196.8 180.55 197.718L179.53 203.989C179.16 206.282 176.48 207.135 175 205.418Z"
        fill="#F5E6C7"
      />
      <path
        d="M657.88 401.21L652.5 399.834C651.72 399.632 650.89 399.856 650.3 400.432L646.19 404.388C644.68 405.839 642.28 404.815 642.12 402.661L641.68 396.773C641.62 395.92 641.16 395.152 640.46 394.726L635.67 391.782C633.91 390.705 634.08 387.943 635.95 387.111L641.06 384.85C641.8 384.519 642.35 383.826 642.51 382.983L643.66 377.213C644.08 375.102 646.59 374.419 647.91 376.061L651.51 380.541C652.03 381.191 652.82 381.533 653.62 381.437L659.12 380.818C661.14 380.594 662.52 382.93 661.46 384.775L658.57 389.809C658.15 390.545 658.1 391.451 658.42 392.23L660.67 397.616C661.5 399.589 659.84 401.711 657.87 401.21H657.88Z"
        fill="#F5E6C7"
      />
      <path
        d="M780.7 468.636L771.22 460.349C769.84 459.143 767.99 458.738 766.28 459.272L754.5 462.908C750.19 464.241 746.32 459.719 747.95 455.261L752.41 443.082C753.06 441.311 752.85 439.317 751.85 437.738L744.97 426.913C742.45 422.946 745.29 417.624 749.77 417.901L762.01 418.658C763.79 418.765 765.51 417.933 766.6 416.44L774.13 406.116C776.89 402.341 782.51 403.567 783.65 408.196L786.75 420.845C787.2 422.679 788.47 424.172 790.15 424.812L801.68 429.26C805.9 430.891 806.54 436.971 802.76 439.552L792.44 446.612C790.94 447.636 790.01 449.385 789.95 451.283L789.55 464.348C789.4 469.137 784.18 471.664 780.7 468.625V468.636Z"
        fill="#FFC96A"
      />
      <path
        d="M1209.48 120.542C1213.66 120.542 1217.06 116.923 1217.06 112.458C1217.06 107.993 1213.66 104.374 1209.48 104.374C1205.29 104.374 1201.9 107.993 1201.9 112.458C1201.9 116.923 1205.29 120.542 1209.48 120.542Z"
        fill="white"
      />
      <path
        d="M1101 70.7096H1306.21L1261.86 64.8118C1261.86 64.8118 1258.91 33.776 1234.25 59.9058C1242.84 -6.20796 1202.97 1.21505 1181.92 51.0856C1181.92 51.0856 1151.91 22.5881 1131.45 66.4755L1101.01 70.699L1101 70.7096Z"
        fill="white"
      />
      <path
        d="M927.056 31.3064L920.756 29.6959C919.836 29.4613 918.876 29.7279 918.176 30.3998L913.366 35.0285C911.606 36.7243 908.786 35.5298 908.596 33.0021L908.076 26.1124C908.006 25.1099 907.466 24.214 906.656 23.7127L901.046 20.2572C898.986 18.988 899.186 15.7671 901.376 14.7966L907.356 12.141C908.226 11.757 908.866 10.9358 909.056 9.95458L910.396 3.19283C910.886 0.7185 913.826 -0.0813962 915.376 1.83834L919.586 7.08565C920.196 7.85354 921.126 8.23749 922.066 8.13083L928.506 7.40559C930.866 7.13896 932.486 9.87994 931.246 12.0343L927.866 17.9322C927.376 18.7854 927.306 19.8519 927.696 20.7691L930.336 27.0829C931.306 29.3973 929.366 31.8823 927.056 31.2957V31.3064Z"
        fill="#FFC96A"
      />
      <path
        d="M805.465 159.374L786.305 154.5C783.515 153.786 780.585 154.586 778.455 156.633L763.825 170.701C758.465 175.852 749.895 172.215 749.325 164.547L747.755 143.6C747.525 140.561 745.905 137.82 743.425 136.295L726.355 125.8C720.105 121.961 720.705 112.138 727.365 109.194L745.555 101.132C748.195 99.9583 750.135 97.4733 750.725 94.4871L754.805 73.9352C756.305 66.4056 765.235 63.9846 769.925 69.8184L782.735 85.7736C784.595 88.0986 787.415 89.2931 790.255 88.9625L809.845 86.7548C817.015 85.9442 821.945 94.2631 818.185 100.822L807.915 118.75C806.425 121.353 806.225 124.584 807.395 127.368L815.415 146.555C818.355 153.583 812.465 161.145 805.445 159.364L805.465 159.374Z"
        fill="#FFC96A"
      />
    </svg>
  );
};

export const Heart: FC<SettingIconProps> = ({}) => {
  return (
    <svg
      width="1071"
      height="659"
      viewBox="0 0 1071 659"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_195_49875)">
        <circle cx="226.49" cy="2.78999" r="2.5" fill="#FFC96A" />
        <path
          d="M226.49 14.69V210.92"
          stroke="#FFC96A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle cx="226.49" cy="216.86" r="2.5" fill="#FFC96A" />
        <path
          d="M189.88 196C189.88 199.5 187.21 202.34 183.93 202.34C180.65 202.34 177.98 199.5 177.98 196C177.98 192.5 180.65 189.66 183.93 189.66C187.21 189.66 189.88 192.5 189.88 196Z"
          fill="#F7ACC0"
        />
        <path
          d="M177.07 211.72C180.356 211.72 183.02 208.881 183.02 205.38C183.02 201.879 180.356 199.04 177.07 199.04C173.784 199.04 171.12 201.879 171.12 205.38C171.12 208.881 173.784 211.72 177.07 211.72Z"
          fill="#F7ACC0"
        />
        <path
          d="M184.47 227.03C184.47 230.53 181.8 233.37 178.52 233.37C175.24 233.37 172.57 230.53 172.57 227.03C172.57 223.53 175.24 220.69 178.52 220.69C181.8 220.69 184.47 223.53 184.47 227.03Z"
          fill="#F7ACC0"
        />
        <path
          d="M183.57 243.48C186.856 243.48 189.52 240.641 189.52 237.14C189.52 233.639 186.856 230.8 183.57 230.8C180.284 230.8 177.62 233.639 177.62 237.14C177.62 240.641 180.284 243.48 183.57 243.48Z"
          fill="#F7ACC0"
        />
        <path
          d="M194.71 246.79C194.71 250.29 192.04 253.13 188.76 253.13C185.48 253.13 182.81 250.29 182.81 246.79C182.81 243.29 185.48 240.45 188.76 240.45C192.04 240.45 194.71 243.29 194.71 246.79Z"
          fill="#F7ACC0"
        />
        <path
          d="M201.57 255.09C201.57 258.59 198.9 261.43 195.62 261.43C192.34 261.43 189.67 258.59 189.67 255.09C189.67 251.59 192.34 248.75 195.62 248.75C198.9 248.75 201.57 251.59 201.57 255.09Z"
          fill="#F7ACC0"
        />
        <path
          d="M203.19 268.64C206.476 268.64 209.14 265.801 209.14 262.3C209.14 258.799 206.476 255.96 203.19 255.96C199.904 255.96 197.24 258.799 197.24 262.3C197.24 265.801 199.904 268.64 203.19 268.64Z"
          fill="#F7ACC0"
        />
        <path
          d="M217.44 267.72C217.44 271.22 214.77 274.06 211.49 274.06C208.21 274.06 205.54 271.22 205.54 267.72C205.54 264.22 208.21 261.38 211.49 261.38C214.77 261.38 217.44 264.22 217.44 267.72Z"
          fill="#F7ACC0"
        />
        <path
          d="M220.51 279.47C223.796 279.47 226.46 276.631 226.46 273.13C226.46 269.629 223.796 266.79 220.51 266.79C217.224 266.79 214.56 269.629 214.56 273.13C214.56 276.631 217.224 279.47 220.51 279.47Z"
          fill="#F7ACC0"
        />
        <path
          d="M240.9 277.66C244.186 277.66 246.85 274.821 246.85 271.32C246.85 267.819 244.186 264.98 240.9 264.98C237.614 264.98 234.95 267.819 234.95 271.32C234.95 274.821 237.614 277.66 240.9 277.66Z"
          fill="#F7ACC0"
        />
        <path
          d="M255.51 266.99C255.51 270.49 252.84 273.33 249.56 273.33C246.28 273.33 243.61 270.49 243.61 266.99C243.61 263.49 246.28 260.65 249.56 260.65C252.84 260.65 255.51 263.49 255.51 266.99Z"
          fill="#F7ACC0"
        />
        <path
          d="M256.41 266.84C259.696 266.84 262.36 264.001 262.36 260.5C262.36 256.999 259.696 254.16 256.41 254.16C253.124 254.16 250.46 256.999 250.46 260.5C250.46 264.001 253.124 266.84 256.41 266.84Z"
          fill="#F7ACC0"
        />
        <path
          d="M262.19 258.9C265.476 258.9 268.14 256.061 268.14 252.56C268.14 249.059 265.476 246.22 262.19 246.22C258.904 246.22 256.24 249.059 256.24 252.56C256.24 256.061 258.904 258.9 262.19 258.9Z"
          fill="#F7ACC0"
        />
        <path
          d="M267.24 249.52C270.526 249.52 273.19 246.681 273.19 243.18C273.19 239.679 270.526 236.84 267.24 236.84C263.954 236.84 261.29 239.679 261.29 243.18C261.29 246.681 263.954 249.52 267.24 249.52Z"
          fill="#F7ACC0"
        />
        <path
          d="M277.88 234.52C277.88 238.02 275.21 240.86 271.93 240.86C268.65 240.86 265.98 238.02 265.98 234.52C265.98 231.02 268.65 228.18 271.93 228.18C275.21 228.18 277.88 231.02 277.88 234.52Z"
          fill="#F7ACC0"
        />
        <path
          d="M280.41 224.42C280.41 227.92 277.74 230.76 274.46 230.76C271.18 230.76 268.51 227.92 268.51 224.42C268.51 220.92 271.18 218.08 274.46 218.08C277.74 218.08 280.41 220.92 280.41 224.42Z"
          fill="#F7ACC0"
        />
        <path
          d="M280.41 203.85C280.41 207.35 277.74 210.19 274.46 210.19C271.18 210.19 268.51 207.35 268.51 203.85C268.51 200.35 271.18 197.51 274.46 197.51C277.74 197.51 280.41 200.35 280.41 203.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M275.72 195.19C275.72 198.69 273.05 201.53 269.77 201.53C266.49 201.53 263.82 198.69 263.82 195.19C263.82 191.69 266.49 188.85 269.77 188.85C273.05 188.85 275.72 191.69 275.72 195.19Z"
          fill="#F7ACC0"
        />
        <path
          d="M261.1 196.12C264.386 196.12 267.05 193.281 267.05 189.78C267.05 186.279 264.386 183.44 261.1 183.44C257.814 183.44 255.15 186.279 255.15 189.78C255.15 193.281 257.814 196.12 261.1 196.12Z"
          fill="#F7ACC0"
        />
        <path
          d="M251.72 197.92C255.006 197.92 257.67 195.081 257.67 191.58C257.67 188.079 255.006 185.24 251.72 185.24C248.434 185.24 245.77 188.079 245.77 191.58C245.77 195.081 248.434 197.92 251.72 197.92Z"
          fill="#F7ACC0"
        />
        <path
          d="M248.29 196.99C248.29 200.49 245.62 203.33 242.34 203.33C239.06 203.33 236.39 200.49 236.39 196.99C236.39 193.49 239.06 190.65 242.34 190.65C245.62 190.65 248.29 193.49 248.29 196.99Z"
          fill="#F7ACC0"
        />
        <path
          d="M239.99 203.85C239.99 207.35 237.32 210.19 234.04 210.19C230.76 210.19 228.09 207.35 228.09 203.85C228.09 200.35 230.76 197.51 234.04 197.51C237.32 197.51 239.99 200.35 239.99 203.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M233.14 210.34C233.14 213.84 230.47 216.68 227.19 216.68C223.91 216.68 221.24 213.84 221.24 210.34C221.24 206.84 223.91 204 227.19 204C230.47 204 233.14 206.84 233.14 210.34Z"
          fill="#F7ACC0"
        />
        <path
          d="M225.92 203.85C225.92 207.35 223.25 210.19 219.97 210.19C216.69 210.19 214.02 207.35 214.02 203.85C214.02 200.35 216.69 197.51 219.97 197.51C223.25 197.51 225.92 200.35 225.92 203.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M209.87 204.06C213.156 204.06 215.82 201.221 215.82 197.72C215.82 194.219 213.156 191.38 209.87 191.38C206.584 191.38 203.92 194.219 203.92 197.72C203.92 201.221 206.584 204.06 209.87 204.06Z"
          fill="#F7ACC0"
        />
        <path
          d="M200.85 199.73C204.136 199.73 206.8 196.891 206.8 193.39C206.8 189.889 204.136 187.05 200.85 187.05C197.564 187.05 194.9 189.889 194.9 193.39C194.9 196.891 197.564 199.73 200.85 199.73Z"
          fill="#F7ACC0"
        />
        <path
          d="M197.42 194.47C197.42 197.97 194.75 200.81 191.47 200.81C188.19 200.81 185.52 197.97 185.52 194.47C185.52 190.97 188.19 188.13 191.47 188.13C194.75 188.13 197.42 190.97 197.42 194.47Z"
          fill="#F7ACC0"
        />
        <path
          d="M281.85 214.67C281.85 218.17 279.18 221.01 275.9 221.01C272.62 221.01 269.95 218.17 269.95 214.67C269.95 211.17 272.62 208.33 275.9 208.33C279.18 208.33 281.85 211.17 281.85 214.67Z"
          fill="#F7ACC0"
        />
        <path
          d="M237.11 274.21C237.11 277.71 234.44 280.55 231.16 280.55C227.88 280.55 225.21 277.71 225.21 274.21C225.21 270.71 227.88 267.87 231.16 267.87C234.44 267.87 237.11 270.71 237.11 274.21Z"
          fill="#F7ACC0"
        />
        <path
          d="M183.03 215.85C183.03 219.35 180.36 222.19 177.08 222.19C173.8 222.19 171.13 219.35 171.13 215.85C171.13 212.35 173.8 209.51 177.08 209.51C180.36 209.51 183.03 212.35 183.03 215.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M264.07 190.27C253.27 187.28 238.23 196.31 226.7 211.47C213.92 197.36 198.17 189.64 187.66 193.53C174.39 198.45 174.52 219.99 187.96 241.64C195.72 254.15 206.23 263.93 216.28 269.03C217.92 270.66 226.85 273.2 229.33 272.87C231.83 272.99 240.51 269.7 242 267.94C251.58 262 261.21 251.36 267.88 238.24C279.42 215.52 277.72 194.05 264.08 190.28L264.07 190.27Z"
          fill="#F4627D"
        />
        <path
          opacity="0.28"
          d="M256.69 199.75C248.07 197.37 236.07 204.57 226.88 216.66C216.68 205.4 204.12 199.24 195.73 202.35C185.14 206.28 185.25 223.46 195.97 240.73C202.16 250.71 210.54 258.51 218.56 262.58C219.87 263.88 226.99 265.91 228.97 265.64C230.97 265.74 237.89 263.11 239.08 261.7C246.72 256.96 254.41 248.47 259.73 238C268.94 219.88 267.58 202.74 256.7 199.74L256.69 199.75Z"
          fill="white"
        />
        <path
          d="M235.91 230.69C235.68 233.6 233.24 235.9 230.27 235.9C227.15 235.9 224.61 233.37 224.61 230.24"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M212.91 236.1C212.91 237.77 210.83 239.13 208.26 239.13C205.69 239.13 203.61 237.77 203.61 236.1C203.61 234.43 205.69 233.07 208.26 233.07C210.83 233.07 212.91 234.43 212.91 236.1Z"
          fill="#F4627D"
        />
        <path
          d="M254.24 236.1C254.24 237.77 252.16 239.13 249.59 239.13C247.02 239.13 244.94 237.77 244.94 236.1C244.94 234.43 247.02 233.07 249.59 233.07C252.16 233.07 254.24 234.43 254.24 236.1Z"
          fill="#F4627D"
        />
        <mask
          id="mask0_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="207"
          y="216"
          width="12"
          height="16"
        >
          <path
            d="M212.85 231.26C215.888 231.26 218.35 227.996 218.35 223.97C218.35 219.944 215.888 216.68 212.85 216.68C209.813 216.68 207.35 219.944 207.35 223.97C207.35 227.996 209.813 231.26 212.85 231.26Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_195_49875)">
          <path
            d="M212.85 231.26C215.888 231.26 218.35 227.996 218.35 223.97C218.35 219.944 215.888 216.68 212.85 216.68C209.813 216.68 207.35 219.944 207.35 223.97C207.35 227.996 209.813 231.26 212.85 231.26Z"
            fill="white"
          />
          <path
            d="M218.93 224.22C218.93 228.59 217.54 232.13 215.82 232.13C214.1 232.13 212.71 228.59 212.71 224.22C212.71 219.85 214.1 216.31 215.82 216.31C217.54 216.31 218.93 219.85 218.93 224.22Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M218.32 223.62C218.32 223.62 217.28 217.68 212.7 217.2C212.7 217.2 208.6 217.75 207.36 223.98C207.36 223.98 206.92 216.72 213.27 216.4C213.27 216.4 217.69 216.32 218.33 223.63L218.32 223.62Z"
          fill="#6B3E3D"
        />
        <mask
          id="mask1_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="239"
          y="216"
          width="12"
          height="16"
        >
          <path
            d="M244.98 216.68C248.02 216.68 250.48 219.94 250.48 223.97C250.48 228 248.02 231.26 244.98 231.26C241.94 231.26 239.48 228 239.48 223.97C239.48 219.94 241.94 216.68 244.98 216.68Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_195_49875)">
          <path
            d="M244.98 216.68C248.02 216.68 250.48 219.94 250.48 223.97C250.48 228 248.02 231.26 244.98 231.26C241.94 231.26 239.48 228 239.48 223.97C239.48 219.94 241.94 216.68 244.98 216.68Z"
            fill="white"
          />
          <path
            d="M247.95 232.13C249.667 232.13 251.06 228.589 251.06 224.22C251.06 219.851 249.667 216.31 247.95 216.31C246.232 216.31 244.84 219.851 244.84 224.22C244.84 228.589 246.232 232.13 247.95 232.13Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M250.45 223.62C250.45 223.62 249.41 217.68 244.83 217.2C244.83 217.2 240.73 217.75 239.49 223.98C239.49 223.98 239.05 216.72 245.4 216.4C245.4 216.4 249.82 216.32 250.46 223.63L250.45 223.62Z"
          fill="#6B3E3D"
        />
        <circle cx="831.55" cy="2.78999" r="2.5" fill="#FFC96A" />
        <path
          d="M831.55 14.69V210.92"
          stroke="#FFC96A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle cx="831.55" cy="216.86" r="2.5" fill="#FFC96A" />
        <path
          d="M868.15 196C868.15 199.5 870.82 202.34 874.1 202.34C877.38 202.34 880.05 199.5 880.05 196C880.05 192.5 877.38 189.66 874.1 189.66C870.82 189.66 868.15 192.5 868.15 196Z"
          fill="#F7ACC0"
        />
        <path
          d="M875.01 205.38C875.01 208.88 877.68 211.72 880.96 211.72C884.24 211.72 886.91 208.88 886.91 205.38C886.91 201.88 884.24 199.04 880.96 199.04C877.68 199.04 875.01 201.88 875.01 205.38Z"
          fill="#F7ACC0"
        />
        <path
          d="M873.56 227.03C873.56 230.53 876.23 233.37 879.51 233.37C882.79 233.37 885.46 230.53 885.46 227.03C885.46 223.53 882.79 220.69 879.51 220.69C876.23 220.69 873.56 223.53 873.56 227.03Z"
          fill="#F7ACC0"
        />
        <path
          d="M868.51 237.14C868.51 240.64 871.18 243.48 874.46 243.48C877.74 243.48 880.41 240.64 880.41 237.14C880.41 233.64 877.74 230.8 874.46 230.8C871.18 230.8 868.51 233.64 868.51 237.14Z"
          fill="#F7ACC0"
        />
        <path
          d="M863.32 246.79C863.32 250.29 865.99 253.13 869.27 253.13C872.55 253.13 875.22 250.29 875.22 246.79C875.22 243.29 872.55 240.45 869.27 240.45C865.99 240.45 863.32 243.29 863.32 246.79Z"
          fill="#F7ACC0"
        />
        <path
          d="M856.47 255.09C856.47 258.59 859.14 261.43 862.42 261.43C865.7 261.43 868.37 258.59 868.37 255.09C868.37 251.59 865.7 248.75 862.42 248.75C859.14 248.75 856.47 251.59 856.47 255.09Z"
          fill="#F7ACC0"
        />
        <path
          d="M854.84 268.64C858.126 268.64 860.79 265.801 860.79 262.3C860.79 258.799 858.126 255.96 854.84 255.96C851.554 255.96 848.89 258.799 848.89 262.3C848.89 265.801 851.554 268.64 854.84 268.64Z"
          fill="#F7ACC0"
        />
        <path
          d="M840.59 267.72C840.59 271.22 843.26 274.06 846.54 274.06C849.82 274.06 852.49 271.22 852.49 267.72C852.49 264.22 849.82 261.38 846.54 261.38C843.26 261.38 840.59 264.22 840.59 267.72Z"
          fill="#F7ACC0"
        />
        <path
          d="M831.57 273.13C831.57 276.63 834.24 279.47 837.52 279.47C840.8 279.47 843.47 276.63 843.47 273.13C843.47 269.63 840.8 266.79 837.52 266.79C834.24 266.79 831.57 269.63 831.57 273.13Z"
          fill="#F7ACC0"
        />
        <path
          d="M817.14 277.66C820.427 277.66 823.09 274.821 823.09 271.32C823.09 267.819 820.427 264.98 817.14 264.98C813.854 264.98 811.19 267.819 811.19 271.32C811.19 274.821 813.854 277.66 817.14 277.66Z"
          fill="#F7ACC0"
        />
        <path
          d="M802.52 266.99C802.52 270.49 805.19 273.33 808.47 273.33C811.75 273.33 814.42 270.49 814.42 266.99C814.42 263.49 811.75 260.65 808.47 260.65C805.19 260.65 802.52 263.49 802.52 266.99Z"
          fill="#F7ACC0"
        />
        <path
          d="M795.67 260.5C795.67 264 798.34 266.84 801.62 266.84C804.9 266.84 807.57 264 807.57 260.5C807.57 257 804.9 254.16 801.62 254.16C798.34 254.16 795.67 257 795.67 260.5Z"
          fill="#F7ACC0"
        />
        <path
          d="M795.85 258.9C799.136 258.9 801.8 256.061 801.8 252.56C801.8 249.059 799.136 246.22 795.85 246.22C792.564 246.22 789.9 249.059 789.9 252.56C789.9 256.061 792.564 258.9 795.85 258.9Z"
          fill="#F7ACC0"
        />
        <path
          d="M784.84 243.18C784.84 246.68 787.51 249.52 790.79 249.52C794.07 249.52 796.74 246.68 796.74 243.18C796.74 239.68 794.07 236.84 790.79 236.84C787.51 236.84 784.84 239.68 784.84 243.18Z"
          fill="#F7ACC0"
        />
        <path
          d="M780.15 234.52C780.15 238.02 782.82 240.86 786.1 240.86C789.38 240.86 792.05 238.02 792.05 234.52C792.05 231.02 789.38 228.18 786.1 228.18C782.82 228.18 780.15 231.02 780.15 234.52Z"
          fill="#F7ACC0"
        />
        <path
          d="M777.63 224.42C777.63 227.92 780.3 230.76 783.58 230.76C786.86 230.76 789.53 227.92 789.53 224.42C789.53 220.92 786.86 218.08 783.58 218.08C780.3 218.08 777.63 220.92 777.63 224.42Z"
          fill="#F7ACC0"
        />
        <path
          d="M777.63 203.85C777.63 207.35 780.3 210.19 783.58 210.19C786.86 210.19 789.53 207.35 789.53 203.85C789.53 200.35 786.86 197.51 783.58 197.51C780.3 197.51 777.63 200.35 777.63 203.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M788.27 201.53C791.556 201.53 794.22 198.691 794.22 195.19C794.22 191.689 791.556 188.85 788.27 188.85C784.984 188.85 782.32 191.689 782.32 195.19C782.32 198.691 784.984 201.53 788.27 201.53Z"
          fill="#F7ACC0"
        />
        <path
          d="M790.98 189.78C790.98 193.28 793.65 196.12 796.93 196.12C800.21 196.12 802.88 193.28 802.88 189.78C802.88 186.28 800.21 183.44 796.93 183.44C793.65 183.44 790.98 186.28 790.98 189.78Z"
          fill="#F7ACC0"
        />
        <path
          d="M800.36 191.58C800.36 195.08 803.03 197.92 806.31 197.92C809.59 197.92 812.26 195.08 812.26 191.58C812.26 188.08 809.59 185.24 806.31 185.24C803.03 185.24 800.36 188.08 800.36 191.58Z"
          fill="#F7ACC0"
        />
        <path
          d="M809.74 196.99C809.74 200.49 812.41 203.33 815.69 203.33C818.97 203.33 821.64 200.49 821.64 196.99C821.64 193.49 818.97 190.65 815.69 190.65C812.41 190.65 809.74 193.49 809.74 196.99Z"
          fill="#F7ACC0"
        />
        <path
          d="M818.04 203.85C818.04 207.35 820.71 210.19 823.99 210.19C827.27 210.19 829.94 207.35 829.94 203.85C829.94 200.35 827.27 197.51 823.99 197.51C820.71 197.51 818.04 200.35 818.04 203.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M824.89 210.34C824.89 213.84 827.56 216.68 830.84 216.68C834.12 216.68 836.79 213.84 836.79 210.34C836.79 206.84 834.12 204 830.84 204C827.56 204 824.89 206.84 824.89 210.34Z"
          fill="#F7ACC0"
        />
        <path
          d="M832.11 203.85C832.11 207.35 834.78 210.19 838.06 210.19C841.34 210.19 844.01 207.35 844.01 203.85C844.01 200.35 841.34 197.51 838.06 197.51C834.78 197.51 832.11 200.35 832.11 203.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M842.21 197.72C842.21 201.22 844.88 204.06 848.16 204.06C851.44 204.06 854.11 201.22 854.11 197.72C854.11 194.22 851.44 191.38 848.16 191.38C844.88 191.38 842.21 194.22 842.21 197.72Z"
          fill="#F7ACC0"
        />
        <path
          d="M857.19 199.73C860.476 199.73 863.14 196.891 863.14 193.39C863.14 189.889 860.476 187.05 857.19 187.05C853.904 187.05 851.24 189.889 851.24 193.39C851.24 196.891 853.904 199.73 857.19 199.73Z"
          fill="#F7ACC0"
        />
        <path
          d="M860.62 194.47C860.62 197.97 863.29 200.81 866.57 200.81C869.85 200.81 872.52 197.97 872.52 194.47C872.52 190.97 869.85 188.13 866.57 188.13C863.29 188.13 860.62 190.97 860.62 194.47Z"
          fill="#F7ACC0"
        />
        <path
          d="M776.18 214.67C776.18 218.17 778.85 221.01 782.13 221.01C785.41 221.01 788.08 218.17 788.08 214.67C788.08 211.17 785.41 208.33 782.13 208.33C778.85 208.33 776.18 211.17 776.18 214.67Z"
          fill="#F7ACC0"
        />
        <path
          d="M820.93 274.21C820.93 277.71 823.6 280.55 826.88 280.55C830.16 280.55 832.83 277.71 832.83 274.21C832.83 270.71 830.16 267.87 826.88 267.87C823.6 267.87 820.93 270.71 820.93 274.21Z"
          fill="#F7ACC0"
        />
        <path
          d="M875.01 215.85C875.01 219.35 877.68 222.19 880.96 222.19C884.24 222.19 886.91 219.35 886.91 215.85C886.91 212.35 884.24 209.51 880.96 209.51C877.68 209.51 875.01 212.35 875.01 215.85Z"
          fill="#F7ACC0"
        />
        <path
          d="M793.96 190.27C804.76 187.28 819.8 196.31 831.33 211.47C844.11 197.36 859.86 189.64 870.37 193.53C883.64 198.45 883.51 219.99 870.07 241.64C862.31 254.15 851.8 263.93 841.76 269.03C840.12 270.66 831.19 273.2 828.71 272.87C826.21 272.99 817.53 269.7 816.04 267.94C806.46 262 796.83 251.36 790.16 238.24C778.62 215.52 780.32 194.05 793.96 190.28V190.27Z"
          fill="#F4627D"
        />
        <path
          opacity="0.28"
          d="M801.34 199.75C809.96 197.37 821.96 204.57 831.15 216.66C841.35 205.4 853.91 199.24 862.3 202.35C872.89 206.28 872.79 223.46 862.06 240.73C855.87 250.71 847.49 258.51 839.47 262.58C838.16 263.88 831.04 265.91 829.06 265.64C827.06 265.74 820.14 263.11 818.95 261.7C811.31 256.96 803.62 248.47 798.3 238C789.09 219.88 790.45 202.74 801.33 199.74L801.34 199.75Z"
          fill="white"
        />
        <path
          d="M822.12 230.69C822.35 233.6 824.79 235.9 827.76 235.9C830.88 235.9 833.42 233.37 833.42 230.24"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M845.13 236.1C845.13 237.77 847.21 239.13 849.78 239.13C852.35 239.13 854.43 237.77 854.43 236.1C854.43 234.43 852.35 233.07 849.78 233.07C847.21 233.07 845.13 234.43 845.13 236.1Z"
          fill="#F4627D"
        />
        <path
          d="M803.79 236.1C803.79 237.77 805.87 239.13 808.44 239.13C811.01 239.13 813.09 237.77 813.09 236.1C813.09 234.43 811.01 233.07 808.44 233.07C805.87 233.07 803.79 234.43 803.79 236.1Z"
          fill="#F4627D"
        />
        <mask
          id="mask2_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="839"
          y="216"
          width="12"
          height="16"
        >
          <path
            d="M845.18 231.26C848.218 231.26 850.68 227.996 850.68 223.97C850.68 219.944 848.218 216.68 845.18 216.68C842.143 216.68 839.68 219.944 839.68 223.97C839.68 227.996 842.143 231.26 845.18 231.26Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_195_49875)">
          <path
            d="M845.18 231.26C848.218 231.26 850.68 227.996 850.68 223.97C850.68 219.944 848.218 216.68 845.18 216.68C842.143 216.68 839.68 219.944 839.68 223.97C839.68 227.996 842.143 231.26 845.18 231.26Z"
            fill="white"
          />
          <path
            d="M842.22 232.13C843.938 232.13 845.33 228.589 845.33 224.22C845.33 219.851 843.938 216.31 842.22 216.31C840.503 216.31 839.11 219.851 839.11 224.22C839.11 228.589 840.503 232.13 842.22 232.13Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M839.71 223.62C839.71 223.62 840.75 217.68 845.33 217.2C845.33 217.2 849.43 217.75 850.67 223.98C850.67 223.98 851.11 216.72 844.76 216.4C844.76 216.4 840.34 216.32 839.7 223.63L839.71 223.62Z"
          fill="#6B3E3D"
        />
        <mask
          id="mask3_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="807"
          y="216"
          width="12"
          height="16"
        >
          <path
            d="M813.05 231.26C816.088 231.26 818.55 227.996 818.55 223.97C818.55 219.944 816.088 216.68 813.05 216.68C810.013 216.68 807.55 219.944 807.55 223.97C807.55 227.996 810.013 231.26 813.05 231.26Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_195_49875)">
          <path
            d="M813.05 231.26C816.088 231.26 818.55 227.996 818.55 223.97C818.55 219.944 816.088 216.68 813.05 216.68C810.013 216.68 807.55 219.944 807.55 223.97C807.55 227.996 810.013 231.26 813.05 231.26Z"
            fill="white"
          />
          <path
            d="M806.98 224.22C806.98 228.59 808.371 232.13 810.091 232.13C811.811 232.13 813.201 228.59 813.201 224.22C813.201 219.85 811.811 216.31 810.091 216.31C808.371 216.31 806.98 219.85 806.98 224.22Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M807.58 223.62C807.58 223.62 808.62 217.68 813.2 217.2C813.2 217.2 817.3 217.75 818.54 223.98C818.54 223.98 818.98 216.72 812.63 216.4C812.63 216.4 808.21 216.32 807.57 223.63L807.58 223.62Z"
          fill="#6B3E3D"
        />
        <circle cx="81.71" cy="9.39" r="2.5" fill="#FFC96A" />
        <path
          d="M81.71 21.38V554.93"
          stroke="#FFC96A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle cx="81.71" cy="560.92" r="2.5" fill="#FFC96A" />
        <path
          d="M89.7502 507.63L103.25 549.17C104.38 552.65 107.62 555.01 111.28 555.01H154.96C163.14 555.01 166.54 565.48 159.92 570.29L124.58 595.97C121.62 598.12 120.38 601.93 121.51 605.41L135.01 646.95C137.54 654.73 128.63 661.2 122.01 656.39L86.6702 630.71C83.7102 628.56 79.7002 628.56 76.7402 630.71L41.4002 656.39C34.7802 661.2 25.8702 654.73 28.4002 646.95L41.9002 605.41C43.0302 601.93 41.7902 598.12 38.8302 595.97L3.49017 570.29C-3.12983 565.48 0.270174 555.01 8.45017 555.01H52.1302C55.7902 555.01 59.0302 552.65 60.1602 549.17L73.6602 507.63C76.1902 499.85 87.2002 499.85 89.7302 507.63H89.7502Z"
          fill="#FFC96A"
        />
        <path
          opacity="0.28"
          d="M87.3002 531.03L96.6902 559.93C97.4802 562.35 99.7302 563.99 102.28 563.99H132.67C138.36 563.99 140.73 571.27 136.12 574.62L111.54 592.48C109.48 593.98 108.62 596.63 109.41 599.05L118.8 627.95C120.56 633.36 114.36 637.86 109.76 634.52L85.1802 616.66C83.1202 615.16 80.3302 615.16 78.2702 616.66L53.6902 634.52C49.0902 637.87 42.8902 633.36 44.6502 627.95L54.0402 599.05C54.8302 596.63 53.9602 593.98 51.9102 592.48L27.3302 574.62C22.7302 571.27 25.0902 563.99 30.7802 563.99H61.1702C63.7202 563.99 65.9702 562.35 66.7602 559.93L76.1502 531.03C77.9102 525.62 85.5702 525.62 87.3302 531.03H87.3002Z"
          fill="white"
        />
        <path
          d="M89.1302 581.54C88.9002 584.41 86.5002 586.67 83.5702 586.67C80.4902 586.67 77.9902 584.17 77.9902 581.09"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M66.45 586.87C66.45 588.52 64.4 589.86 61.87 589.86C59.34 589.86 57.29 588.52 57.29 586.87C57.29 585.22 59.34 583.88 61.87 583.88C64.4 583.88 66.45 585.22 66.45 586.87Z"
          fill="#F4627D"
        />
        <path
          d="M107.2 586.87C107.2 588.52 105.15 589.86 102.62 589.86C100.09 589.86 98.04 588.52 98.04 586.87C98.04 585.22 100.09 583.88 102.62 583.88C105.15 583.88 107.2 585.22 107.2 586.87Z"
          fill="#F4627D"
        />
        <mask
          id="mask4_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="60"
          y="567"
          width="12"
          height="16"
        >
          <path
            d="M66.4005 582.11C69.3939 582.11 71.8205 578.891 71.8205 574.92C71.8205 570.949 69.3939 567.73 66.4005 567.73C63.4071 567.73 60.9805 570.949 60.9805 574.92C60.9805 578.891 63.4071 582.11 66.4005 582.11Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_195_49875)">
          <path
            d="M66.4005 582.11C69.3939 582.11 71.8205 578.891 71.8205 574.92C71.8205 570.949 69.3939 567.73 66.4005 567.73C63.4071 567.73 60.9805 570.949 60.9805 574.92C60.9805 578.891 63.4071 582.11 66.4005 582.11Z"
            fill="white"
          />
          <path
            d="M69.3203 582.96C71.0102 582.96 72.3802 579.468 72.3802 575.16C72.3802 570.852 71.0102 567.36 69.3203 567.36C67.6303 567.36 66.2603 570.852 66.2603 575.16C66.2603 579.468 67.6303 582.96 69.3203 582.96Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M71.7904 574.57C71.7904 574.57 70.7604 568.71 66.2504 568.24C66.2504 568.24 62.2004 568.78 60.9804 574.92C60.9804 574.92 60.5504 567.76 66.8004 567.45C66.8004 567.45 71.1504 567.37 71.7904 574.57Z"
          fill="#6B3E3D"
        />
        <mask
          id="mask5_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="92"
          y="567"
          width="12"
          height="16"
        >
          <path
            d="M98.0704 582.11C101.064 582.11 103.49 578.891 103.49 574.92C103.49 570.949 101.064 567.73 98.0704 567.73C95.077 567.73 92.6504 570.949 92.6504 574.92C92.6504 578.891 95.077 582.11 98.0704 582.11Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_195_49875)">
          <path
            d="M98.0704 582.11C101.064 582.11 103.49 578.891 103.49 574.92C103.49 570.949 101.064 567.73 98.0704 567.73C95.077 567.73 92.6504 570.949 92.6504 574.92C92.6504 578.891 95.077 582.11 98.0704 582.11Z"
            fill="white"
          />
          <path
            d="M101 582.96C102.69 582.96 104.06 579.468 104.06 575.16C104.06 570.852 102.69 567.36 101 567.36C99.3104 567.36 97.9404 570.852 97.9404 575.16C97.9404 579.468 99.3104 582.96 101 582.96Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M103.46 574.57C103.46 574.57 102.43 568.71 97.9203 568.24C97.9203 568.24 93.8703 568.78 92.6503 574.92C92.6503 574.92 92.2203 567.76 98.4703 567.45C98.4703 567.45 102.82 567.37 103.46 574.57Z"
          fill="#6B3E3D"
        />
        <circle cx="989.18" cy="2.5" r="2.5" fill="#FFC96A" />
        <path
          d="M989.18 14.47V427.42"
          stroke="#FFC96A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle cx="989.18" cy="433.4" r="2.5" fill="#FFC96A" />
        <path
          d="M981.15 380.16L967.65 421.7C966.52 425.18 963.28 427.54 959.62 427.54H915.94C907.76 427.54 904.35 438.01 910.97 442.82L946.31 468.5C949.27 470.65 950.51 474.46 949.38 477.94L935.88 519.48C933.35 527.26 942.26 533.73 948.88 528.92L984.22 503.24C987.18 501.09 991.19 501.09 994.15 503.24L1029.49 528.92C1036.11 533.73 1045.02 527.26 1042.49 519.48L1028.99 477.94C1027.86 474.46 1029.1 470.65 1032.06 468.5L1067.4 442.82C1074.02 438.01 1070.62 427.54 1062.43 427.54H1018.75C1015.09 427.54 1011.85 425.18 1010.72 421.7L997.22 380.16C994.69 372.38 983.68 372.38 981.15 380.16Z"
          fill="#FFC96A"
        />
        <path
          opacity="0.28"
          d="M983.59 403.56L974.2 432.46C973.41 434.88 971.16 436.52 968.61 436.52H938.22C932.53 436.52 930.16 443.8 934.77 447.15L959.35 465.01C961.41 466.51 962.27 469.16 961.48 471.58L952.09 500.48C950.33 505.89 956.53 510.39 961.13 507.05L985.71 489.19C987.77 487.69 990.56 487.69 992.62 489.19L1017.2 507.05C1021.8 510.4 1028 505.89 1026.24 500.48L1016.85 471.58C1016.06 469.16 1016.93 466.51 1018.98 465.01L1043.56 447.15C1048.16 443.8 1045.8 436.52 1040.11 436.52H1009.72C1007.17 436.52 1004.92 434.88 1004.13 432.46L994.74 403.56C992.98 398.15 985.32 398.15 983.56 403.56H983.59Z"
          fill="white"
        />
        <path
          d="M981.76 454.07C981.99 456.94 984.39 459.2 987.32 459.2C990.4 459.2 992.9 456.7 992.9 453.62"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M1004.44 459.4C1004.44 461.05 1006.49 462.39 1009.02 462.39C1011.55 462.39 1013.6 461.05 1013.6 459.4C1013.6 457.75 1011.55 456.41 1009.02 456.41C1006.49 456.41 1004.44 457.75 1004.44 459.4Z"
          fill="#F4627D"
        />
        <path
          d="M963.69 459.4C963.69 461.05 965.74 462.39 968.27 462.39C970.8 462.39 972.85 461.05 972.85 459.4C972.85 457.75 970.8 456.41 968.27 456.41C965.74 456.41 963.69 457.75 963.69 459.4Z"
          fill="#F4627D"
        />
        <mask
          id="mask6_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="999"
          y="440"
          width="11"
          height="15"
        >
          <path
            d="M1004.5 454.64C1007.49 454.64 1009.92 451.421 1009.92 447.45C1009.92 443.479 1007.49 440.26 1004.5 440.26C1001.51 440.26 999.08 443.479 999.08 447.45C999.08 451.421 1001.51 454.64 1004.5 454.64Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_195_49875)">
          <path
            d="M1004.5 454.64C1007.49 454.64 1009.92 451.421 1009.92 447.45C1009.92 443.479 1007.49 440.26 1004.5 440.26C1001.51 440.26 999.08 443.479 999.08 447.45C999.08 451.421 1001.51 454.64 1004.5 454.64Z"
            fill="white"
          />
          <path
            d="M1001.57 455.49C1003.26 455.49 1004.63 451.998 1004.63 447.69C1004.63 443.382 1003.26 439.89 1001.57 439.89C999.88 439.89 998.51 443.382 998.51 447.69C998.51 451.998 999.88 455.49 1001.57 455.49Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M999.11 447.1C999.11 447.1 1000.14 441.24 1004.65 440.77C1004.65 440.77 1008.7 441.31 1009.92 447.45C1009.92 447.45 1010.35 440.29 1004.1 439.98C1004.1 439.98 999.75 439.9 999.11 447.1Z"
          fill="#6B3E3D"
        />
        <mask
          id="mask7_195_49875"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="967"
          y="440"
          width="12"
          height="15"
        >
          <path
            d="M972.82 440.26C969.83 440.26 967.4 443.48 967.4 447.45C967.4 451.42 969.83 454.64 972.82 454.64C975.81 454.64 978.24 451.42 978.24 447.45C978.24 443.48 975.81 440.26 972.82 440.26Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_195_49875)">
          <path
            d="M972.82 440.26C969.83 440.26 967.4 443.48 967.4 447.45C967.4 451.42 969.83 454.64 972.82 454.64C975.81 454.64 978.24 451.42 978.24 447.45C978.24 443.48 975.81 440.26 972.82 440.26Z"
            fill="white"
          />
          <path
            d="M969.9 455.49C971.59 455.49 972.96 451.998 972.96 447.69C972.96 443.382 971.59 439.89 969.9 439.89C968.21 439.89 966.84 443.382 966.84 447.69C966.84 451.998 968.21 455.49 969.9 455.49Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M967.43 447.1C967.43 447.1 968.46 441.24 972.97 440.77C972.97 440.77 977.02 441.31 978.24 447.45C978.24 447.45 978.67 440.29 972.42 439.98C972.42 439.98 968.07 439.9 967.43 447.1Z"
          fill="#6B3E3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_195_49875">
          <rect width="1070.89" height="658.05" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
