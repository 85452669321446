import { FC } from "react";
interface SettingIconProps {}
export const Baby: FC<SettingIconProps> = ({}) => {
  return (
    <svg
      width="345"
      height="447"
      viewBox="0 0 345 447"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_195_49739)">
        <path
          d="M102.41 370.232C78.9178 361.499 65.1699 404.78 65.1699 404.78C65.1699 404.78 53.9304 393.489 49.2031 396.722C41.9915 401.644 43.7522 406.469 45.5852 414.068C47.4183 421.644 57.0659 428.785 65.5076 427.362C73.9493 425.938 92.1351 415.009 92.1351 415.009L137.455 417.808C137.455 417.808 135.332 382.488 102.434 370.256L102.41 370.232Z"
          fill="#FFCC89"
        />
        <path
          d="M46.7427 399.376C46.4774 399.376 45.1026 399.834 44.8373 401.523C44.572 403.212 46.381 404.466 47.2734 404.804"
          stroke="#9C5D37"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M69.5111 409.219C69.5111 409.219 54.8225 391.728 49.1787 396.722C43.5348 401.716 51.1564 402.126 51.1564 402.126"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M96.6451 396.409C96.6451 396.409 104.001 415.468 71.9712 425.215"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M46.1639 407.072C46.1639 407.072 44.2826 406.879 43.945 404.901C43.6073 402.898 44.7891 402.343 44.7891 402.343"
          stroke="#9C5D37"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M214.226 318.434C214.226 318.434 231.278 282.415 270.158 298.265C294.736 308.278 297.075 311.872 297.075 311.872C297.075 311.872 332.241 300.485 335.497 311.824C338.753 323.163 315.261 339.4 315.261 339.4C315.261 339.4 323.992 351.535 316.322 361.306C308.652 371.077 289.068 364.201 289.068 364.201C289.068 364.201 292.444 378.17 280.337 382.657C268.229 387.168 248.861 370.208 248.861 370.208C248.861 370.208 246.256 385.6 229.855 383.236C213.454 380.872 197.849 357.735 197.849 357.735"
          fill="white"
        />
        <path
          d="M297.075 311.872C297.075 311.872 331.156 300.195 335.497 311.824C341.334 327.385 315.261 339.4 315.261 339.4C315.261 339.4 328.43 347.433 316.323 361.306C304.215 375.178 289.068 364.201 289.068 364.201C289.068 364.201 292.372 383.043 276.864 384.322C261.355 385.6 248.861 370.208 248.861 370.208"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M199.779 355.684C199.779 355.684 217.699 304.176 234.149 297.18C250.598 290.183 283.52 301.378 283.424 305.021C283.303 308.664 264.201 308.784 264.201 308.784C264.201 308.784 273.583 314.188 267.554 319.424C261.524 324.659 254.36 320.799 254.36 320.799C254.36 320.799 261.186 332.741 251.563 334.623C241.939 336.505 233.353 325.527 233.353 325.527C233.353 325.527 232.557 333.947 222.933 335.829"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M280.819 326.806C280.819 326.806 311.137 331.149 306.096 341.571C301.055 352.017 275.995 342.85 275.995 342.85C275.995 342.85 289.984 351.438 280.264 359.183C270.544 366.927 254.626 346.903 254.409 348.374C254.192 349.87 262.657 358.169 254.119 364.563C245.581 370.956 239.286 354.84 239.286 354.84C239.286 354.84 236.151 373.176 228.143 372.524C220.111 371.897 218.833 352.427 218.833 352.427"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M122.38 292.644C122.38 292.644 107.451 255.708 67.7022 269.29C42.5942 277.879 40.0617 281.305 40.0617 281.305C40.0617 281.305 5.61961 267.915 1.68819 279.037C-2.21911 290.183 20.3082 307.723 20.3082 307.723C20.3082 307.723 10.9017 319.327 17.9927 329.532C25.0837 339.713 45.0303 333.995 45.0303 333.995C45.0303 333.995 40.8577 347.747 52.676 352.934C64.4944 358.121 84.8268 342.319 84.8268 342.319C84.8268 342.319 86.5393 357.856 103.061 356.432C119.558 355.009 136.466 332.813 136.466 332.813"
          fill="white"
        />
        <path
          d="M84.8029 342.319C84.8029 342.319 66.8583 358.435 54.5334 353.658C42.2086 348.881 45.0064 333.995 45.0064 333.995C45.0064 333.995 22.045 338.748 16.2564 328.857C10.4678 318.965 20.2843 307.747 20.2843 307.747C20.2843 307.747 -5.71615 284.731 2.50848 277.059C10.7572 269.387 40.0378 281.329 40.0378 281.329"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M134.633 330.666C134.633 330.666 119.703 278.217 103.712 270.28C87.6971 262.342 54.1715 271.631 54.0751 275.274C53.9786 278.917 73.0568 280.123 73.0568 280.123C73.0568 280.123 63.3851 284.972 69.1013 290.545C74.8175 296.118 82.198 292.668 82.198 292.668C82.198 292.668 74.6969 304.2 84.1757 306.637C93.6546 309.074 102.868 298.603 102.868 298.603C102.868 298.603 103.182 307.047 112.66 309.484"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M55.4019 297.156C55.4019 297.156 24.8671 299.737 29.3051 310.449C33.743 321.161 59.2851 313.465 59.2851 313.465C59.2851 313.465 44.8137 321.233 54.0995 329.532C63.3613 337.831 80.4135 318.748 80.5341 320.244C80.6547 321.74 71.7547 329.556 79.8829 336.432C88.011 343.308 95.2709 327.578 95.2709 327.578C95.2709 327.578 97.3451 346.058 105.377 345.889C113.408 345.72 115.82 326.348 115.82 326.348"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M140.735 307.723C112.467 329.822 107.137 400.896 129.857 412.211C152.578 423.526 206.894 425.625 216.083 413.682C225.273 401.74 224.694 324.707 205.809 314.381C186.923 304.056 154.145 297.252 140.735 307.747V307.723Z"
          fill="#FFCC89"
        />
        <path
          d="M112.636 361.788C112.636 361.788 140.349 382.005 173.875 383.791C207.4 385.576 223.054 368.375 223.054 368.375C223.054 368.375 236.15 414.985 199.61 423.719C163.069 432.428 78.5317 426.276 112.612 361.812L112.636 361.788Z"
          fill="white"
        />
        <path
          d="M160.392 382.585C160.392 382.585 124.117 373.175 112.636 361.812C112.636 361.812 78.7728 412.163 147.078 426.469"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M213.744 375.274L223.054 368.35C223.054 368.35 227.516 383.067 224.525 394.84"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M227.058 378.507C251.346 372.307 265.793 417.47 265.793 417.47C265.793 417.47 272.474 407.217 278.238 413.755C284.027 420.293 285.498 424.129 282.869 431.463C280.24 438.797 261.958 448.448 253.733 446.131C245.509 443.815 237.694 432.187 237.694 432.187L196.86 424.394C196.86 424.394 193.001 387.168 227.033 378.483L227.058 378.507Z"
          fill="#FFCC89"
        />
        <path
          d="M238.152 414.068C238.152 414.068 230.386 438.845 253.733 446.156"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M262.802 423.043C262.802 423.043 267.457 411.029 275.055 412.428C281.35 413.562 276.02 416.964 276.02 416.964C276.02 416.964 275.923 419.617 273.077 420.438"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M278.407 414.937C278.407 414.937 281.519 414.937 280.94 417.88C280.602 419.569 277.78 419.617 277.78 419.617L276.767 421.547"
          stroke="#9C5D37"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M280.554 417.856C280.554 417.856 283.665 418.049 282.893 420.944C282.459 422.609 280.481 421.789 280.481 421.789L279.348 423.646"
          stroke="#9C5D37"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M196.885 424.418C196.885 424.418 195.1 385.407 224.477 380.413"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M275.175 419.69C275.175 419.69 280.433 423.357 281.422 426.734"
          stroke="#9C5D37"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M96.2355 204.658C96.6214 226.323 81.1611 244.224 61.6969 244.658C42.2328 245.092 27.7131 227.191 27.3272 205.526C26.9413 183.862 40.8339 166.636 60.298 166.202C79.7622 165.767 95.8496 182.993 96.2355 204.658Z"
          fill="#FFCC89"
        />
        <path
          d="M81.6675 204.055C81.8846 216.817 72.7675 227.384 61.311 227.65C49.8303 227.891 40.3514 217.758 40.1102 204.971C39.8932 192.209 49.0102 181.642 60.4668 181.377C71.9475 181.135 81.4263 191.268 81.6675 204.055Z"
          fill="#FFA96E"
        />
        <path
          d="M339.959 243.283C338.368 264.9 321.339 281.329 301.924 279.978C282.508 278.627 268.036 260.026 269.628 238.41C271.22 216.793 288.248 200.388 307.664 201.715C327.08 203.066 341.551 221.666 339.959 243.283Z"
          fill="#FFCC89"
        />
        <path
          d="M323.896 241.884C322.955 254.622 312.922 264.321 301.465 263.524C290.008 262.728 281.494 251.751 282.411 239.013C283.351 226.274 293.385 216.576 304.842 217.372C316.298 218.168 324.812 229.145 323.896 241.884Z"
          fill="#FFA96E"
        />
        <path
          d="M212.417 92.5942L201.805 91.4121C188.539 89.9163 175.153 90.1334 161.96 92.0152C88.5657 98.4326 69.1498 153.946 66.9791 204.61L66.3279 219.761C14.2064 274.043 100.674 317.542 156.268 315.322L196.523 316.963C254.312 325.093 294.591 292.499 294.254 229.049L294.905 213.898C307.157 139.301 269.724 100.845 212.417 92.5942Z"
          fill="#FFCC89"
        />
        <path
          d="M192.905 317.831C192.905 317.831 250.694 325.648 274.862 291.438"
          stroke="#9C5D37"
          strokeMiterlimit="10"
        />
        <path
          d="M146.885 156.624C146.885 156.624 128.386 154.38 117.195 165.019"
          stroke="#9C5D37"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M293.578 118.047C301.9 106.346 332.917 114.428 332.917 114.428C309.979 92.0393 294.35 102.317 294.35 102.317C299.246 82.1237 282.46 65.1392 282.46 65.1392C286.463 76.9849 281.181 102.1 281.181 102.1C266.01 75.3685 193.002 51.1705 181.69 48.6132C170.354 46.08 152.675 19.976 152.675 19.976C142.689 42.244 168.979 53.3659 168.979 53.3659C121.078 55.3442 94.4027 81.9548 94.4027 81.9548C81.1131 83.3782 73.2502 76.7919 73.2502 76.7919C60.6359 121.907 84.2244 125.405 84.2244 125.405C81.4507 127.769 79.6418 130.882 78.4358 134.187C65.6768 151.919 47.081 186.612 66.328 219.712L94.668 127.552C114.18 110.471 141.025 104.368 141.025 104.368C111.6 153.922 156.847 185.478 156.847 185.478C150.769 168.542 169.172 141.4 169.172 141.4C201.685 175.49 268.712 144.899 268.712 144.899C233.281 176.696 280.265 259.447 280.265 259.447C278.118 234.574 317.505 204.754 317.505 204.754C339.694 215.49 340.659 237.589 339.043 250.545C365.815 112.691 293.554 118.023 293.554 118.023L293.578 118.047Z"
          fill="#FFA96E"
        />
        <path
          d="M86.0333 124.633C86.0333 124.633 41.7264 166.37 64.1572 223.114"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M339.067 250.569C338.44 253.85 337.764 257.204 336.993 260.653C336.993 260.653 338.295 256.6 339.067 250.569Z"
          fill="#FFA96E"
        />
        <path
          d="M70.9829 108.421C70.9829 108.421 73.7807 130.616 95.0779 126.225"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M72.4543 216.648C72.4543 216.648 42.9566 237.493 51.4707 263.259C60.3706 290.207 113.843 319.906 161.164 314.502"
          stroke="#9C5D37"
          strokeMiterlimit="10"
        />
        <path
          d="M164.71 224.513C164.71 224.513 168.087 219.519 173.706 219.978C178.916 220.388 183.04 225.213 183.04 225.213"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M158.969 235.515C158.969 235.515 168.496 249.266 188.274 237.71"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M143.823 224.224C142.279 227.143 137.045 220.894 123.297 213.705C109.549 206.491 97.2486 203.041 98.7681 200.122C100.312 197.203 114.18 194.38 127.928 201.594C141.676 208.807 145.342 221.305 143.798 224.224H143.823Z"
          fill="#9C5D37"
        />
        <path
          d="M198.959 223.379C199.297 226.66 206.484 222.824 221.92 221.256C237.357 219.688 250.067 221.063 249.73 217.782C249.392 214.501 237.55 206.709 222.113 208.277C206.677 209.845 198.621 220.074 198.959 223.355V223.379Z"
          fill="#9C5D37"
        />
        <path
          d="M130.171 235.659C127.759 244.417 116.665 249.001 105.377 245.889C94.1133 242.8 86.9258 233.198 89.3377 224.441C91.7496 215.683 102.844 211.099 114.132 214.212C125.396 217.3 132.583 226.902 130.171 235.659Z"
          fill="#FFA96E"
        />
        <path
          d="M194.979 232.426C194.979 232.426 223.705 208.397 264.466 223.452C264.466 223.452 222.258 256.045 194.979 232.426Z"
          fill="#FFCC89"
        />
        <path
          d="M256.531 242.511C255.904 251.558 245.943 258.265 234.269 257.469C222.596 256.673 213.672 248.687 214.299 239.64C214.926 230.593 224.887 223.886 236.561 224.682C248.21 225.478 257.158 233.464 256.531 242.511Z"
          fill="#FFA96E"
        />
        <path
          d="M194.979 232.426C194.979 232.426 223.705 208.397 264.466 223.452"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M172.331 215.056L171.945 219.929"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M223.271 170.013C223.271 170.013 240.323 170.568 247.221 183.837"
          stroke="#9C5D37"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M191.796 234.212C191.796 234.212 187.502 236.48 186.972 245.768"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M160.875 230.472C161.236 234.188 160.223 236.069 154.652 240.605"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M288.851 186.853C288.851 186.853 264.611 217.927 277.852 244.224"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M266.709 203.982C266.709 203.982 272.643 178.699 286.294 170.592"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M267.94 131.605C267.94 131.605 198.645 165.96 169.196 141.425C169.196 141.425 154.001 159.374 155.086 174.766"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M147.85 169.603C147.85 169.603 134.006 150.906 155.761 127.359C177.517 103.813 219.846 96.2613 245.991 104.44"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M79.6416 93.0285C79.6416 93.0285 93.0277 98.0707 100.456 100.966C107.885 103.837 178.892 44.3429 257.086 91.8705"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M137.817 129.265C137.817 129.265 179.109 79.5423 225.345 89.3614"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M281.253 105.453C281.253 105.453 287.621 80.6038 282.049 65.9354"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M293.723 118.071C293.723 118.071 304.263 105.694 332.048 114.356"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M76.6992 154.573C76.6992 154.573 82.8014 111.678 161.526 99.4459"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M178 146.612C178 146.612 166.302 143.885 163.311 127.046"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M268.736 144.947C268.736 144.947 235.186 186.684 280.289 259.495C280.289 259.495 289.864 212.981 329.13 194.211"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M168.062 9.21598C165.337 36.8399 307.133 77.8535 322.762 52.3285C338.392 26.8036 170.788 -18.4079 168.062 9.21598ZM177.445 12.6177C174.164 -0.193018 320.254 35.5612 314.2 47.6723C308.146 59.7833 180.725 25.4284 177.445 12.6177Z"
          fill="#FFEEAB"
        />
        <path
          d="M313.284 48.6855C317.794 48.2995 321.026 47.431 323.293 46.2247C314.779 22.7987 196.282 -9.52966 172.283 3.64295C173.031 6.80341 174.912 9.72261 177.421 12.3282C176.576 0.410111 320.206 35.6577 314.2 47.6723C314.007 48.0583 313.694 48.396 313.284 48.6855Z"
          fill="#FFDF36"
        />
        <path
          d="M229.301 36.1885C229.301 36.1885 172.789 18.7698 173.489 7.06881C174.212 -4.63213 221.1 3.90835 221.1 3.90835"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M268.229 45.3803C268.229 45.3803 324.716 55.0065 322.738 45.0908C321.026 36.4056 286.125 21.8578 286.125 21.8578"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M189.914 333.923C189.914 333.923 175.973 372.645 177.058 388.544C177.058 388.544 171.221 406.59 169.75 417.229C169.75 417.229 152.77 429.654 153.614 431.897C154.459 434.165 176.166 433.61 187.261 434.358C191.674 434.648 190.203 416.891 190.203 416.891C190.203 416.891 200.888 405.793 200.767 399.714L220.352 366.396"
          fill="#FFCC89"
        />
        <path
          d="M218.809 366.71C218.809 366.71 206.436 396.167 200.792 399.69C200.792 399.69 198.139 412.862 190.228 416.867"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M170.715 425.022C170.715 425.022 166.856 431.053 166.687 433.924"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M176.238 426.011C176.238 426.011 173.513 432.959 173.609 434.31"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M182.461 426.662C182.461 426.662 180.748 433.49 180.869 434.503"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M188.587 337.976C188.587 337.976 175.9 372.09 177.058 388.519"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M132.534 317.132C132.534 317.132 102.434 290.183 83.3314 285.792C83.3314 285.792 84.9232 271.414 74.6726 270.159C74.6726 270.159 74.8173 250.207 68.1363 253.681C61.4553 257.155 66.2309 265.913 66.2309 265.913C66.2309 265.913 42.0876 250.955 42.3047 258.072C42.5218 265.189 54.1472 272.403 54.1472 272.403C54.1472 272.403 39.3381 264.345 40.3511 268.929C41.3641 273.512 52.2177 275.467 52.2177 275.467C52.2177 275.467 36.7814 274.526 40.9782 279.954C45.1749 285.382 54.9431 284.031 54.9431 284.031C54.9431 284.031 41.8465 287.119 45.5367 289.918C49.251 292.716 50.7223 292.909 50.7223 292.909C50.7223 292.909 58.8987 298.265 68.0157 296.89C68.0157 296.89 96.4763 348.76 122.236 360.654"
          fill="#FFCC89"
        />
        <path
          d="M127.566 321.836C127.566 321.836 118.497 343.236 116.495 361.33"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M54.5089 287.795C54.5089 287.795 51.3011 279.857 55.8355 270.883"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M66.2309 281.643C66.2309 281.643 61.2624 272.909 65.8209 267.336"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
        <path
          d="M134.85 319.351C122.429 303.766 83.3556 285.768 83.3556 285.768C83.3556 285.768 84.2239 280.967 81.6673 275.587C79.1106 270.183 74.9863 269.918 74.9863 269.918C74.9863 269.918 75.8063 255.37 69.2218 253.512C62.6614 251.655 66.014 267.385 66.014 267.385C58.9471 261.932 53.2791 259.737 44.2585 255.539C44.2585 255.539 42.8596 254.767 42.329 258.024C41.7984 261.281 44.5962 264.586 46.4533 265.913"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeLinejoin="round"
        />
        <path
          d="M92.5449 317.325C92.5449 317.325 94.2815 308.567 100.456 304.176"
          stroke="#9C5D37"
          strokeWidth="0.75"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_195_49739">
          <rect width="345" height="447" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
