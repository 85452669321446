import { FC } from "react";
import "../../styles/test.css"
interface SettingIconProps {}
export const LargeBear: FC<SettingIconProps> = ({}) => {
  return (
    <>
      <svg
        width="1024"
        height="898"
        viewBox="0 0 1024 898"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1081.93 203.079C1080.25 197.558 1076.59 192.607 1071.57 189.725C1062.88 184.737 1050.92 186.374 1043.88 193.496C1044.78 182.191 1043.37 170.044 1036.33 161.144C1029.3 152.244 1015.34 148.229 1006.16 154.911C1004.34 139.582 1002.39 123.813 994.917 110.299C987.439 96.7855 973.009 85.911 957.622 87.3615C944.635 88.5875 933.671 98.311 927.524 109.803C921.368 121.295 919.222 134.435 917.329 147.331C905.063 130.991 876.136 135.745 865.772 153.348C856.823 168.556 860.122 188.386 870.233 203.079H1081.93Z"
          fill="white"
        />
        <g opacity="0.17">
          <path
            d="M1040.25 203.079C1039.2 199.635 1036.92 196.547 1033.79 194.75C1028.37 191.643 1020.91 192.663 1016.53 197.108C1017.09 190.061 1016.21 182.49 1011.82 176.941C1007.44 171.391 998.73 168.883 993.014 173.057C991.88 163.502 990.662 153.666 986.005 145.244C981.348 136.821 972.352 130.036 962.757 130.944C954.661 131.711 947.83 137.776 943.988 144.935C940.155 152.094 938.815 160.292 937.634 168.331C929.988 158.149 911.95 161.106 905.493 172.084C899.909 181.564 901.97 193.927 908.276 203.088H1040.26L1040.25 203.079Z"
            fill="#F7ACC0"
          />
        </g>
        <ellipse
          cx="314.626"
          cy="2.6111"
          rx="2.34264"
          ry="2.33962"
          fill="#FFC96A"
        />
        <path
          d="M314.625 13.7476V197.389"
          stroke="#FFC96A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <ellipse
          cx="314.626"
          cy="202.948"
          rx="2.34264"
          ry="2.33962"
          fill="#FFC96A"
        />
        <path
          d="M280.319 183.426C280.319 186.701 277.817 189.359 274.743 189.359C271.67 189.359 269.168 186.701 269.168 183.426C269.168 180.15 271.67 177.493 274.743 177.493C277.817 177.493 280.319 180.15 280.319 183.426Z"
          fill="#F7ACC0"
        />
        <path
          d="M268.325 198.138C271.405 198.138 273.901 195.481 273.901 192.204C273.901 188.927 271.405 186.271 268.325 186.271C265.246 186.271 262.75 188.927 262.75 192.204C262.75 195.481 265.246 198.138 268.325 198.138Z"
          fill="#F7ACC0"
        />
        <path
          d="M275.251 212.466C275.251 215.741 272.749 218.399 269.675 218.399C266.602 218.399 264.1 215.741 264.1 212.466C264.1 209.19 266.602 206.532 269.675 206.532C272.749 206.532 275.251 209.19 275.251 212.466Z"
          fill="#F7ACC0"
        />
        <path
          d="M274.407 227.86C277.487 227.86 279.983 225.204 279.983 221.927C279.983 218.65 277.487 215.994 274.407 215.994C271.328 215.994 268.832 218.65 268.832 221.927C268.832 225.204 271.328 227.86 274.407 227.86Z"
          fill="#F7ACC0"
        />
        <path
          d="M284.846 230.958C284.846 234.233 282.344 236.891 279.271 236.891C276.197 236.891 273.695 234.233 273.695 230.958C273.695 227.682 276.197 225.024 279.271 225.024C282.344 225.024 284.846 227.682 284.846 230.958Z"
          fill="#F7ACC0"
        />
        <path
          d="M291.274 238.725C291.274 242.001 288.772 244.659 285.699 244.659C282.625 244.659 280.123 242.001 280.123 238.725C280.123 235.45 282.625 232.792 285.699 232.792C288.772 232.792 291.274 235.45 291.274 238.725Z"
          fill="#F7ACC0"
        />
        <path
          d="M292.792 251.406C295.872 251.406 298.368 248.75 298.368 245.473C298.368 242.196 295.872 239.54 292.792 239.54C289.713 239.54 287.217 242.196 287.217 245.473C287.217 248.75 289.713 251.406 292.792 251.406Z"
          fill="#F7ACC0"
        />
        <path
          d="M306.145 250.545C306.145 253.821 303.643 256.478 300.57 256.478C297.496 256.478 294.994 253.821 294.994 250.545C294.994 247.27 297.496 244.612 300.57 244.612C303.643 244.612 306.145 247.27 306.145 250.545Z"
          fill="#F7ACC0"
        />
        <path
          d="M309.021 261.541C312.1 261.541 314.596 258.885 314.596 255.608C314.596 252.331 312.1 249.675 309.021 249.675C305.942 249.675 303.445 252.331 303.445 255.608C303.445 258.885 305.942 261.541 309.021 261.541Z"
          fill="#F7ACC0"
        />
        <path
          d="M328.128 259.848C331.207 259.848 333.704 257.191 333.704 253.914C333.704 250.637 331.207 247.981 328.128 247.981C325.049 247.981 322.553 250.637 322.553 253.914C322.553 257.191 325.049 259.848 328.128 259.848Z"
          fill="#F7ACC0"
        />
        <path
          d="M341.819 249.862C341.819 253.137 339.317 255.795 336.243 255.795C333.17 255.795 330.668 253.137 330.668 249.862C330.668 246.587 333.17 243.929 336.243 243.929C339.317 243.929 341.819 246.587 341.819 249.862Z"
          fill="#F7ACC0"
        />
        <path
          d="M342.671 249.722C345.75 249.722 348.247 247.065 348.247 243.788C348.247 240.511 345.75 237.855 342.671 237.855C339.592 237.855 337.096 240.511 337.096 243.788C337.096 247.065 339.592 249.722 342.671 249.722Z"
          fill="#F7ACC0"
        />
        <path
          d="M348.077 242.291C351.157 242.291 353.653 239.634 353.653 236.358C353.653 233.081 351.157 230.424 348.077 230.424C344.998 230.424 342.502 233.081 342.502 236.358C342.502 239.634 344.998 242.291 348.077 242.291Z"
          fill="#F7ACC0"
        />
        <path
          d="M352.81 233.513C355.889 233.513 358.385 230.856 358.385 227.579C358.385 224.302 355.889 221.646 352.81 221.646C349.731 221.646 347.234 224.302 347.234 227.579C347.234 230.856 349.731 233.513 352.81 233.513Z"
          fill="#F7ACC0"
        />
        <path
          d="M362.78 219.475C362.78 222.75 360.278 225.408 357.204 225.408C354.131 225.408 351.629 222.75 351.629 219.475C351.629 216.199 354.131 213.542 357.204 213.542C360.278 213.542 362.78 216.199 362.78 219.475Z"
          fill="#F7ACC0"
        />
        <path
          d="M365.151 210.023C365.151 213.299 362.649 215.956 359.575 215.956C356.502 215.956 354 213.299 354 210.023C354 206.748 356.502 204.09 359.575 204.09C362.649 204.09 365.151 206.748 365.151 210.023Z"
          fill="#F7ACC0"
        />
        <path
          d="M365.151 190.773C365.151 194.048 362.649 196.706 359.575 196.706C356.502 196.706 354 194.048 354 190.773C354 187.497 356.502 184.839 359.575 184.839C362.649 184.839 365.151 187.497 365.151 190.773Z"
          fill="#F7ACC0"
        />
        <path
          d="M360.756 182.668C360.756 185.944 358.254 188.601 355.181 188.601C352.107 188.601 349.605 185.944 349.605 182.668C349.605 179.393 352.107 176.735 355.181 176.735C358.254 176.735 360.756 179.393 360.756 182.668Z"
          fill="#F7ACC0"
        />
        <path
          d="M347.066 183.538C350.145 183.538 352.641 180.882 352.641 177.605C352.641 174.328 350.145 171.672 347.066 171.672C343.986 171.672 341.49 174.328 341.49 177.605C341.49 180.882 343.986 183.538 347.066 183.538Z"
          fill="#F7ACC0"
        />
        <path
          d="M338.267 185.223C341.346 185.223 343.842 182.567 343.842 179.29C343.842 176.013 341.346 173.356 338.267 173.356C335.188 173.356 332.691 176.013 332.691 179.29C332.691 182.567 335.188 185.223 338.267 185.223Z"
          fill="#F7ACC0"
        />
        <path
          d="M335.063 184.353C335.063 187.628 332.561 190.286 329.488 190.286C326.414 190.286 323.912 187.628 323.912 184.353C323.912 181.077 326.414 178.419 329.488 178.419C332.561 178.419 335.063 181.077 335.063 184.353Z"
          fill="#F7ACC0"
        />
        <path
          d="M327.286 190.773C327.286 194.048 324.784 196.706 321.71 196.706C318.637 196.706 316.135 194.048 316.135 190.773C316.135 187.497 318.637 184.839 321.71 184.839C324.784 184.839 327.286 187.497 327.286 190.773Z"
          fill="#F7ACC0"
        />
        <path
          d="M320.856 196.846C320.856 200.122 318.354 202.78 315.281 202.78C312.207 202.78 309.705 200.122 309.705 196.846C309.705 193.571 312.207 190.913 315.281 190.913C318.354 190.913 320.856 193.571 320.856 196.846Z"
          fill="#F7ACC0"
        />
        <path
          d="M314.09 190.773C314.09 194.048 311.588 196.706 308.515 196.706C305.441 196.706 302.939 194.048 302.939 190.773C302.939 187.497 305.441 184.839 308.515 184.839C311.588 184.839 314.09 187.497 314.09 190.773Z"
          fill="#F7ACC0"
        />
        <path
          d="M299.052 190.969C302.131 190.969 304.628 188.313 304.628 185.036C304.628 181.759 302.131 179.103 299.052 179.103C295.973 179.103 293.477 181.759 293.477 185.036C293.477 188.313 295.973 190.969 299.052 190.969Z"
          fill="#F7ACC0"
        />
        <path
          d="M290.599 186.917C293.678 186.917 296.174 184.26 296.174 180.984C296.174 177.707 293.678 175.05 290.599 175.05C287.52 175.05 285.023 177.707 285.023 180.984C285.023 184.26 287.52 186.917 290.599 186.917Z"
          fill="#F7ACC0"
        />
        <path
          d="M287.385 181.994C287.385 185.27 284.883 187.928 281.81 187.928C278.736 187.928 276.234 185.27 276.234 181.994C276.234 178.719 278.736 176.061 281.81 176.061C284.883 176.061 287.385 178.719 287.385 181.994Z"
          fill="#F7ACC0"
        />
        <path
          d="M366.501 200.899C366.501 204.174 363.999 206.832 360.925 206.832C357.852 206.832 355.35 204.174 355.35 200.899C355.35 197.623 357.852 194.965 360.925 194.965C363.999 194.965 366.501 197.623 366.501 200.899Z"
          fill="#F7ACC0"
        />
        <path
          d="M324.577 256.619C324.577 259.894 322.075 262.552 319.001 262.552C315.928 262.552 313.426 259.894 313.426 256.619C313.426 253.343 315.928 250.686 319.001 250.686C322.075 250.686 324.577 253.343 324.577 256.619Z"
          fill="#F7ACC0"
        />
        <path
          d="M273.901 202.003C273.901 205.278 271.399 207.936 268.325 207.936C265.252 207.936 262.75 205.278 262.75 202.003C262.75 198.727 265.252 196.069 268.325 196.069C271.399 196.069 273.901 198.727 273.901 202.003Z"
          fill="#F7ACC0"
        />
        <path
          d="M349.84 178.064C339.72 175.266 325.627 183.716 314.823 197.904C302.847 184.699 288.088 177.474 278.24 181.115C265.805 185.719 265.927 205.877 278.521 226.138C285.793 237.846 295.641 246.998 305.058 251.771C306.595 253.297 314.963 255.674 317.287 255.365C319.63 255.477 327.763 252.398 329.159 250.751C338.136 245.192 347.16 235.235 353.41 222.957C364.224 201.694 362.631 181.601 349.85 178.073L349.84 178.064Z"
          fill="#F4627D"
        />
        <path
          opacity="0.28"
          d="M342.925 186.936C334.847 184.708 323.603 191.446 314.991 202.761C305.433 192.223 293.664 186.458 285.802 189.369C275.879 193.047 275.982 209.125 286.027 225.287C291.827 234.626 299.68 241.926 307.195 245.735C308.423 246.951 315.094 248.851 316.95 248.599C318.824 248.692 325.308 246.231 326.423 244.911C333.582 240.475 340.788 232.53 345.774 222.732C354.404 205.774 353.129 189.734 342.934 186.926L342.925 186.936Z"
          fill="white"
        />
        <path
          d="M323.462 215.891C323.246 218.614 320.96 220.767 318.177 220.767C315.253 220.767 312.873 218.399 312.873 215.47"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M301.9 220.954C301.9 222.517 299.951 223.789 297.543 223.789C295.135 223.789 293.186 222.517 293.186 220.954C293.186 219.391 295.135 218.118 297.543 218.118C299.951 218.118 301.9 219.391 301.9 220.954Z"
          fill="#F4627D"
        />
        <path
          d="M340.638 220.954C340.638 222.517 338.689 223.789 336.281 223.789C333.873 223.789 331.924 222.517 331.924 220.954C331.924 219.391 333.873 218.118 336.281 218.118C338.689 218.118 340.638 219.391 340.638 220.954Z"
          fill="#F4627D"
        />
        <mask
          id="mask0_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="296"
          y="202"
          width="11"
          height="15"
        >
          <path
            d="M301.843 216.424C304.69 216.424 306.997 213.37 306.997 209.602C306.997 205.834 304.69 202.78 301.843 202.78C298.997 202.78 296.689 205.834 296.689 209.602C296.689 213.37 298.997 216.424 301.843 216.424Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_201_27617)">
          <path
            d="M301.843 216.424C304.69 216.424 306.997 213.37 306.997 209.602C306.997 205.834 304.69 202.78 301.843 202.78C298.997 202.78 296.689 205.834 296.689 209.602C296.689 213.37 298.997 216.424 301.843 216.424Z"
            fill="white"
          />
          <path
            d="M307.541 209.836C307.541 213.926 306.239 217.239 304.627 217.239C303.015 217.239 301.713 213.926 301.713 209.836C301.713 205.747 303.015 202.434 304.627 202.434C306.239 202.434 307.541 205.747 307.541 209.836Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M306.97 209.274C306.97 209.274 305.995 203.716 301.703 203.266C301.703 203.266 297.861 203.781 296.699 209.611C296.699 209.611 296.287 202.817 302.237 202.518C302.237 202.518 306.379 202.443 306.979 209.284L306.97 209.274Z"
          fill="#6B3E3D"
        />
        <mask
          id="mask1_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="326"
          y="202"
          width="12"
          height="15"
        >
          <path
            d="M331.951 202.78C334.799 202.78 337.104 205.831 337.104 209.602C337.104 213.374 334.799 216.424 331.951 216.424C329.102 216.424 326.797 213.374 326.797 209.602C326.797 205.831 329.102 202.78 331.951 202.78Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_201_27617)">
          <path
            d="M331.951 202.78C334.799 202.78 337.104 205.831 337.104 209.602C337.104 213.374 334.799 216.424 331.951 216.424C329.102 216.424 326.797 213.374 326.797 209.602C326.797 205.831 329.102 202.78 331.951 202.78Z"
            fill="white"
          />
          <path
            d="M334.735 217.239C336.344 217.239 337.649 213.924 337.649 209.836C337.649 205.748 336.344 202.434 334.735 202.434C333.125 202.434 331.82 205.748 331.82 209.836C331.82 213.924 333.125 217.239 334.735 217.239Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M337.077 209.274C337.077 209.274 336.102 203.716 331.811 203.266C331.811 203.266 327.969 203.781 326.807 209.611C326.807 209.611 326.395 202.817 332.345 202.518C332.345 202.518 336.487 202.443 337.086 209.284L337.077 209.274Z"
          fill="#6B3E3D"
        />
        <ellipse
          cx="881.6"
          cy="2.6111"
          rx="2.34264"
          ry="2.33962"
          fill="#FFC96A"
        />
        <path
          d="M881.6 13.7476V197.389"
          stroke="#FFC96A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <ellipse
          cx="881.6"
          cy="202.948"
          rx="2.34264"
          ry="2.33962"
          fill="#FFC96A"
        />
        <path
          d="M915.896 183.426C915.896 186.701 918.398 189.359 921.472 189.359C924.546 189.359 927.047 186.701 927.047 183.426C927.047 180.15 924.546 177.493 921.472 177.493C918.398 177.493 915.896 180.15 915.896 183.426Z"
          fill="#F7ACC0"
        />
        <path
          d="M922.324 192.204C922.324 195.48 924.826 198.138 927.9 198.138C930.973 198.138 933.475 195.48 933.475 192.204C933.475 188.929 930.973 186.271 927.9 186.271C924.826 186.271 922.324 188.929 922.324 192.204Z"
          fill="#F7ACC0"
        />
        <path
          d="M920.975 212.466C920.975 215.741 923.476 218.399 926.55 218.399C929.624 218.399 932.126 215.741 932.126 212.466C932.126 209.19 929.624 206.532 926.55 206.532C923.476 206.532 920.975 209.19 920.975 212.466Z"
          fill="#F7ACC0"
        />
        <path
          d="M916.234 221.927C916.234 225.202 918.736 227.86 921.81 227.86C924.883 227.86 927.385 225.202 927.385 221.927C927.385 218.651 924.883 215.994 921.81 215.994C918.736 215.994 916.234 218.651 916.234 221.927Z"
          fill="#F7ACC0"
        />
        <path
          d="M911.371 230.958C911.371 234.233 913.873 236.891 916.947 236.891C920.02 236.891 922.522 234.233 922.522 230.958C922.522 227.682 920.02 225.024 916.947 225.024C913.873 225.024 911.371 227.682 911.371 230.958Z"
          fill="#F7ACC0"
        />
        <path
          d="M904.951 238.725C904.951 242.001 907.453 244.659 910.527 244.659C913.6 244.659 916.102 242.001 916.102 238.725C916.102 235.45 913.6 232.792 910.527 232.792C907.453 232.792 904.951 235.45 904.951 238.725Z"
          fill="#F7ACC0"
        />
        <path
          d="M903.423 251.406C906.502 251.406 908.999 248.75 908.999 245.473C908.999 242.196 906.502 239.54 903.423 239.54C900.344 239.54 897.848 242.196 897.848 245.473C897.848 248.75 900.344 251.406 903.423 251.406Z"
          fill="#F7ACC0"
        />
        <path
          d="M890.07 250.545C890.07 253.821 892.572 256.478 895.646 256.478C898.719 256.478 901.221 253.821 901.221 250.545C901.221 247.27 898.719 244.612 895.646 244.612C892.572 244.612 890.07 247.27 890.07 250.545Z"
          fill="#F7ACC0"
        />
        <path
          d="M881.619 255.608C881.619 258.884 884.121 261.541 887.195 261.541C890.268 261.541 892.77 258.884 892.77 255.608C892.77 252.333 890.268 249.675 887.195 249.675C884.121 249.675 881.619 252.333 881.619 255.608Z"
          fill="#F7ACC0"
        />
        <path
          d="M868.097 259.848C871.176 259.848 873.672 257.191 873.672 253.914C873.672 250.637 871.176 247.981 868.097 247.981C865.018 247.981 862.521 250.637 862.521 253.914C862.521 257.191 865.018 259.848 868.097 259.848Z"
          fill="#F7ACC0"
        />
        <path
          d="M854.406 249.862C854.406 253.137 856.908 255.795 859.982 255.795C863.055 255.795 865.557 253.137 865.557 249.862C865.557 246.587 863.055 243.929 859.982 243.929C856.908 243.929 854.406 246.587 854.406 249.862Z"
          fill="#F7ACC0"
        />
        <path
          d="M847.979 243.788C847.979 247.064 850.48 249.722 853.554 249.722C856.628 249.722 859.129 247.064 859.129 243.788C859.129 240.513 856.628 237.855 853.554 237.855C850.48 237.855 847.979 240.513 847.979 243.788Z"
          fill="#F7ACC0"
        />
        <path
          d="M848.148 242.291C851.227 242.291 853.723 239.634 853.723 236.358C853.723 233.081 851.227 230.424 848.148 230.424C845.069 230.424 842.572 233.081 842.572 236.358C842.572 239.634 845.069 242.291 848.148 242.291Z"
          fill="#F7ACC0"
        />
        <path
          d="M837.83 227.579C837.83 230.855 840.332 233.513 843.406 233.513C846.479 233.513 848.981 230.855 848.981 227.579C848.981 224.304 846.479 221.646 843.406 221.646C840.332 221.646 837.83 224.304 837.83 227.579Z"
          fill="#F7ACC0"
        />
        <path
          d="M833.436 219.475C833.436 222.75 835.937 225.408 839.011 225.408C842.085 225.408 844.587 222.75 844.587 219.475C844.587 216.199 842.085 213.542 839.011 213.542C835.937 213.542 833.436 216.199 833.436 219.475Z"
          fill="#F7ACC0"
        />
        <path
          d="M831.074 210.023C831.074 213.299 833.576 215.956 836.65 215.956C839.723 215.956 842.225 213.299 842.225 210.023C842.225 206.748 839.723 204.09 836.65 204.09C833.576 204.09 831.074 206.748 831.074 210.023Z"
          fill="#F7ACC0"
        />
        <path
          d="M831.074 190.773C831.074 194.048 833.576 196.706 836.65 196.706C839.723 196.706 842.225 194.048 842.225 190.773C842.225 187.497 839.723 184.839 836.65 184.839C833.576 184.839 831.074 187.497 831.074 190.773Z"
          fill="#F7ACC0"
        />
        <path
          d="M841.044 188.601C844.123 188.601 846.62 185.945 846.62 182.668C846.62 179.391 844.123 176.735 841.044 176.735C837.965 176.735 835.469 179.391 835.469 182.668C835.469 185.945 837.965 188.601 841.044 188.601Z"
          fill="#F7ACC0"
        />
        <path
          d="M843.584 177.605C843.584 180.881 846.086 183.538 849.159 183.538C852.233 183.538 854.735 180.881 854.735 177.605C854.735 174.33 852.233 171.672 849.159 171.672C846.086 171.672 843.584 174.33 843.584 177.605Z"
          fill="#F7ACC0"
        />
        <path
          d="M852.373 179.29C852.373 182.565 854.875 185.223 857.949 185.223C861.022 185.223 863.524 182.565 863.524 179.29C863.524 176.014 861.022 173.356 857.949 173.356C854.875 173.356 852.373 176.014 852.373 179.29Z"
          fill="#F7ACC0"
        />
        <path
          d="M861.162 184.353C861.162 187.628 863.664 190.286 866.738 190.286C869.811 190.286 872.313 187.628 872.313 184.353C872.313 181.077 869.811 178.419 866.738 178.419C863.664 178.419 861.162 181.077 861.162 184.353Z"
          fill="#F7ACC0"
        />
        <path
          d="M868.939 190.773C868.939 194.048 871.441 196.706 874.515 196.706C877.588 196.706 880.09 194.048 880.09 190.773C880.09 187.497 877.588 184.839 874.515 184.839C871.441 184.839 868.939 187.497 868.939 190.773Z"
          fill="#F7ACC0"
        />
        <path
          d="M875.369 196.846C875.369 200.122 877.871 202.78 880.945 202.78C884.018 202.78 886.52 200.122 886.52 196.846C886.52 193.571 884.018 190.913 880.945 190.913C877.871 190.913 875.369 193.571 875.369 196.846Z"
          fill="#F7ACC0"
        />
        <path
          d="M882.125 190.773C882.125 194.048 884.627 196.706 887.7 196.706C890.774 196.706 893.276 194.048 893.276 190.773C893.276 187.497 890.774 184.839 887.7 184.839C884.627 184.839 882.125 187.497 882.125 190.773Z"
          fill="#F7ACC0"
        />
        <path
          d="M891.598 185.036C891.598 188.311 894.1 190.969 897.173 190.969C900.247 190.969 902.749 188.311 902.749 185.036C902.749 181.76 900.247 179.103 897.173 179.103C894.1 179.103 891.598 181.76 891.598 185.036Z"
          fill="#F7ACC0"
        />
        <path
          d="M905.626 186.917C908.706 186.917 911.202 184.26 911.202 180.984C911.202 177.707 908.706 175.05 905.626 175.05C902.547 175.05 900.051 177.707 900.051 180.984C900.051 184.26 902.547 186.917 905.626 186.917Z"
          fill="#F7ACC0"
        />
        <path
          d="M908.84 181.994C908.84 185.27 911.342 187.928 914.415 187.928C917.489 187.928 919.991 185.27 919.991 181.994C919.991 178.719 917.489 176.061 914.415 176.061C911.342 176.061 908.84 178.719 908.84 181.994Z"
          fill="#F7ACC0"
        />
        <path
          d="M829.725 200.899C829.725 204.174 832.226 206.832 835.3 206.832C838.374 206.832 840.876 204.174 840.876 200.899C840.876 197.623 838.374 194.965 835.3 194.965C832.226 194.965 829.725 197.623 829.725 200.899Z"
          fill="#F7ACC0"
        />
        <path
          d="M871.648 256.619C871.648 259.894 874.15 262.552 877.224 262.552C880.297 262.552 882.799 259.894 882.799 256.619C882.799 253.343 880.297 250.686 877.224 250.686C874.15 250.686 871.648 253.343 871.648 256.619Z"
          fill="#F7ACC0"
        />
        <path
          d="M922.324 202.003C922.324 205.278 924.826 207.936 927.9 207.936C930.973 207.936 933.475 205.278 933.475 202.003C933.475 198.727 930.973 196.069 927.9 196.069C924.826 196.069 922.324 198.727 922.324 202.003Z"
          fill="#F7ACC0"
        />
        <path
          d="M846.386 178.064C856.506 175.266 870.6 183.716 881.404 197.904C893.379 184.699 908.138 177.474 917.987 181.115C930.421 185.719 930.299 205.877 917.705 226.138C910.434 237.846 900.585 246.998 891.177 251.771C889.641 253.297 881.273 255.674 878.949 255.365C876.606 255.477 868.473 252.398 867.076 250.751C858.099 245.192 849.076 235.235 842.825 222.957C832.012 201.694 833.605 181.601 846.386 178.073V178.064Z"
          fill="#F4627D"
        />
        <path
          opacity="0.28"
          d="M853.291 186.936C861.369 184.708 872.613 191.446 881.225 202.761C890.783 192.223 902.552 186.458 910.414 189.369C920.338 193.047 920.244 209.125 910.189 225.287C904.389 234.626 896.536 241.926 889.021 245.735C887.794 246.951 881.122 248.851 879.267 248.599C877.392 248.692 870.908 246.231 869.793 244.911C862.634 240.475 855.428 232.53 850.443 222.732C841.812 205.774 843.087 189.734 853.282 186.926L853.291 186.936Z"
          fill="white"
        />
        <path
          d="M872.764 215.891C872.979 218.614 875.266 220.767 878.049 220.767C880.972 220.767 883.352 218.399 883.352 215.47"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M894.324 220.954C894.324 222.517 896.273 223.789 898.681 223.789C901.09 223.789 903.039 222.517 903.039 220.954C903.039 219.391 901.09 218.118 898.681 218.118C896.273 218.118 894.324 219.391 894.324 220.954Z"
          fill="#F4627D"
        />
        <path
          d="M855.588 220.954C855.588 222.517 857.537 223.789 859.945 223.789C862.353 223.789 864.302 222.517 864.302 220.954C864.302 219.391 862.353 218.118 859.945 218.118C857.537 218.118 855.588 219.391 855.588 220.954Z"
          fill="#F4627D"
        />
        <mask
          id="mask2_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="889"
          y="202"
          width="11"
          height="15"
        >
          <path
            d="M894.382 216.424C897.229 216.424 899.536 213.37 899.536 209.602C899.536 205.834 897.229 202.78 894.382 202.78C891.536 202.78 889.229 205.834 889.229 209.602C889.229 213.37 891.536 216.424 894.382 216.424Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_201_27617)">
          <path
            d="M894.382 216.424C897.229 216.424 899.536 213.37 899.536 209.602C899.536 205.834 897.229 202.78 894.382 202.78C891.536 202.78 889.229 205.834 889.229 209.602C889.229 213.37 891.536 216.424 894.382 216.424Z"
            fill="white"
          />
          <path
            d="M891.6 217.239C893.209 217.239 894.514 213.924 894.514 209.836C894.514 205.748 893.209 202.434 891.6 202.434C889.99 202.434 888.686 205.748 888.686 209.836C888.686 213.924 889.99 217.239 891.6 217.239Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M889.255 209.274C889.255 209.274 890.23 203.716 894.522 203.266C894.522 203.266 898.364 203.781 899.526 209.611C899.526 209.611 899.938 202.817 893.988 202.518C893.988 202.518 889.846 202.443 889.246 209.284L889.255 209.274Z"
          fill="#6B3E3D"
        />
        <mask
          id="mask3_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="859"
          y="202"
          width="11"
          height="15"
        >
          <path
            d="M864.273 216.424C867.119 216.424 869.427 213.37 869.427 209.602C869.427 205.834 867.119 202.78 864.273 202.78C861.427 202.78 859.119 205.834 859.119 209.602C859.119 213.37 861.427 216.424 864.273 216.424Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_201_27617)">
          <path
            d="M864.273 216.424C867.119 216.424 869.427 213.37 869.427 209.602C869.427 205.834 867.119 202.78 864.273 202.78C861.427 202.78 859.119 205.834 859.119 209.602C859.119 213.37 861.427 216.424 864.273 216.424Z"
            fill="white"
          />
          <path
            d="M858.576 209.836C858.576 213.926 859.879 217.239 861.49 217.239C863.102 217.239 864.405 213.926 864.405 209.836C864.405 205.747 863.102 202.434 861.49 202.434C859.879 202.434 858.576 205.747 858.576 209.836Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M859.146 209.274C859.146 209.274 860.121 203.716 864.412 203.266C864.412 203.266 868.254 203.781 869.416 209.611C869.416 209.611 869.829 202.817 863.878 202.518C863.878 202.518 859.736 202.443 859.137 209.284L859.146 209.274Z"
          fill="#6B3E3D"
        />
        <ellipse
          cx="178.958"
          cy="8.78786"
          rx="2.34264"
          ry="2.33962"
          fill="#FFC96A"
        />
        <path
          d="M178.957 20.0088V519.331"
          stroke="#FFC96A"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <ellipse
          cx="178.958"
          cy="524.936"
          rx="2.34264"
          ry="2.33962"
          fill="#FFC96A"
        />
        <path
          d="M186.492 475.065L199.142 513.94C200.201 517.197 203.237 519.406 206.667 519.406H247.597C255.262 519.406 258.448 529.204 252.245 533.705L219.129 557.738C216.356 559.75 215.194 563.316 216.253 566.572L228.903 605.448C231.274 612.728 222.925 618.783 216.721 614.282L183.606 590.249C180.832 588.237 177.074 588.237 174.301 590.249L141.185 614.282C134.982 618.783 126.633 612.728 129.004 605.448L141.654 566.572C142.713 563.316 141.551 559.75 138.777 557.738L105.662 533.705C99.4582 529.204 102.644 519.406 110.309 519.406H151.24C154.669 519.406 157.706 517.197 158.764 513.94L171.415 475.065C173.785 467.784 184.102 467.784 186.473 475.065H186.492Z"
          fill="#FFC96A"
        />
        <path
          opacity="0.28"
          d="M184.197 496.964L192.995 524.01C193.736 526.275 195.844 527.81 198.234 527.81H226.711C232.043 527.81 234.263 534.623 229.944 537.758L206.911 554.472C204.98 555.876 204.175 558.356 204.915 560.621L213.714 587.667C215.363 592.73 209.553 596.941 205.243 593.815L182.21 577.101C180.28 575.697 177.665 575.697 175.735 577.101L152.702 593.815C148.392 596.95 142.582 592.73 144.231 587.667L153.03 560.621C153.77 558.356 152.955 555.876 151.034 554.472L128.001 537.758C123.691 534.623 125.902 527.81 131.234 527.81H159.711C162.101 527.81 164.209 526.275 164.949 524.01L173.748 496.964C175.398 491.901 182.575 491.901 184.225 496.964H184.197Z"
          fill="white"
        />
        <path
          d="M185.921 544.234C185.706 546.919 183.457 549.034 180.711 549.034C177.825 549.034 175.482 546.695 175.482 543.812"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M164.658 549.222C164.658 550.766 162.737 552.02 160.366 552.02C157.995 552.02 156.074 550.766 156.074 549.222C156.074 547.677 157.995 546.423 160.366 546.423C162.737 546.423 164.658 547.677 164.658 549.222Z"
          fill="#F4627D"
        />
        <path
          d="M202.853 549.222C202.853 550.766 200.932 552.02 198.561 552.02C196.19 552.02 194.27 550.766 194.27 549.222C194.27 547.677 196.19 546.423 198.561 546.423C200.932 546.423 202.853 547.677 202.853 549.222Z"
          fill="#F4627D"
        />
        <mask
          id="mask4_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="159"
          y="531"
          width="11"
          height="14"
        >
          <path
            d="M164.612 544.767C167.417 544.767 169.691 541.755 169.691 538.038C169.691 534.322 167.417 531.31 164.612 531.31C161.807 531.31 159.533 534.322 159.533 538.038C159.533 541.755 161.807 544.767 164.612 544.767Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_201_27617)">
          <path
            d="M164.612 544.767C167.417 544.767 169.691 541.755 169.691 538.038C169.691 534.322 167.417 531.31 164.612 531.31C161.807 531.31 159.533 534.322 159.533 538.038C159.533 541.755 161.807 544.767 164.612 544.767Z"
            fill="white"
          />
          <path
            d="M167.358 545.563C168.941 545.563 170.225 542.294 170.225 538.263C170.225 534.232 168.941 530.963 167.358 530.963C165.774 530.963 164.49 534.232 164.49 538.263C164.49 542.294 165.774 545.563 167.358 545.563Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M169.663 537.711C169.663 537.711 168.698 532.227 164.472 531.787C164.472 531.787 160.676 532.292 159.533 538.038C159.533 538.038 159.13 531.338 164.987 531.047C164.987 531.047 169.063 530.973 169.663 537.711Z"
          fill="#6B3E3D"
        />
        <mask
          id="mask5_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="189"
          y="531"
          width="11"
          height="14"
        >
          <path
            d="M194.288 544.767C197.093 544.767 199.367 541.755 199.367 538.038C199.367 534.322 197.093 531.31 194.288 531.31C191.483 531.31 189.209 534.322 189.209 538.038C189.209 541.755 191.483 544.767 194.288 544.767Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_201_27617)">
          <path
            d="M194.288 544.767C197.093 544.767 199.367 541.755 199.367 538.038C199.367 534.322 197.093 531.31 194.288 531.31C191.483 531.31 189.209 534.322 189.209 538.038C189.209 541.755 191.483 544.767 194.288 544.767Z"
            fill="white"
          />
          <path
            d="M197.033 545.563C198.617 545.563 199.901 542.294 199.901 538.263C199.901 534.232 198.617 530.963 197.033 530.963C195.45 530.963 194.166 534.232 194.166 538.263C194.166 542.294 195.45 545.563 197.033 545.563Z"
            fill="#6B3E3D"
          />
        </g>
        <path
          d="M199.339 537.711C199.339 537.711 198.373 532.227 194.147 531.787C194.147 531.787 190.352 532.292 189.209 538.038C189.209 538.038 188.806 531.338 194.663 531.047C194.663 531.047 198.739 530.973 199.339 537.711Z"
          fill="#6B3E3D"
        />
        <path
          d="M577.02 243.864L574.996 241.917C575.446 241.449 620.209 195.359 671.719 182.491L672.403 185.214C621.68 197.876 577.47 243.396 577.02 243.854V243.864Z"
          fill="#5E8474"
        />
        <path
          d="M624.37 205.962L621.896 204.642C622.431 203.65 635.109 180.226 652.754 168.256L654.337 170.577C637.255 182.163 624.502 205.728 624.38 205.971L624.37 205.962Z"
          fill="#5E8474"
        />
        <path
          d="M613.846 220.121C608.102 220.121 604.588 219.906 604.457 219.897L604.644 217.098C605.085 217.127 649.248 219.869 682.682 205.344L683.807 207.918C659.162 218.624 628.877 220.121 613.846 220.121Z"
          fill="#5E8474"
        />
        <path
          d="M658.909 167.882C658.909 171.157 656.248 173.815 652.968 173.815C649.689 173.815 647.027 171.157 647.027 167.882C647.027 164.607 649.689 161.949 652.968 161.949C656.248 161.949 658.909 164.607 658.909 167.882Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M654.037 171.962C648.04 171.962 647.871 163.624 652.716 161.967C649.558 162.108 647.037 164.7 647.037 167.882C647.037 171.064 649.698 173.815 652.978 173.815C655.995 173.815 658.479 171.569 658.863 168.659C658.169 170.493 656.539 171.962 654.037 171.962Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M655.612 169.894C656.683 169.894 657.551 169.027 657.551 167.957C657.551 166.887 656.683 166.02 655.612 166.02C654.54 166.02 653.672 166.887 653.672 167.957C653.672 169.027 654.54 169.894 655.612 169.894Z"
          fill="white"
        />
        <path
          d="M676.647 184.756C676.647 188.031 673.986 190.689 670.707 190.689C667.427 190.689 664.766 188.031 664.766 184.756C664.766 181.48 667.427 178.822 670.707 178.822C673.986 178.822 676.647 181.48 676.647 184.756Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M671.765 188.836C665.768 188.836 665.6 180.497 670.444 178.841C667.286 178.981 664.766 181.573 664.766 184.755C664.766 187.937 667.427 190.689 670.707 190.689C673.724 190.689 676.207 188.443 676.591 185.532C675.898 187.366 674.267 188.836 671.765 188.836Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M675.28 184.83C675.28 185.897 674.408 186.767 673.34 186.767C672.272 186.767 671.4 185.897 671.4 184.83C671.4 183.763 672.272 182.893 673.34 182.893C674.408 182.893 675.28 183.763 675.28 184.83Z"
          fill="white"
        />
        <path
          d="M687.181 207.309C687.181 210.585 684.519 213.243 681.24 213.243C677.96 213.243 675.299 210.585 675.299 207.309C675.299 204.034 677.96 201.376 681.24 201.376C684.519 201.376 687.181 204.034 687.181 207.309Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M682.308 211.389C676.311 211.389 676.143 203.051 680.987 201.395C677.829 201.535 675.309 204.127 675.309 207.309C675.309 210.491 677.97 213.242 681.25 213.242C684.267 213.242 686.75 210.996 687.134 208.086C686.441 209.92 684.81 211.389 682.308 211.389Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M685.823 207.384C685.823 208.451 684.951 209.321 683.883 209.321C682.815 209.321 681.943 208.451 681.943 207.384C681.943 206.317 682.815 205.447 683.883 205.447C684.951 205.447 685.823 206.317 685.823 207.384Z"
          fill="white"
        />
        <path
          d="M956.329 616.724L954.68 615.742C954.905 615.367 977.572 577.784 1009.87 561.285L1010.74 562.988C978.949 579.234 956.554 616.35 956.329 616.715V616.724Z"
          fill="#5E8474"
        />
        <path
          d="M981.986 584.203L980.131 583.707C980.328 582.968 985.154 565.421 995.049 554.733L996.454 556.034C986.878 566.375 982.033 584.025 981.986 584.203Z"
          fill="#5E8474"
        />
        <path
          d="M977.179 595.247C973.356 596.136 970.985 596.529 970.901 596.538L970.592 594.648C970.892 594.601 1000.71 589.613 1020.71 574.798L1021.86 576.342C1007.11 587.264 987.187 592.935 977.179 595.247Z"
          fill="#5E8474"
        />
        <path
          d="M999.096 553.536C999.602 555.716 998.244 557.897 996.06 558.402C993.877 558.907 991.694 557.551 991.188 555.37C990.682 553.189 992.04 551.009 994.224 550.504C996.407 549.998 998.59 551.355 999.096 553.536Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M996.472 556.998C992.481 557.925 991.075 552.403 994.045 550.55C991.965 551.131 990.691 553.246 991.178 555.361C991.684 557.541 993.867 558.898 996.051 558.393C998.056 557.925 999.368 556.053 999.171 554.05C998.993 555.379 998.131 556.605 996.472 556.998Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M998.196 553.789C998.365 554.5 997.915 555.211 997.203 555.38C996.49 555.548 995.778 555.099 995.61 554.388C995.441 553.676 995.891 552.965 996.603 552.797C997.315 552.628 998.027 553.077 998.196 553.789Z"
          fill="white"
        />
        <path
          d="M1013.5 562.033C1014 564.214 1012.65 566.394 1010.46 566.9C1008.28 567.405 1006.1 566.048 1005.59 563.868C1005.08 561.687 1006.44 559.506 1008.63 559.001C1010.81 558.496 1012.99 559.853 1013.5 562.033Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M1010.88 565.496C1006.89 566.422 1005.49 560.901 1008.46 559.048C1006.38 559.628 1005.1 561.743 1005.59 563.858C1006.1 566.039 1008.28 567.396 1010.46 566.89C1012.47 566.422 1013.78 564.551 1013.58 562.548C1013.41 563.877 1012.54 565.103 1010.88 565.486V565.496Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M1012.61 562.286C1012.78 562.997 1012.33 563.709 1011.61 563.877C1010.9 564.046 1010.19 563.596 1010.02 562.885C1009.85 562.174 1010.3 561.463 1011.02 561.294C1011.73 561.126 1012.44 561.575 1012.61 562.286Z"
          fill="white"
        />
        <path
          d="M1024 575.416C1024.51 577.596 1023.15 579.777 1020.97 580.282C1018.78 580.787 1016.6 579.43 1016.09 577.25C1015.59 575.069 1016.95 572.889 1019.13 572.383C1021.31 571.878 1023.5 573.235 1024 575.416Z"
          fill="#F4627D"
        />
        <g mode="multiply" opacity="0.49">
          <path
            d="M1021.39 578.887C1017.4 579.814 1015.99 574.292 1018.96 572.439C1016.88 573.02 1015.61 575.135 1016.09 577.25C1016.6 579.43 1018.78 580.787 1020.97 580.282C1022.97 579.814 1024.28 577.942 1024.09 575.94C1023.91 577.268 1023.05 578.494 1021.39 578.887Z"
            fill="#F4627D"
          />
        </g>
        <path
          opacity="0.39"
          d="M1023.1 575.678C1023.27 576.389 1022.82 577.1 1022.11 577.269C1021.4 577.437 1020.68 576.988 1020.52 576.277C1020.35 575.566 1020.8 574.854 1021.51 574.686C1022.22 574.517 1022.93 574.967 1023.1 575.678Z"
          fill="white"
        />
        <path
          d="M881.02 687.343C918.286 656.919 945.686 615.386 959.123 569.23C960.135 565.758 954.71 564.27 953.698 567.733C940.513 613.018 913.601 653.522 877.046 683.366C874.245 685.649 878.246 689.608 881.02 687.334V687.343Z"
          fill="#94BDAE"
        />
        <mask
          id="mask6_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="949"
          y="536"
          width="30"
          height="46"
        >
          <path
            d="M954.044 581.527C969.027 577.063 979.691 561.163 978.107 545.628C977.873 543.298 977.348 540.892 975.868 539.077C973.488 536.138 969.046 535.464 965.466 536.7C961.887 537.944 959.057 540.743 956.808 543.784C949.696 553.386 947.297 566.31 950.502 577.821L954.044 581.527Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_201_27617)">
          <path
            d="M954.044 581.527C969.027 577.063 979.691 561.163 978.107 545.628C977.873 543.298 977.348 540.892 975.868 539.077C973.488 536.138 969.046 535.464 965.466 536.7C961.887 537.944 959.057 540.743 956.808 543.784C949.696 553.386 947.297 566.31 950.502 577.821L954.044 581.527Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M954.766 580.629C964.746 577.662 971.839 567.068 970.78 556.727C970.621 555.174 970.274 553.573 969.291 552.366C967.707 550.41 964.746 549.961 962.366 550.785C959.986 551.608 958.102 553.48 956.603 555.501C951.871 561.893 950.278 570.503 952.405 578.158L954.766 580.619V580.629Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M952.086 583.53C952.086 583.53 973.01 542.689 972.57 531.833"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M979.963 548.847C979.963 548.847 966.619 554.275 965.804 554.312C964.989 554.35 957.82 539.685 957.82 539.685"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M976.823 564.12L958.476 570.681C958.476 570.681 948.496 556.203 947.344 553.77"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M965.466 536.7C969.045 535.455 973.478 536.129 975.867 539.077C976.139 539.405 976.364 539.76 976.57 540.125C974.077 538.02 970.273 537.636 967.124 538.721C963.545 539.966 960.715 542.764 958.466 545.806C951.438 555.305 949.02 568.042 952.057 579.459L950.492 577.821C947.296 566.31 949.686 553.386 956.798 543.784C959.047 540.743 961.877 537.935 965.456 536.7H965.466Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask7_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="918"
          y="558"
          width="29"
          height="47"
        >
          <path
            d="M941.591 604.577C949.912 591.353 947.428 572.374 935.978 561.733C934.263 560.143 932.276 558.673 929.971 558.28C926.232 557.634 922.456 560.049 920.582 563.343C918.708 566.637 918.436 570.605 918.754 574.377C919.748 586.281 926.476 597.576 936.484 604.127L941.591 604.577Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_201_27617)">
          <path
            d="M941.591 604.577C949.912 591.353 947.428 572.374 935.978 561.733C934.263 560.143 932.276 558.673 929.971 558.28C926.232 557.634 922.456 560.049 920.582 563.343C918.708 566.637 918.436 570.605 918.754 574.377C919.748 586.281 926.476 597.576 936.484 604.127L941.591 604.577Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M941.543 603.416C947.081 594.619 945.432 581.976 937.804 574.901C936.661 573.843 935.34 572.87 933.803 572.599C931.32 572.168 928.799 573.778 927.553 575.968C926.307 578.158 926.119 580.797 926.335 583.314C927 591.241 931.479 598.756 938.132 603.117L941.534 603.416H941.543Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M941.439 607.375C941.439 607.375 930.213 562.894 922.717 555.014"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M939.509 562.932C939.509 562.932 933.063 575.8 932.463 576.361C931.872 576.923 916.805 570.624 916.805 570.624"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M947.23 576.482L937.766 593.496C937.766 593.496 920.712 589.182 918.229 588.106"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M920.59 563.343C922.464 560.049 926.241 557.634 929.98 558.28C930.401 558.355 930.804 558.467 931.207 558.608C927.946 558.673 924.826 560.882 923.186 563.774C921.312 567.068 921.04 571.036 921.349 574.807C922.333 586.58 928.93 597.745 938.75 604.324L936.492 604.127C926.484 597.576 919.756 586.281 918.763 574.377C918.444 570.605 918.726 566.637 920.6 563.343H920.59Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask8_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="900"
          y="590"
          width="29"
          height="47"
        >
          <path
            d="M923.337 636.667C931.658 623.443 929.174 604.464 917.724 593.824C916.009 592.233 914.022 590.764 911.717 590.371C907.978 589.725 904.202 592.139 902.328 595.433C900.454 598.728 900.182 602.696 900.501 606.467C901.494 618.371 908.222 629.667 918.23 636.218L923.337 636.667Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask8_201_27617)">
          <path
            d="M923.337 636.667C931.658 623.443 929.174 604.464 917.724 593.824C916.009 592.233 914.022 590.764 911.717 590.371C907.978 589.725 904.202 592.139 902.328 595.433C900.454 598.728 900.182 602.696 900.501 606.467C901.494 618.371 908.222 629.667 918.23 636.218L923.337 636.667Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M923.281 635.507C928.819 626.71 927.17 614.066 919.542 606.991C918.399 605.934 917.078 604.96 915.541 604.689C913.058 604.259 910.537 605.868 909.291 608.058C908.045 610.248 907.857 612.887 908.073 615.405C908.738 623.331 913.217 630.846 919.87 635.207L923.272 635.507H923.281Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M923.187 639.456C923.187 639.456 911.961 594.975 904.465 587.095"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M921.248 595.022C921.248 595.022 914.801 607.89 914.201 608.451C913.611 609.013 898.543 602.715 898.543 602.715"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M928.969 608.573L919.504 625.586C919.504 625.586 902.45 621.272 899.967 620.196"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M902.329 595.433C904.203 592.139 907.979 589.725 911.718 590.371C912.14 590.445 912.543 590.558 912.945 590.698C909.684 590.764 906.564 592.972 904.924 595.864C903.05 599.158 902.778 603.126 903.088 606.898C904.072 618.671 910.668 629.835 920.489 636.414L918.23 636.218C908.232 629.667 901.495 618.371 900.501 606.467C900.183 602.696 900.464 598.728 902.338 595.433H902.329Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask9_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="942"
          y="585"
          width="48"
          height="29"
        >
          <path
            d="M942.527 606.907C948.337 592.411 965.176 583.258 980.525 586.244C982.83 586.693 985.173 587.432 986.859 589.07C989.577 591.709 989.839 596.182 988.274 599.626C986.709 603.07 983.655 605.625 980.422 607.59C970.199 613.786 957.089 614.993 945.901 610.763L942.537 606.898L942.527 606.907Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask9_201_27617)">
          <path
            d="M942.527 606.907C948.337 592.411 965.176 583.258 980.525 586.244C982.83 586.693 985.173 587.432 986.859 589.07C989.577 591.709 989.839 596.182 988.274 599.626C986.709 603.07 983.655 605.625 980.422 607.59C970.199 613.786 957.089 614.993 945.901 610.763L942.537 606.898L942.527 606.907Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M943.492 606.271C947.362 596.622 958.569 590.53 968.783 592.514C970.311 592.813 971.876 593.31 973 594.395C974.809 596.154 974.987 599.13 973.946 601.423C972.906 603.716 970.873 605.419 968.718 606.73C961.915 610.857 953.181 611.661 945.741 608.845L943.502 606.271H943.492Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M940.344 608.667C940.344 608.667 982.97 591.578 993.756 593.001"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M977.471 584.101C977.471 584.101 970.846 596.875 970.733 597.689C970.621 598.503 984.592 606.963 984.592 606.963"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M961.962 585.832L953.754 603.482C953.754 603.482 967.276 614.722 969.599 616.097"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M988.264 599.626C989.829 596.182 989.557 591.709 986.849 589.07C986.54 588.77 986.212 588.518 985.865 588.274C987.74 590.951 987.777 594.769 986.4 597.792C984.835 601.236 981.78 603.791 978.547 605.756C968.436 611.886 955.514 613.121 944.41 609.06L945.9 610.763C957.089 614.993 970.189 613.786 980.421 607.59C983.663 605.625 986.709 603.07 988.274 599.626H988.264Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask10_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="923"
          y="619"
          width="49"
          height="27"
        >
          <path
            d="M923.197 636.686C931.059 623.181 949.041 616.574 963.79 621.759C966.002 622.536 968.223 623.612 969.647 625.474C971.952 628.478 971.568 632.942 969.516 636.124C967.464 639.306 964.072 641.393 960.586 642.862C949.566 647.513 936.428 646.802 925.971 641L923.197 636.686Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask10_201_27617)">
          <path
            d="M923.197 636.686C931.059 623.181 949.041 616.574 963.79 621.759C966.002 622.536 968.223 623.612 969.647 625.474C971.952 628.478 971.568 632.942 969.516 636.124C967.464 639.306 964.072 641.393 960.586 642.862C949.566 647.513 936.428 646.802 925.971 641L923.197 636.686Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M924.246 636.19C929.475 627.205 941.45 622.798 951.271 626.251C952.742 626.766 954.222 627.486 955.169 628.722C956.706 630.724 956.443 633.691 955.085 635.815C953.716 637.93 951.468 639.325 949.144 640.307C941.806 643.405 933.064 642.928 926.101 639.063L924.255 636.19H924.246Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M920.789 638.117C920.789 638.117 965.449 627.392 975.916 630.368"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M961.092 619.185C961.092 619.185 952.677 630.864 952.452 631.651C952.227 632.437 964.812 642.834 964.812 642.834"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M945.491 618.652L934.799 634.926C934.799 634.926 946.54 648.009 948.648 649.703"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M969.517 636.115C971.569 632.933 971.953 628.469 969.648 625.465C969.385 625.128 969.095 624.819 968.786 624.538C970.248 627.458 969.732 631.239 967.933 634.037C965.881 637.219 962.489 639.306 959.003 640.775C948.105 645.37 935.136 644.724 924.744 639.091L925.972 641C936.42 646.802 949.567 647.513 960.586 642.862C964.082 641.393 967.464 639.306 969.517 636.124V636.115Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask11_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="900"
          y="650"
          width="49"
          height="27"
        >
          <path
            d="M900.004 666.942C907.866 653.438 925.848 646.831 940.597 652.015C942.809 652.792 945.02 653.868 946.454 655.73C948.759 658.734 948.375 663.198 946.322 666.38C944.27 669.562 940.878 671.649 937.392 673.118C926.373 677.77 913.235 677.058 902.778 671.256L900.004 666.942Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask11_201_27617)">
          <path
            d="M900.004 666.942C907.866 653.438 925.848 646.831 940.597 652.015C942.809 652.792 945.02 653.868 946.454 655.73C948.759 658.734 948.375 663.198 946.322 666.38C944.27 669.562 940.878 671.649 937.392 673.118C926.373 677.77 913.235 677.058 902.778 671.256L900.004 666.942Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M901.043 666.446C906.272 657.462 918.247 653.054 928.068 656.507C929.539 657.022 931.019 657.743 931.966 658.978C933.503 660.981 933.24 663.947 931.881 666.072C930.523 668.196 928.264 669.581 925.941 670.564C918.603 673.661 909.861 673.184 902.898 669.319L901.052 666.446H901.043Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M897.586 668.374C897.586 668.374 942.246 657.649 952.713 660.625"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M937.899 649.441C937.899 649.441 929.484 661.121 929.259 661.907C929.034 662.693 941.619 673.09 941.619 673.09"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M922.295 648.908L911.604 665.183C911.604 665.183 923.345 678.266 925.453 679.96"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M946.314 666.371C948.366 663.189 948.75 658.725 946.445 655.721C946.182 655.384 945.892 655.075 945.583 654.794C947.044 657.714 946.529 661.495 944.73 664.293C942.678 667.475 939.286 669.562 935.8 671.031C924.902 675.626 911.933 674.981 901.541 669.347L902.769 671.256C913.217 677.058 926.364 677.77 937.383 673.118C940.879 671.649 944.261 669.562 946.314 666.38V666.371Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask12_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="949"
          y="590"
          width="28"
          height="37"
        >
          <path
            d="M976.027 626.485C968.793 623.612 960.003 617.024 955.018 611.044C952.507 608.03 950.314 604.53 949.939 600.618C949.564 596.716 951.467 592.383 955.13 590.96C957.848 589.903 961.025 590.614 963.452 592.242C965.878 593.871 967.659 596.276 969.158 598.784C973.384 605.85 975.718 614.038 975.849 622.264L976.027 626.485Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask12_201_27617)">
          <path
            d="M976.027 626.485C968.793 623.612 960.003 617.024 955.018 611.044C952.507 608.03 950.314 604.53 949.939 600.618C949.564 596.716 951.467 592.383 955.13 590.96C957.848 589.903 961.025 590.614 963.452 592.242C965.878 593.871 967.659 596.276 969.158 598.784C973.384 605.85 975.718 614.038 975.849 622.264L976.027 626.485Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M973.177 621.974C973.177 621.974 959.918 608.133 963.123 606.711C966.327 605.288 973.177 621.974 973.177 621.974Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M977.498 628.01C977.498 628.01 950.698 607.207 949.461 591.887"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M976.185 629.573C976.185 629.573 974.489 604.455 956.123 588.808"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask13_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="973"
          y="593"
          width="26"
          height="38"
        >
          <path
            d="M974.22 630.93C973.348 623.209 975.035 612.353 977.968 605.147C979.449 601.516 981.51 597.932 984.78 595.761C988.051 593.59 992.773 593.225 995.753 595.78C997.965 597.679 998.836 600.814 998.546 603.716C998.255 606.617 996.962 609.321 995.453 611.82C991.199 618.867 985.071 624.782 977.865 628.768L974.22 630.921V630.93Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask13_201_27617)">
          <path
            d="M974.22 630.93C973.348 623.209 975.035 612.353 977.968 605.147C979.449 601.516 981.51 597.932 984.78 595.761C988.051 593.59 992.773 593.225 995.753 595.78C997.965 597.679 998.836 600.814 998.546 603.716C998.255 606.617 996.962 609.321 995.453 611.82C991.199 618.867 985.071 624.782 977.865 628.768L974.22 630.921V630.93Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M976.873 626.298C976.873 626.298 982.851 608.096 985.625 610.248C988.399 612.401 976.873 626.298 976.873 626.298Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M973.574 632.933C973.574 632.933 979.328 599.523 992.278 591.222"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M971.568 632.521C971.568 632.521 992.952 619.204 998.125 595.649"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask14_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="940"
          y="602"
          width="37"
          height="27"
        >
          <path
            d="M976.946 628.618C969.177 629.114 958.401 626.906 951.345 623.63C947.785 621.974 944.299 619.747 942.293 616.378C940.279 613.009 940.148 608.282 942.846 605.428C944.852 603.313 948.028 602.602 950.914 603.023C953.8 603.444 956.443 604.876 958.87 606.495C965.71 611.081 971.323 617.482 974.978 624.866L976.946 628.609V628.618Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask14_201_27617)">
          <path
            d="M976.946 628.618C969.177 629.114 958.401 626.906 951.345 623.63C947.785 621.974 944.299 619.747 942.293 616.378C940.279 613.009 940.148 608.282 942.846 605.428C944.852 603.313 948.028 602.602 950.914 603.023C953.8 603.444 956.443 604.876 958.87 606.495C965.71 611.081 971.323 617.482 974.978 624.866L976.946 628.609V628.618Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M955.665 605.316C955.9 605.204 956.125 605.091 956.359 604.979C954.644 604.053 952.836 603.323 950.915 603.042C948.028 602.611 944.852 603.323 942.847 605.447C940.148 608.301 940.288 613.027 942.294 616.396C943.203 617.922 944.411 619.213 945.798 620.318C946.932 614.319 950.165 608.105 955.665 605.325V605.316Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M976.113 628.001C971.343 628.309 964.728 626.981 960.399 624.987C958.215 623.986 956.079 622.629 954.842 620.57C953.614 618.511 953.53 615.629 955.198 613.879C956.435 612.578 958.384 612.147 960.155 612.4C961.926 612.662 963.547 613.523 965.037 614.515C969.235 617.304 972.674 621.216 974.904 625.717L976.113 628.001Z"
              fill="white"
            />
          </g>
          <path
            d="M972.439 625.755C972.439 625.755 954.523 618.904 956.809 616.246C959.095 613.589 972.439 625.755 972.439 625.755Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M978.924 629.367C978.924 629.367 945.789 622.011 938.105 608.694"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M978.407 631.342C978.407 631.342 966.123 609.368 942.809 603.061"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask15_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="965"
          y="588"
          width="20"
          height="42"
        >
          <path
            d="M976.373 629.395C971.529 623.312 967.181 613.224 965.831 605.569C965.147 601.704 964.988 597.576 966.609 594.002C968.23 590.427 972.035 587.619 975.923 588.199C978.81 588.63 981.208 590.829 982.511 593.44C983.813 596.051 984.151 599.027 984.207 601.947C984.357 610.173 982.305 618.437 978.322 625.643L976.373 629.395Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask15_201_27617)">
          <path
            d="M976.373 629.395C971.529 623.312 967.181 613.224 965.831 605.569C965.147 601.704 964.988 597.576 966.609 594.002C968.23 590.427 972.035 587.619 975.923 588.199C978.81 588.63 981.208 590.829 982.511 593.44C983.813 596.051 984.151 599.027 984.207 601.947C984.357 610.173 982.305 618.437 978.322 625.643L976.373 629.395Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M983.354 598.634C983.579 598.756 983.804 598.887 984.029 599.008C983.813 597.071 983.373 595.171 982.511 593.44C981.208 590.829 978.809 588.63 975.923 588.199C972.034 587.619 968.23 590.427 966.609 594.002C965.878 595.621 965.512 597.352 965.391 599.121C970.966 596.622 977.919 595.723 983.354 598.634Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M976.41 628.366C973.43 624.632 970.741 618.455 969.907 613.766C969.485 611.399 969.373 608.872 970.366 606.691C971.35 604.511 973.683 602.798 976.063 603.154C977.834 603.425 979.305 604.773 980.111 606.373C980.917 607.973 981.133 609.798 981.161 611.586C981.264 616.621 980.027 621.674 977.6 626.073L976.419 628.366H976.41Z"
              fill="white"
            />
          </g>
          <path
            d="M976.149 624.07C976.149 624.07 971.529 605.475 975.024 605.831C978.519 606.186 976.149 624.07 976.149 624.07Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M976.889 631.445C976.889 631.445 963.995 600.084 970.545 586.178"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M974.967 632.156C974.967 632.156 985.996 609.527 977.853 586.823"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask16_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="975"
          y="603"
          width="37"
          height="27"
        >
          <path
            d="M975.316 629.311C978.343 622.152 985.136 613.514 991.218 608.667C994.291 606.224 997.843 604.109 1001.76 603.819C1005.68 603.529 1009.97 605.522 1011.32 609.209C1012.32 611.951 1011.53 615.105 1009.87 617.492C1008.19 619.878 1005.73 621.609 1003.19 623.05C996.025 627.121 987.788 629.274 979.542 629.227L975.307 629.321L975.316 629.311Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask16_201_27617)">
          <path
            d="M975.316 629.311C978.343 622.152 985.136 613.514 991.218 608.667C994.291 606.224 997.843 604.109 1001.76 603.819C1005.68 603.529 1009.97 605.522 1011.32 609.209C1012.32 611.951 1011.53 615.105 1009.87 617.492C1008.19 619.878 1005.73 621.609 1003.19 623.05C996.025 627.121 987.788 629.274 979.542 629.227L975.307 629.321L975.316 629.311Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M1005.71 620.711C1005.71 620.973 1005.71 621.226 1005.7 621.478C1007.3 620.365 1008.75 619.073 1009.87 617.482C1011.54 615.096 1012.32 611.933 1011.32 609.2C1009.97 605.513 1005.68 603.519 1001.76 603.81C999.989 603.941 998.293 604.446 996.682 605.185C1001.54 608.882 1005.67 614.544 1005.71 620.702V620.711Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M976.244 628.853C978.1 624.455 982.251 619.139 985.971 616.163C987.845 614.656 990.019 613.356 992.408 613.178C994.798 613 997.422 614.217 998.246 616.481C998.855 618.166 998.377 620.103 997.347 621.572C996.316 623.042 994.826 624.099 993.27 624.988C988.894 627.487 983.862 628.816 978.83 628.797L976.244 628.853Z"
              fill="white"
            />
          </g>
          <path
            d="M979.898 626.56C979.898 626.56 994.039 613.617 995.397 616.846C996.756 620.075 979.898 626.56 979.898 626.56Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M973.762 630.743C973.762 630.743 995.155 604.427 1010.52 603.52"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M972.225 629.405C972.225 629.405 997.403 628.244 1013.46 610.239"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask17_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="282"
          y="513"
          width="55"
          height="26"
        >
          <path
            d="M336.178 528.464C327.501 533.752 313.708 537.738 303.55 538.244C298.424 538.497 293.046 538.038 288.67 535.361C284.294 532.685 281.267 527.304 282.644 522.372C283.675 518.703 286.917 515.952 290.516 514.698C294.114 513.444 298.04 513.481 301.826 513.884C312.527 515.025 322.91 519.021 331.615 525.348L336.169 528.474L336.178 528.464Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask17_201_27617)">
          <path
            d="M336.178 528.464C327.501 533.752 313.708 537.738 303.55 538.244C298.424 538.497 293.046 538.038 288.67 535.361C284.294 532.685 281.267 527.304 282.644 522.372C283.675 518.703 286.917 515.952 290.516 514.698C294.114 513.444 298.04 513.481 301.826 513.884C312.527 515.025 322.91 519.021 331.615 525.348L336.169 528.474L336.178 528.464Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M329.224 527.893C329.224 527.893 304.355 530.85 305.376 526.396C306.398 521.932 329.224 527.893 329.224 527.893Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M338.914 528.127C338.914 528.127 296.138 539.732 279.158 528.998"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M339.532 530.719C339.532 530.719 311.955 512.77 281.182 519.639"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask18_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="310"
          y="483"
          width="31"
          height="51"
        >
          <path
            d="M339.403 533.836C330.641 528.68 320.624 518.404 315.405 509.701C312.772 505.302 310.644 500.352 310.944 495.232C311.244 490.113 314.571 484.91 319.584 483.806C323.304 482.982 327.258 484.545 330.07 487.128C332.871 489.702 334.689 493.183 336.114 496.711C340.143 506.678 341.502 517.712 340.021 528.352L339.403 533.836Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask18_201_27617)">
          <path
            d="M339.403 533.836C330.641 528.68 320.624 518.404 315.405 509.701C312.772 505.302 310.644 500.352 310.944 495.232C311.244 490.113 314.571 484.91 319.584 483.806C323.304 482.982 327.258 484.545 330.07 487.128C332.871 489.702 334.689 493.183 336.114 496.711C340.143 506.678 341.502 517.712 340.021 528.352L339.403 533.836Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M336.629 527.435C336.629 527.435 322.302 506.912 326.725 505.714C331.147 504.516 336.629 527.435 336.629 527.435Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M340.987 536.092C340.987 536.092 310.589 503.87 312.078 483.853"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M338.981 537.851C338.981 537.851 341.848 505.106 321.289 481.223"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask19_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="288"
          y="527"
          width="52"
          height="29"
        >
          <path
            d="M339.129 529.185C333.554 537.673 322.787 547.172 313.829 551.954C309.303 554.369 304.243 556.25 299.145 555.698C294.048 555.145 288.997 551.58 288.135 546.526C287.498 542.774 289.25 538.899 291.958 536.232C294.676 533.555 298.236 531.918 301.835 530.664C312.002 527.126 323.096 526.303 333.666 528.296L339.12 529.176L339.129 529.185Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask19_201_27617)">
          <path
            d="M339.129 529.185C333.554 537.673 322.787 547.172 313.829 551.954C309.303 554.369 304.243 556.25 299.145 555.698C294.048 555.145 288.997 551.58 288.135 546.526C287.498 542.774 289.25 538.899 291.958 536.232C294.676 533.555 298.236 531.918 301.835 530.664C312.002 527.126 323.096 526.303 333.666 528.296L339.12 529.176L339.129 529.185Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M298.057 533.069C298.114 532.741 298.179 532.404 298.254 532.077C295.94 533.134 293.756 534.444 291.948 536.222C289.23 538.899 287.478 542.764 288.125 546.517C288.987 551.57 294.028 555.136 299.135 555.688C301.44 555.941 303.745 555.688 305.985 555.117C300.616 549.221 296.652 540.977 298.057 533.059V533.069Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M337.826 529.56C334.406 534.782 327.818 540.621 322.336 543.579C319.572 545.067 316.48 546.227 313.359 545.899C310.248 545.572 307.166 543.382 306.65 540.284C306.266 537.982 307.344 535.605 309.002 533.958C310.661 532.311 312.844 531.3 315.046 530.533C321.259 528.352 328.043 527.828 334.499 529.035L337.835 529.569L337.826 529.56Z"
              fill="white"
            />
          </g>
          <path
            d="M332.598 531.637C332.598 531.637 311.384 544.935 310.4 540.471C309.416 535.998 332.598 531.637 332.598 531.637Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M341.463 527.716C341.463 527.716 307.766 556.484 287.816 554.032"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M343.121 529.793C343.121 529.793 310.512 525.348 285.633 544.702"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask20_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="293"
          y="495"
          width="47"
          height="36"
        >
          <path
            d="M339.487 530.383C329.329 530.664 315.376 527.267 306.315 522.662C301.742 520.341 297.31 517.262 294.845 512.77C292.381 508.278 292.428 502.102 296.092 498.508C298.809 495.841 302.988 495.064 306.736 495.757C310.485 496.449 313.867 498.433 316.95 500.67C325.665 506.977 332.684 515.597 337.097 525.404L339.496 530.383H339.487Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask20_201_27617)">
          <path
            d="M339.487 530.383C329.329 530.664 315.376 527.267 306.315 522.662C301.742 520.341 297.31 517.262 294.845 512.77C292.381 508.278 292.428 502.102 296.092 498.508C298.809 495.841 302.988 495.064 306.736 495.757C310.485 496.449 313.867 498.433 316.95 500.67C325.665 506.977 332.684 515.597 337.097 525.404L339.496 530.383H339.487Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M312.829 498.958C313.138 498.817 313.447 498.686 313.756 498.555C311.564 497.254 309.24 496.225 306.747 495.766C302.999 495.074 298.82 495.851 296.102 498.518C292.448 502.111 292.401 508.288 294.856 512.78C295.971 514.811 297.489 516.552 299.241 518.068C301.012 510.3 305.529 502.336 312.829 498.967V498.958Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M338.438 529.532C332.197 529.709 323.632 527.66 318.066 524.852C315.265 523.439 312.538 521.567 311.029 518.825C309.52 516.083 309.558 512.312 311.807 510.113C313.475 508.475 316.052 507.998 318.347 508.419C320.643 508.84 322.724 510.047 324.616 511.404C329.967 515.25 334.268 520.51 336.967 526.49L338.429 529.522L338.438 529.532Z"
              fill="white"
            />
          </g>
          <path
            d="M333.743 526.434C333.743 526.434 310.691 516.654 313.793 513.285C316.904 509.925 333.743 526.434 333.743 526.434Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M342.036 531.459C342.036 531.459 299.147 520.295 289.758 502.542"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M341.268 534.014C341.268 534.014 326.275 504.75 296.158 495.42"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask21_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="329"
          y="477"
          width="26"
          height="55"
        >
          <path
            d="M338.568 531.422C333.461 522.643 329.76 508.783 329.479 498.639C329.338 493.52 329.91 488.157 332.683 483.843C335.457 479.529 340.911 476.618 345.821 478.106C349.466 479.211 352.155 482.505 353.336 486.127C354.517 489.748 354.395 493.66 353.908 497.441C352.53 508.1 348.313 518.385 341.801 526.939L338.577 531.422H338.568Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask21_201_27617)">
          <path
            d="M338.568 531.422C333.461 522.643 329.76 508.783 329.479 498.639C329.338 493.52 329.91 488.157 332.683 483.843C335.457 479.529 340.911 476.618 345.821 478.106C349.466 479.211 352.155 482.505 353.336 486.127C354.517 489.748 354.395 493.66 353.908 497.441C352.53 508.1 348.313 518.385 341.801 526.939L338.577 531.422H338.568Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M353.439 492.996C353.711 493.192 353.973 493.407 354.236 493.613C354.33 491.077 354.123 488.532 353.336 486.127C352.156 482.505 349.466 479.211 345.821 478.106C340.911 476.618 335.457 479.529 332.684 483.843C331.428 485.79 330.631 487.961 330.135 490.216C337.809 488.054 346.974 488.195 353.43 492.996H353.439Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M338.812 530.092C335.664 524.702 333.377 516.214 333.181 509.99C333.078 506.855 333.424 503.57 335.111 500.931C336.798 498.292 340.134 496.523 343.142 497.441C345.381 498.124 347.03 500.155 347.761 502.373C348.492 504.59 348.426 506.986 348.136 509.298C347.311 515.83 344.753 522.119 340.78 527.341L338.812 530.083V530.092Z"
              fill="white"
            />
          </g>
          <path
            d="M339.29 524.496C339.29 524.496 336.854 499.593 341.305 500.716C345.747 501.83 339.29 524.496 339.29 524.496Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M338.848 534.164C338.848 534.164 328.138 491.208 339.251 474.475"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M336.234 534.725C336.234 534.725 354.788 507.576 348.575 476.703"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M410.496 677.049C383.855 636.62 345.268 605.073 300.383 586.861C297.028 585.504 295.576 590.932 298.883 592.28C342.325 609.911 379.864 640.785 405.633 679.894C407.61 682.898 412.482 680.09 410.487 677.058L410.496 677.049Z"
          fill="#94BDAE"
        />
        <mask
          id="mask22_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="268"
          y="565"
          width="45"
          height="32"
        >
          <path
            d="M312.386 593.262C309.491 577.914 294.741 565.692 279.102 565.664C276.759 565.664 274.314 565.935 272.346 567.208C269.169 569.276 268.045 573.609 268.907 577.297C269.778 580.984 272.271 584.082 275.073 586.627C283.909 594.685 296.531 598.391 308.329 596.397L312.377 593.253L312.386 593.262Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask22_201_27617)">
          <path
            d="M312.386 593.262C309.491 577.914 294.741 565.692 279.102 565.664C276.759 565.664 274.314 565.935 272.346 567.208C269.169 569.276 268.045 573.609 268.907 577.297C269.778 580.984 272.271 584.082 275.073 586.627C283.909 594.685 296.531 598.391 308.329 596.397L312.377 593.253L312.386 593.262Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M311.562 592.448C309.631 582.228 299.811 574.096 289.41 574.077C287.845 574.077 286.224 574.255 284.912 575.107C282.794 576.482 282.044 579.365 282.625 581.826C283.206 584.278 284.865 586.337 286.73 588.04C292.614 593.402 301.02 595.873 308.863 594.544L311.562 592.457V592.448Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M314.176 595.415C314.176 595.415 275.654 570.418 264.803 569.745"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M282.504 564.139C282.504 564.139 286.533 577.952 286.486 578.766C286.439 579.58 271.1 585.186 271.1 585.186"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M297.394 568.836L302.042 587.741C302.042 587.741 286.6 596.163 284.051 597.062"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M268.916 577.297C268.045 573.609 269.179 569.276 272.355 567.208C272.712 566.974 273.086 566.787 273.48 566.619C271.128 568.883 270.35 572.617 271.109 575.855C271.981 579.543 274.473 582.64 277.275 585.186C286.008 593.15 298.452 596.856 310.128 595.012L308.338 596.397C296.541 598.391 283.919 594.675 275.082 586.627C272.28 584.072 269.788 580.975 268.916 577.297Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask23_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="286"
          y="601"
          width="49"
          height="27"
        >
          <path
            d="M334.061 608.002C321.748 598.372 302.594 598.887 290.816 609.162C289.054 610.707 287.395 612.522 286.758 614.778C285.737 618.418 287.751 622.423 290.834 624.623C293.917 626.822 297.844 627.505 301.629 627.58C313.586 627.814 325.533 622.292 333.086 613.028L334.061 608.002Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask23_201_27617)">
          <path
            d="M334.061 608.002C321.748 598.372 302.594 598.887 290.816 609.162C289.054 610.707 287.395 612.522 286.758 614.778C285.737 618.418 287.751 622.423 290.834 624.623C293.917 626.822 297.844 627.505 301.629 627.58C313.586 627.814 325.533 622.292 333.086 613.028L334.061 608.002Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M332.9 607.936C324.7 601.526 311.947 601.872 304.113 608.713C302.942 609.742 301.836 610.95 301.415 612.447C300.731 614.871 302.071 617.538 304.132 618.998C306.184 620.458 308.799 620.916 311.319 620.963C319.284 621.122 327.23 617.444 332.262 611.277L332.909 607.927L332.9 607.936Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M336.825 608.432C336.825 608.432 291.369 615.002 282.748 621.637"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M292.361 605.784C292.361 605.784 304.515 613.514 305.012 614.16C305.508 614.805 297.684 629.124 297.684 629.124"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M306.652 599.514L322.62 610.669C322.62 610.669 316.566 627.168 315.245 629.517"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M290.834 624.623C287.751 622.423 285.737 618.418 286.758 614.777C286.871 614.366 287.03 613.973 287.208 613.598C286.936 616.846 288.82 620.168 291.528 622.105C294.611 624.304 298.537 624.988 302.323 625.062C314.148 625.296 325.955 619.897 333.517 610.819L333.086 613.046C325.533 622.311 313.586 627.833 301.629 627.599C297.844 627.524 293.917 626.841 290.834 624.641V624.623Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask24_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="316"
          y="622"
          width="49"
          height="28"
        >
          <path
            d="M364.131 629.442C351.818 619.812 332.665 620.327 320.886 630.602C319.124 632.147 317.466 633.962 316.828 636.218C315.807 639.858 317.822 643.863 320.905 646.063C323.988 648.262 327.914 648.945 331.7 649.02C343.656 649.254 355.604 643.732 363.156 634.467L364.131 629.442Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask24_201_27617)">
          <path
            d="M364.131 629.442C351.818 619.812 332.665 620.327 320.886 630.602C319.124 632.147 317.466 633.962 316.828 636.218C315.807 639.858 317.822 643.863 320.905 646.063C323.988 648.262 327.914 648.945 331.7 649.02C343.656 649.254 355.604 643.732 363.156 634.467L364.131 629.442Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M362.979 629.376C354.78 622.965 342.026 623.312 334.193 630.153C333.021 631.182 331.916 632.389 331.494 633.887C330.81 636.311 332.159 638.978 334.211 640.438C336.264 641.898 338.878 642.356 341.399 642.403C349.364 642.562 357.31 638.884 362.342 632.717L362.988 629.367L362.979 629.376Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M366.906 629.881C366.906 629.881 321.449 636.451 312.828 643.086"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M322.441 627.224C322.441 627.224 334.595 634.954 335.092 635.599C335.588 636.245 327.764 650.564 327.764 650.564"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M336.732 620.954L352.7 632.109C352.7 632.109 346.646 648.608 345.325 650.957"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M320.914 646.063C317.832 643.863 315.817 639.858 316.838 636.217C316.951 635.806 317.11 635.413 317.288 635.038C317.016 638.286 318.9 641.608 321.608 643.545C324.691 645.744 328.617 646.428 332.403 646.502C344.228 646.736 356.035 641.337 363.597 632.259L363.166 634.486C355.614 643.751 343.666 649.273 331.709 649.039C327.924 648.964 323.997 648.281 320.914 646.081V646.063Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask25_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="318"
          y="559"
          width="27"
          height="49"
        >
          <path
            d="M336.478 607.319C322.638 600.047 315.254 582.388 319.817 567.452C320.501 565.215 321.485 562.959 323.284 561.453C326.189 559.029 330.678 559.225 333.948 561.135C337.218 563.044 339.448 566.338 341.069 569.763C346.186 580.563 346.036 593.702 340.676 604.38L336.478 607.328V607.319Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask25_201_27617)">
          <path
            d="M336.478 607.319C322.638 600.047 315.254 582.388 319.817 567.452C320.501 565.215 321.485 562.959 323.284 561.453C326.189 559.029 330.678 559.225 333.948 561.135C337.218 563.044 339.448 566.338 341.069 569.763C346.186 580.563 346.036 593.702 340.676 604.38L336.478 607.328V607.319Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M335.934 606.289C326.723 601.451 321.804 589.687 324.84 579.748C325.299 578.26 325.945 576.754 327.145 575.752C329.084 574.133 332.064 574.264 334.248 575.537C336.431 576.81 337.912 579 338.989 581.283C342.4 588.471 342.297 597.221 338.727 604.324L335.934 606.289Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M338.007 609.658C338.007 609.658 325.375 565.552 327.905 554.986"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M317.354 570.249C317.354 570.249 329.395 578.148 330.191 578.344C330.988 578.541 340.855 565.533 340.855 565.533"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M317.484 585.841L334.22 595.817C334.22 595.817 346.805 583.538 348.417 581.367"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M333.939 561.125C330.668 559.216 326.18 559.019 323.275 561.443C322.947 561.714 322.657 562.023 322.385 562.341C325.243 560.76 329.047 561.115 331.924 562.791C335.194 564.7 337.424 567.994 339.046 571.419C344.106 582.097 344.012 595.059 338.821 605.671L340.676 604.37C346.036 593.692 346.186 580.553 341.07 569.753C339.449 566.337 337.218 563.034 333.948 561.125H333.939Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask26_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="350"
          y="581"
          width="26"
          height="49"
        >
          <path
            d="M364.14 629.583C351.508 620.383 346.767 601.835 353.448 587.722C354.45 585.607 355.744 583.511 357.749 582.294C360.982 580.32 365.386 581.162 368.347 583.52C371.308 585.888 373.032 589.463 374.147 593.085C377.642 604.511 375.581 617.491 368.722 627.28L364.14 629.592V629.583Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask26_201_27617)">
          <path
            d="M364.14 629.583C351.508 620.383 346.767 601.835 353.448 587.722C354.45 585.607 355.744 583.511 357.749 582.294C360.982 580.32 365.386 581.162 368.347 583.52C371.308 585.888 373.032 589.463 374.147 593.085C377.642 604.511 375.581 617.491 368.722 627.28L364.14 629.592V629.583Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M363.755 628.488C355.341 622.367 352.192 610.014 356.643 600.618C357.308 599.205 358.171 597.82 359.501 597.006C361.647 595.686 364.589 596.248 366.557 597.82C368.525 599.392 369.678 601.779 370.418 604.184C372.742 611.792 371.374 620.43 366.81 626.944L363.765 628.478L363.755 628.488Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M365.32 632.128C365.32 632.128 359.239 586.655 363.277 576.567"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M350.617 590.136C350.617 590.136 361.384 599.701 362.143 600C362.902 600.309 374.55 588.873 374.55 588.873"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M348.48 605.578L363.586 617.875C363.586 617.875 377.829 607.553 379.731 605.644"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M368.346 583.52C365.385 581.153 360.981 580.311 357.748 582.295C357.383 582.519 357.055 582.772 356.736 583.053C359.791 581.901 363.502 582.8 366.107 584.877C369.068 587.245 370.792 590.82 371.907 594.442C375.365 605.738 373.378 618.559 366.707 628.301L368.731 627.281C375.59 617.492 377.651 604.512 374.156 593.085C373.05 589.463 371.317 585.879 368.356 583.52H368.346Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask27_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="378"
          y="607"
          width="26"
          height="49"
        >
          <path
            d="M391.886 655.739C379.254 646.54 374.513 627.992 381.194 613.879C382.196 611.764 383.49 609.668 385.495 608.451C388.728 606.476 393.132 607.319 396.093 609.677C399.054 612.045 400.778 615.62 401.893 619.241C405.389 630.668 403.327 643.648 396.468 653.437L391.886 655.749V655.739Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask27_201_27617)">
          <path
            d="M391.886 655.739C379.254 646.54 374.513 627.992 381.194 613.879C382.196 611.764 383.49 609.668 385.495 608.451C388.728 606.476 393.132 607.319 396.093 609.677C399.054 612.045 400.778 615.62 401.893 619.241C405.389 630.668 403.327 643.648 396.468 653.437L391.886 655.749V655.739Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M391.501 654.654C383.087 648.533 379.938 636.18 384.389 626.784C385.055 625.371 385.917 623.986 387.247 623.172C389.393 621.852 392.335 622.414 394.303 623.986C396.271 625.558 397.424 627.945 398.164 630.35C400.488 637.958 399.12 646.596 394.556 653.11L391.511 654.644L391.501 654.654Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M393.066 658.285C393.066 658.285 386.985 612.812 391.023 602.724"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M378.363 616.294C378.363 616.294 389.13 625.858 389.889 626.158C390.648 626.457 402.296 615.03 402.296 615.03"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M376.227 631.735L391.332 644.032C391.332 644.032 405.575 633.71 407.477 631.8"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M396.093 609.677C393.131 607.31 388.727 606.467 385.494 608.451C385.129 608.676 384.801 608.938 384.482 609.209C387.537 608.058 391.248 608.957 393.853 611.034C396.814 613.393 398.538 616.977 399.653 620.599C403.111 631.894 401.125 644.715 394.453 654.458L396.477 653.438C403.336 643.649 405.397 630.668 401.902 619.242C400.797 615.62 399.063 612.036 396.102 609.677H396.093Z"
              fill="white"
            />
          </g>
        </g>
        <path
          d="M607.107 377.821C607.041 330.654 621.453 284.274 648.15 245.38C650.202 242.394 645.329 239.578 643.296 242.544C616.075 282.215 601.419 329.737 601.485 377.821C601.485 381.433 607.116 381.443 607.107 377.821Z"
          fill="#94BDAE"
        />
        <mask
          id="mask28_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="634"
          y="210"
          width="32"
          height="45"
        >
          <path
            d="M637.122 254.055C631.078 239.652 636.635 221.338 649.66 212.709C651.618 211.418 653.811 210.295 656.154 210.285C659.939 210.257 663.275 213.261 664.587 216.808C665.899 220.364 665.515 224.323 664.587 227.992C661.663 239.577 653.174 249.619 642.229 254.448L637.113 254.055H637.122Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask28_201_27617)">
          <path
            d="M637.122 254.055C631.078 239.652 636.635 221.338 649.66 212.709C651.618 211.418 653.811 210.295 656.154 210.285C659.939 210.257 663.275 213.261 664.587 216.808C665.899 220.364 665.515 224.323 664.587 227.992C661.663 239.577 653.174 249.619 642.229 254.448L637.113 254.055H637.122Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M637.366 252.923C633.337 243.34 637.038 231.136 645.715 225.399C647.018 224.538 648.479 223.79 650.035 223.78C652.556 223.762 654.776 225.764 655.648 228.123C656.519 230.49 656.266 233.12 655.648 235.563C653.699 243.274 648.048 249.956 640.767 253.175L637.366 252.913V252.923Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M636.812 256.834C636.812 256.834 655.16 214.777 663.846 208.236"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M645.986 213.308C645.986 213.308 650.25 227.056 650.737 227.711C651.224 228.366 667.126 224.604 667.126 224.604"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M636.156 225.418L642.706 243.742C642.706 243.742 660.239 242.273 662.862 241.617"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M664.587 216.808C663.275 213.252 659.949 210.257 656.154 210.285C655.732 210.285 655.31 210.342 654.889 210.407C658.103 210.997 660.811 213.692 661.963 216.808C663.275 220.364 662.891 224.323 661.963 227.992C659.068 239.446 650.728 249.385 639.971 254.28L642.229 254.448C653.164 249.619 661.654 239.577 664.587 227.992C665.515 224.323 665.89 220.364 664.587 216.808Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask29_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="627"
          y="255"
          width="47"
          height="29"
        >
          <path
            d="M627.17 278.284C632.127 263.479 648.404 253.362 663.893 255.449C666.217 255.768 668.606 256.366 670.377 257.901C673.245 260.381 673.77 264.827 672.411 268.364C671.052 271.892 668.147 274.625 665.036 276.777C655.188 283.562 642.172 285.518 630.759 281.953L627.17 278.293V278.284Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask29_201_27617)">
          <path
            d="M627.17 278.284C632.127 263.479 648.404 253.362 663.893 255.449C666.217 255.768 668.606 256.366 670.377 257.901C673.245 260.381 673.77 264.827 672.411 268.364C671.052 271.892 668.147 274.625 665.036 276.777C655.188 283.562 642.172 285.518 630.759 281.953L627.17 278.293V278.284Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M628.098 277.582C631.396 267.727 642.228 260.989 652.545 262.384C654.092 262.59 655.685 262.992 656.865 264.012C658.777 265.659 659.124 268.626 658.215 270.975C657.306 273.324 655.375 275.139 653.304 276.571C646.745 281.082 638.087 282.392 630.487 280.015L628.098 277.582Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M625.1 280.165C625.1 280.165 666.658 260.634 677.509 261.429"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M660.736 253.484C660.736 253.484 654.861 266.623 654.805 267.437C654.739 268.252 669.179 275.888 669.179 275.888"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M645.35 256.114L638.182 274.213C638.182 274.213 652.331 284.648 654.739 285.883"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M672.411 268.364C673.77 264.836 673.245 260.381 670.378 257.901C670.059 257.62 669.703 257.386 669.347 257.162C671.371 259.726 671.633 263.535 670.434 266.632C669.075 270.161 666.17 272.893 663.059 275.046C653.323 281.746 640.486 283.74 629.166 280.324L630.75 281.943C642.163 285.518 655.179 283.553 665.027 276.768C668.147 274.615 671.043 271.892 672.402 268.354L672.411 268.364Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask30_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="613"
          y="289"
          width="47"
          height="30"
        >
          <path
            d="M613.9 312.733C618.857 297.928 635.134 287.811 650.624 289.898C652.947 290.216 655.337 290.815 657.108 292.35C659.975 294.83 660.5 299.275 659.141 302.813C657.783 306.341 654.878 309.074 651.767 311.226C641.918 318.011 628.903 319.967 617.489 316.401L613.9 312.742V312.733Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask30_201_27617)">
          <path
            d="M613.9 312.733C618.857 297.928 635.134 287.811 650.624 289.898C652.947 290.216 655.337 290.815 657.108 292.35C659.975 294.83 660.5 299.275 659.141 302.813C657.783 306.341 654.878 309.074 651.767 311.226C641.918 318.011 628.903 319.967 617.489 316.401L613.9 312.742V312.733Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M614.826 312.031C618.125 302.176 628.957 295.438 639.274 296.833C640.82 297.038 642.413 297.441 643.594 298.461C645.505 300.108 645.852 303.075 644.943 305.424C644.034 307.773 642.104 309.588 640.033 311.02C633.474 315.531 624.815 316.841 617.216 314.464L614.826 312.031Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M611.838 314.614C611.838 314.614 653.396 295.083 664.247 295.878"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M647.465 287.933C647.465 287.933 641.59 301.072 641.533 301.886C641.468 302.7 655.908 310.337 655.908 310.337"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M632.079 290.562L624.91 308.662C624.91 308.662 639.06 319.097 641.468 320.332"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M659.14 302.812C660.498 299.284 659.974 294.83 657.106 292.35C656.788 292.069 656.432 291.835 656.075 291.61C658.099 294.175 658.362 297.983 657.162 301.081C655.804 304.609 652.899 307.342 649.788 309.494C640.052 316.195 627.214 318.188 615.895 314.773L617.478 316.392C628.891 319.967 641.907 318.001 651.756 311.216C654.876 309.064 657.771 306.341 659.13 302.803L659.14 302.812Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask31_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="599"
          y="233"
          width="30"
          height="47"
        >
          <path
            d="M624.78 279.042C632.314 265.36 628.734 246.559 616.684 236.602C614.875 235.114 612.814 233.766 610.481 233.504C606.714 233.083 603.078 235.712 601.401 239.1C599.723 242.488 599.676 246.475 600.211 250.218C601.897 262.047 609.272 272.931 619.645 278.883L624.771 279.033L624.78 279.042Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask31_201_27617)">
          <path
            d="M624.78 279.042C632.314 265.36 628.734 246.559 616.684 236.602C614.875 235.114 612.814 233.766 610.481 233.504C606.714 233.083 603.078 235.712 601.401 239.1C599.723 242.488 599.676 246.475 600.211 250.218C601.897 262.047 609.272 272.931 619.645 278.883L624.771 279.033L624.78 279.042Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M624.658 277.891C629.681 268.785 627.291 256.264 619.27 249.638C618.071 248.646 616.693 247.747 615.147 247.579C612.636 247.298 610.218 249.048 609.103 251.304C607.988 253.559 607.96 256.207 608.316 258.706C609.44 266.577 614.35 273.829 621.247 277.788L624.658 277.891Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M624.789 281.84C624.789 281.84 610.995 238.08 603.049 230.659"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M620.272 237.584C620.272 237.584 614.584 250.798 614.022 251.397C613.46 251.996 598.055 246.578 598.055 246.578"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M628.761 250.667L620.309 268.195C620.309 268.195 603.03 264.883 600.49 263.956"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M601.41 239.1C603.087 235.703 606.723 233.073 610.49 233.504C610.912 233.551 611.324 233.644 611.727 233.756C608.475 234.009 605.486 236.396 604.015 239.381C602.338 242.778 602.291 246.755 602.825 250.499C604.493 262.197 611.727 272.959 621.913 278.958L619.645 278.892C609.281 272.931 601.897 262.047 600.211 250.227C599.676 246.484 599.723 242.507 601.401 239.11L601.41 239.1Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask32_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="584"
          y="269"
          width="32"
          height="45"
        >
          <path
            d="M613.976 312.845C619.448 298.218 613.161 280.128 599.798 272.023C597.793 270.807 595.554 269.777 593.211 269.852C589.425 269.983 586.211 273.109 585.049 276.712C583.887 280.315 584.421 284.255 585.49 287.886C588.872 299.341 597.756 309.045 608.879 313.434L613.976 312.845Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask32_201_27617)">
          <path
            d="M613.976 312.845C619.448 298.218 613.161 280.128 599.798 272.023C597.793 270.807 595.554 269.777 593.211 269.852C589.425 269.983 586.211 273.109 585.049 276.712C583.887 280.315 584.421 284.255 585.49 287.886C588.872 299.341 597.756 309.045 608.879 313.434L613.976 312.845Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M613.695 311.721C617.34 301.979 613.152 289.944 604.259 284.554C602.929 283.749 601.439 283.056 599.874 283.113C597.353 283.197 595.217 285.284 594.439 287.68C593.661 290.075 594.017 292.705 594.729 295.12C596.988 302.747 602.9 309.204 610.303 312.124L613.695 311.731V311.721Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M614.398 315.615C614.398 315.615 594.383 274.325 585.443 268.13"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M603.49 272.491C603.49 272.491 599.779 286.397 599.32 287.071C598.852 287.745 582.828 284.619 582.828 284.619"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M613.799 284.198L607.979 302.775C607.979 302.775 590.4 301.998 587.758 301.455"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M585.048 276.721C586.21 273.118 589.424 269.992 593.21 269.861C593.632 269.843 594.053 269.88 594.475 269.936C591.289 270.657 588.684 273.455 587.662 276.618C586.501 280.221 587.035 284.161 588.103 287.792C591.448 299.125 600.182 308.727 611.126 313.182L608.877 313.444C597.755 309.055 588.871 299.35 585.489 287.895C584.411 284.264 583.877 280.324 585.048 276.721Z"
              fill="white"
            />
          </g>
        </g>
        <mask
          id="mask33_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="575"
          y="306"
          width="33"
          height="45"
        >
          <path
            d="M605.261 349.942C610.734 335.315 604.446 317.225 591.084 309.12C589.078 307.904 586.839 306.874 584.496 306.949C580.71 307.08 577.496 310.206 576.334 313.809C575.172 317.412 575.707 321.352 576.775 324.983C580.158 336.438 589.041 346.142 600.164 350.532L605.261 349.942Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask33_201_27617)">
          <path
            d="M605.261 349.942C610.734 335.315 604.446 317.225 591.084 309.12C589.078 307.904 586.839 306.874 584.496 306.949C580.71 307.08 577.496 310.206 576.334 313.809C575.172 317.412 575.707 321.352 576.775 324.983C580.158 336.438 589.041 346.142 600.164 350.532L605.261 349.942Z"
            fill="#94BDAE"
          />
          <g opacity="0.25">
            <path
              d="M604.971 348.828C608.616 339.086 604.427 327.051 595.535 321.661C594.204 320.856 592.714 320.163 591.149 320.22C588.628 320.304 586.492 322.391 585.714 324.786C584.936 327.182 585.293 329.812 586.005 332.226C588.263 339.854 594.176 346.311 601.579 349.231L604.971 348.838V348.828Z"
              fill="#5E8474"
            />
          </g>
          <path
            d="M605.674 352.712C605.674 352.712 585.658 311.422 576.719 305.227"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M594.775 309.588C594.775 309.588 591.065 323.495 590.605 324.168C590.137 324.842 574.113 321.716 574.113 321.716"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M605.084 321.295L599.265 339.872C599.265 339.872 581.685 339.095 579.043 338.552"
            stroke="#5E8474"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <g opacity="0.28">
            <path
              d="M576.325 313.818C577.487 310.215 580.701 307.09 584.487 306.958C584.909 306.94 585.331 306.977 585.752 307.033C582.566 307.754 579.961 310.552 578.94 313.715C577.778 317.318 578.312 321.258 579.38 324.889C582.726 336.222 591.459 345.824 602.404 350.279L600.155 350.541C589.032 346.152 580.149 336.447 576.766 324.992C575.688 321.361 575.154 317.421 576.325 313.818Z"
              fill="white"
            />
          </g>
        </g>
        <path
          opacity="0.28"
          d="M203.379 689.346C190.485 694.68 186.155 699.004 180.814 711.881C175.473 699.004 171.144 694.68 158.25 689.346C171.144 684.011 175.473 679.688 180.814 666.811C186.155 679.688 190.485 684.011 203.379 689.346Z"
          fill="#F7ACC0"
        />
        <path
          opacity="0.28"
          d="M45.1285 815.143C32.2347 820.477 27.9055 824.801 22.5643 837.678C17.2231 824.801 12.8939 820.477 0 815.143C12.8939 809.808 17.2231 805.485 22.5643 792.607C27.9055 805.485 32.2347 809.808 45.1285 815.143Z"
          fill="#F7ACC0"
        />
        <path
          d="M84.9902 702.251C77.9248 705.171 75.5447 707.548 72.6211 714.604C69.6975 707.548 67.3173 705.171 60.252 702.251C67.3173 699.331 69.6975 696.954 72.6211 689.898C75.5447 696.954 77.9248 699.331 84.9902 702.251Z"
          fill="white"
        />
        <path
          d="M281.998 386.159C274.933 389.079 272.552 391.456 269.629 398.513C266.705 391.456 264.325 389.079 257.26 386.159C264.325 383.24 266.705 380.862 269.629 373.806C272.552 380.862 274.933 383.24 281.998 386.159Z"
          fill="white"
        />
        <path
          d="M863.318 358.748C856.253 361.668 853.873 364.045 850.949 371.101C848.026 364.045 845.645 361.668 838.58 358.748C845.645 355.828 848.026 353.451 850.949 346.395C853.873 353.451 856.253 355.828 863.318 358.748Z"
          fill="white"
        />
        <path
          d="M770.203 116.841C763.138 119.761 760.758 122.138 757.834 129.194C754.91 122.138 752.53 119.761 745.465 116.841C752.53 113.921 754.91 111.544 757.834 104.488C760.758 111.544 763.138 113.921 770.203 116.841Z"
          fill="white"
        />
        <path
          d="M1008.81 737.804C1001.75 740.724 999.369 743.101 996.445 750.158C993.522 743.101 991.142 740.724 984.076 737.804C991.142 734.885 993.522 732.507 996.445 725.451C999.369 732.507 1001.75 734.885 1008.81 737.804Z"
          fill="white"
        />
        <path
          opacity="0.46"
          d="M453.843 111.815C440.949 117.149 436.62 121.473 431.279 134.35C425.938 121.473 421.609 117.149 408.715 111.815C421.609 106.481 425.938 102.157 431.279 89.2798C436.62 102.157 440.949 106.481 453.843 111.815Z"
          fill="#F7ACC0"
        />
        <path
          opacity="0.46"
          d="M975.418 298.882C962.524 304.216 958.195 308.54 952.853 321.417C947.512 308.54 943.183 304.216 930.289 298.882C943.183 293.548 947.512 289.224 952.853 276.347C958.195 289.224 962.524 293.548 975.418 298.882Z"
          fill="#F7ACC0"
        />
        <path
          opacity="0.46"
          d="M874.59 853.99C861.696 859.324 857.366 863.648 852.025 876.525C846.684 863.648 842.355 859.324 829.461 853.99C842.355 848.655 846.684 844.332 852.025 831.455C857.366 844.332 861.696 848.655 874.59 853.99Z"
          fill="#F7ACC0"
        />
        <path
          d="M894.821 639.184C725.917 639.184 589.004 502.438 589.004 333.761C589.004 239.643 631.649 155.472 698.667 99.4526C692.333 99.1251 685.951 98.9473 679.523 98.9473C480.83 98.9473 319.76 259.81 319.76 458.247C319.76 656.685 480.83 817.548 679.523 817.548C819.791 817.548 941.299 737.364 1000.63 620.402C967.658 632.549 932.012 639.184 894.811 639.184H894.821Z"
          fill="#FFC96A"
        />
        <path
          opacity="0.28"
          d="M666.751 99.4526C599.724 155.482 557.088 239.643 557.088 333.761C557.088 502.438 694.01 639.184 862.905 639.184C868.218 639.184 873.503 639.035 878.751 638.763C717.324 630.415 589.013 497.048 589.013 333.761C589.013 239.643 631.659 155.472 698.677 99.4526C692.342 99.1251 685.961 98.9473 679.533 98.9473C674.163 98.9473 668.813 99.0689 663.5 99.3029C664.587 99.3497 665.664 99.3965 666.751 99.4526Z"
          fill="white"
        />
        <path
          opacity="0.21"
          d="M728.175 777.54C529.482 777.54 368.412 616.677 368.412 418.24C368.412 317.824 409.661 227.028 476.164 161.827C381.718 226.588 319.77 335.184 319.77 458.248C319.77 656.685 480.84 817.548 679.533 817.548C777.671 817.548 866.625 778.289 931.525 714.651C873.681 754.313 803.646 777.54 728.175 777.54Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M511.51 225.109C522.176 225.109 530.823 213.277 530.823 198.681C530.823 184.085 522.176 172.252 511.51 172.252C500.844 172.252 492.197 184.085 492.197 198.681C492.197 213.277 500.844 225.109 511.51 225.109Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M774.05 751.483C777.463 741.391 769.005 729.424 755.158 724.754C741.312 720.084 727.32 724.479 723.908 734.572C720.495 744.664 728.953 756.631 742.8 761.301C756.646 765.972 770.638 761.576 774.05 751.483Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M534.401 272.472C540.538 272.472 545.514 265.66 545.514 257.255C545.514 248.851 540.538 242.039 534.401 242.039C528.263 242.039 523.287 248.851 523.287 257.255C523.287 265.66 528.263 272.472 534.401 272.472Z"
          fill="#DF8941"
        />
        <path
          opacity="0.21"
          d="M811.685 714.492C819.79 716.757 825.009 723.383 823.36 729.279C821.702 735.184 813.793 738.132 805.697 735.867C797.591 733.602 792.372 726.977 794.021 721.081C795.68 715.176 803.588 712.228 811.685 714.492Z"
          fill="#DF8941"
        />
        <path
          d="M519.11 517.168C513.768 517.168 508.446 515.961 503.957 513.687C492.45 507.857 483.539 495.869 478.873 479.94C478.582 478.948 479.154 477.91 480.147 477.619C481.14 477.329 482.18 477.9 482.471 478.892C485.001 487.539 491.382 503.112 505.654 510.346C516.701 515.942 533.278 514.305 540.362 502.944C544.616 496.102 544.935 486.435 541.196 477.077C537.738 468.411 531.61 461.018 525.697 453.868C525.041 453.072 525.154 451.893 525.95 451.229C526.747 450.573 527.927 450.676 528.593 451.481C534.702 458.856 541.009 466.483 544.691 475.692C548.861 486.145 548.439 497.076 543.557 504.918C538.329 513.322 528.677 517.168 519.128 517.168H519.11Z"
          fill="#6B3E3D"
        />
        <path
          d="M488.777 523.56C488.458 523.56 488.14 523.476 487.84 523.307C486.94 522.793 486.641 521.642 487.156 520.753L495.524 506.303C496.039 505.405 497.192 505.105 498.082 505.62C498.982 506.135 499.281 507.286 498.766 508.175L490.398 522.624C490.051 523.223 489.424 523.56 488.777 523.56Z"
          fill="#6B3E3D"
        />
        <path
          d="M517.667 535.436C516.636 535.436 515.793 534.594 515.793 533.565V517.328C515.793 516.298 516.636 515.456 517.667 515.456C518.698 515.456 519.541 516.298 519.541 517.328V533.565C519.541 534.594 518.698 535.436 517.667 535.436Z"
          fill="#6B3E3D"
        />
        <path
          d="M607.493 591.016C596.717 591.016 589.595 588.555 589.267 588.443C588.292 588.096 587.777 587.029 588.124 586.056C588.47 585.083 589.539 584.568 590.513 584.914C590.982 585.074 625.39 596.566 656.426 566.572C657.166 565.851 658.356 565.87 659.077 566.619C659.799 567.358 659.78 568.546 659.031 569.267C641.911 585.813 623.891 590.165 611.794 590.895C610.304 590.988 608.87 591.025 607.502 591.025L607.493 591.016Z"
          fill="#6B3E3D"
        />
        <path
          opacity="0.58"
          d="M510.742 546.938C513.468 551.664 508.821 559.45 500.359 564.335C491.897 569.221 482.817 569.342 480.081 564.616C477.354 559.89 482.002 552.104 490.464 547.219C498.925 542.334 508.005 542.212 510.742 546.938Z"
          fill="#F4627D"
        />
        <path
          d="M584.215 598.924C583.774 598.924 583.324 598.765 582.968 598.447C582.2 597.754 582.134 596.575 582.818 595.808C582.912 595.705 591.805 585.523 588.684 573.572C588.422 572.571 589.022 571.551 590.024 571.289C591.027 571.027 592.048 571.625 592.311 572.627C595.947 586.552 586.033 597.829 585.611 598.307C585.245 598.718 584.73 598.934 584.215 598.934V598.924Z"
          fill="#6B3E3D"
        />
        <path
          opacity="0.28"
          d="M573.074 596.482C581.748 596.482 588.779 589.715 588.779 581.368C588.779 573.021 581.748 566.254 573.074 566.254C564.401 566.254 557.369 573.021 557.369 581.368C557.369 589.715 564.401 596.482 573.074 596.482Z"
          fill="white"
        />
        <path
          d="M917.947 520.126C908.783 497.975 892.141 477.021 868.846 471.013C854.209 467.241 838.673 469.759 823.698 467.69C804.236 465.005 786.038 454.205 774.409 438.445C771.439 434.421 768.806 430.013 764.823 426.972C755.087 419.541 741.144 422.676 729.581 426.775C694.197 439.316 660.941 457.789 631.64 481.185C631.265 480.371 629.794 484.32 628.051 489.786C628.622 493.819 629.335 497.825 630.206 501.802C636.034 528.38 650.231 553.424 667.014 574.611C676.187 586.188 686.42 596.847 697.487 606.589C700.841 609.022 704.271 611.371 707.766 613.627C745.08 637.669 790.18 650.349 834.465 646.494C867.065 643.649 901.221 629.92 917.254 601.526C931.075 577.044 928.686 546.105 917.938 520.135L917.947 520.126Z"
          fill="#DF8941"
        />

        <path
          id="heart"
          opacity="0.77"
          d="M797.069 580.975C790.828 570.774 779.883 564.794 769.445 559.647C756.991 553.517 744.575 547.93 732.909 540.322C721.599 532.947 710.916 524.59 701.096 515.316C684.322 499.463 671.054 480.83 661.355 459.914C651.066 466.455 641.124 473.549 631.604 481.176C631.229 480.362 629.777 484.283 628.043 489.72C628.615 493.773 629.336 497.797 630.208 501.793C636.036 528.371 650.232 553.414 667.015 574.602C676.498 586.571 687.115 597.558 698.603 607.562C701.508 609.649 704.46 611.671 707.468 613.617C730.332 628.404 756.138 638.876 782.873 643.873C794.989 635.741 802.588 621.619 803.31 606.486C803.731 597.577 801.717 588.611 797.05 580.975H797.069Z"
          fill="#FFF2E2"
        />

        <path id="tail"
          d="M904.024 524.103C917.681 524.103 928.753 511.818 928.753 496.664C928.753 481.51 917.681 469.225 904.024 469.225C890.366 469.225 879.295 481.51 879.295 496.664C879.295 511.818 890.366 524.103 904.024 524.103Z"
          fill="#DF8941"
        />
        <path
          d="M865.933 501.287C861.247 501.709 857.855 508.615 857.228 517.861C849.16 513.285 841.522 512.293 838.58 515.962C834.869 520.603 840.042 530.907 850.153 538.983C855.991 543.653 862.185 546.536 867.104 547.256C868.097 547.762 869.119 548.005 870.14 547.911C871.096 547.827 871.986 547.443 872.82 546.863C873.767 546.489 874.582 545.955 875.172 545.216C875.819 544.411 876.175 543.429 876.306 542.324C878.414 537.832 879.426 531.075 878.761 523.645C877.599 510.768 871.855 500.763 865.933 501.297V501.287Z"
          fill="#F4627D"
        />
        <g mode="overlay" opacity="0.31">
          <path
            d="M864.687 510.749C861.688 511.02 859.514 515.447 859.111 521.361C853.948 518.432 849.056 517.796 847.173 520.145C844.793 523.111 848.11 529.709 854.585 534.884C858.324 537.87 862.288 539.723 865.436 540.181C866.073 540.499 866.72 540.658 867.385 540.602C867.994 540.546 868.566 540.303 869.1 539.928C869.709 539.694 870.225 539.348 870.609 538.871C871.021 538.356 871.255 537.729 871.33 537.018C872.68 534.145 873.326 529.821 872.895 525.058C872.155 516.813 868.472 510.412 864.687 510.758V510.749Z"
            fill="white"
          />
        </g>
        <path
          d="M857.994 512.302C857.619 514.024 857.357 515.887 857.226 517.861C855.502 516.888 853.805 516.074 852.156 515.428"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M842.046 513.959C840.584 514.277 839.403 514.941 838.588 515.962C834.877 520.603 840.05 530.907 850.161 538.983C855.998 543.653 862.192 546.536 867.112 547.256C868.105 547.762 869.127 548.005 870.148 547.911C871.104 547.827 871.994 547.443 872.828 546.863C873.774 546.489 874.59 545.955 875.189 545.216C875.836 544.411 876.192 543.429 876.323 542.324C878.432 537.832 879.444 531.075 878.778 523.645C877.616 510.768 871.872 500.763 865.95 501.297C863.326 501.531 861.105 503.805 859.55 507.38"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M827.776 565.066C827.776 565.066 831.889 643.134 828.806 650.321C825.724 657.509 808.238 664.705 827.776 680.109C847.313 695.513 939.885 668.814 924.452 542.474L827.776 565.075V565.066Z"
          fill="#DF8941"
        />
        <path
          opacity="0.48"
          d="M904.342 493.959C904.342 493.959 915.718 508.016 917.948 520.126"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M911.838 557.195C911.838 557.195 915.174 567.18 913.506 574.667"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M906.844 568.846C906.844 568.846 906.01 577.166 905.176 582.996"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.25"
          d="M689.737 545.562C707.588 544.234 725.326 540.696 741.744 533.434C757.955 526.265 771.739 515.634 782.908 501.877C792.485 490.085 800.094 476.824 805.192 462.543C793.038 457.387 782.234 449.048 774.409 438.445C771.439 434.421 768.806 430.013 764.823 426.972C755.087 419.541 741.144 422.676 729.581 426.775C694.197 439.316 660.941 457.789 631.64 481.185C631.265 480.371 629.794 484.32 628.051 489.786C628.622 493.819 629.335 497.825 630.206 501.802C633.289 515.859 638.724 529.475 645.63 542.334C659.124 548.117 675.475 546.629 689.728 545.572L689.737 545.562Z"
          fill="#D86B21"
        />
        <path
          d="M563.554 444.556C586.615 444.556 605.309 425.149 605.309 401.208C605.309 377.267 586.615 357.859 563.554 357.859C540.493 357.859 521.799 377.267 521.799 401.208C521.799 425.149 540.493 444.556 563.554 444.556Z"
          fill="#DF8941"
        />
        <path
          opacity="0.28"
          d="M528.461 401.208C528.461 378.429 545.384 359.768 566.89 358.009C565.794 357.916 564.678 357.859 563.554 357.859C540.493 357.859 521.799 377.269 521.799 401.208C521.799 425.147 540.493 444.556 563.554 444.556C564.678 444.556 565.784 444.5 566.89 444.407C545.384 442.638 528.461 423.986 528.461 401.208Z"
          fill="white"
        />
        <path
          opacity="0.77"
          d="M563.554 427.814C577.708 427.814 589.183 415.902 589.183 401.208C589.183 386.514 577.708 374.602 563.554 374.602C549.4 374.602 537.926 386.514 537.926 401.208C537.926 415.902 549.4 427.814 563.554 427.814Z"
          fill="#FFF2E2"
        />
        <path
          opacity="0.15"
          d="M582.277 404.146C575.024 411.399 562.055 413.186 552.525 410.435C546.519 408.704 541.702 405.063 537.945 400.44C537.945 400.702 537.926 400.955 537.926 401.217C537.926 415.91 549.395 427.823 563.554 427.823C577.713 427.823 589.183 415.91 589.183 401.217C589.183 398.737 588.845 396.341 588.236 394.058C587.093 397.801 585.191 401.236 582.277 404.146Z"
          fill="#D86B21"
        />
        <path id="ears"
          d="M730.238 357.054C753.298 357.054 771.993 337.647 771.993 313.706C771.993 289.765 753.298 270.357 730.238 270.357C707.177 270.357 688.482 289.765 688.482 313.706C688.482 337.647 707.177 357.054 730.238 357.054Z"
          fill="#DF8941"
        />
        <path
          opacity="0.28"
          d="M693.899 319.115C693.899 295.176 712.593 275.767 735.654 275.767C745.54 275.767 754.61 279.342 761.76 285.294C754.104 276.15 742.822 270.357 730.238 270.357C707.177 270.357 688.482 289.767 688.482 313.706C688.482 327.388 694.592 339.582 704.131 347.527C697.759 339.919 693.899 329.99 693.899 319.115Z"
          fill="white"
        />
        <path
          opacity="0.77"
          d="M730.238 340.312C744.392 340.312 755.866 328.4 755.866 313.706C755.866 299.012 744.392 287.1 730.238 287.1C716.084 287.1 704.609 299.012 704.609 313.706C704.609 328.4 716.084 340.312 730.238 340.312Z"
          fill="#FFF2E2"
        />
        <path
          opacity="0.15"
          d="M739.674 321.492C728.467 315.69 718.712 304.89 714.683 292.565C708.564 297.422 704.619 305.077 704.619 313.696C704.619 328.389 716.089 340.303 730.248 340.303C740.218 340.303 748.848 334.388 753.084 325.759C748.417 325.226 743.844 323.644 739.684 321.483L739.674 321.492Z"
          fill="#D86B21"
        />
        <path
          d="M820.888 416.003C820.082 413.074 819.06 410.238 817.851 407.525L817.964 407.487C772.076 288.457 664.624 321.436 664.624 321.436C523.644 358.009 565.521 489.168 565.521 489.168C574.077 520.145 605.721 539.563 641.395 538.562L641.413 538.618C641.413 538.618 701.272 540.218 784.623 495.784L784.492 495.26C813.466 477.414 829.115 445.829 820.878 416.003H820.888Z"
          fill="#DF8941"
        />
        <path
          opacity="0.28"
          d="M576.354 489.168C576.354 489.168 534.477 358.009 675.457 321.436C675.457 321.436 685.671 318.301 701.141 317.459C700.898 317.393 700.654 317.328 700.41 317.271C679.439 316.897 664.624 321.445 664.624 321.445C523.644 358.018 565.521 489.177 565.521 489.177C574.077 520.154 605.721 539.573 641.395 538.571L641.413 538.628C641.413 538.628 643.072 538.674 646.221 538.543C613.049 537.336 584.412 518.413 576.344 489.177L576.354 489.168Z"
          fill="white"
        />
        <path
          opacity="0.28"
          d="M820.888 416.003C820.082 413.074 819.061 410.239 817.852 407.525L817.965 407.487C795.728 349.802 759.033 327.818 727.117 320.481C745.68 330.214 761.329 345.085 771.843 363.68C786.039 388.78 790.659 418.97 785.177 447.214C779.555 476.188 763.016 501.802 737.406 516.897C736.478 517.44 735.551 517.964 734.614 518.488C749.963 512.723 766.755 505.321 784.624 495.794L784.493 495.27C813.467 477.423 829.116 445.838 820.879 416.013L820.888 416.003Z"
          fill="#D86B21"
        />
        <path
          opacity="0.15"
          d="M709.347 501.959C729.942 494.002 741.093 473.237 734.253 455.58C727.413 437.923 705.173 430.06 684.578 438.017C663.983 445.975 652.832 466.739 659.672 484.396C666.512 502.053 688.752 509.917 709.347 501.959Z"
          fill="#D86B21"
        />
        <path
          d="M712.161 427.458C710.043 427.458 707.888 426.999 705.798 426.073C701.75 424.285 698.433 420.963 696.446 416.714C696.006 415.779 696.418 414.665 697.355 414.225C698.292 413.785 699.407 414.197 699.848 415.133C701.45 418.558 704.102 421.225 707.316 422.648C710.446 424.033 713.735 424.061 716.584 422.732C719.432 421.403 721.522 418.867 722.468 415.573C723.443 412.194 723.096 408.451 721.494 405.026C721.053 404.09 721.466 402.976 722.403 402.536C723.34 402.097 724.455 402.508 724.895 403.444C726.882 407.684 727.294 412.363 726.067 416.612C724.82 420.954 722.009 424.332 718.167 426.12C716.265 427.009 714.232 427.449 712.161 427.449V427.458Z"
          fill="#6B3E3D"
        />
        <path
          d="M631.743 465.463C629.625 465.463 627.47 465.004 625.38 464.078C621.332 462.29 618.015 458.968 616.028 454.719C615.588 453.784 616 452.67 616.937 452.23C617.874 451.79 618.989 452.202 619.43 453.138C621.032 456.563 623.684 459.23 626.898 460.653C630.028 462.038 633.317 462.066 636.166 460.737C642.135 457.957 644.346 450.012 641.085 443.031C640.645 442.095 641.057 440.981 641.994 440.541C642.931 440.101 644.046 440.513 644.487 441.449C648.619 450.293 645.602 460.465 637.759 464.125C635.856 465.014 633.823 465.454 631.752 465.454L631.743 465.463Z"
          fill="#6B3E3D"
        />
        <path
          d="M731.155 414.385C731.155 414.385 731.099 414.385 731.071 414.385L725.233 414.132C724.202 414.085 723.396 413.215 723.443 412.176C723.49 411.146 724.371 410.36 725.392 410.388L731.23 410.641C732.261 410.688 733.067 411.558 733.02 412.597C732.973 413.598 732.149 414.385 731.146 414.385H731.155Z"
          fill="#6B3E3D"
        />
        <path
          d="M618.26 467.588C617.782 467.588 617.304 467.4 616.939 467.035C616.208 466.306 616.208 465.117 616.939 464.387L621.755 459.577C622.486 458.847 623.676 458.847 624.407 459.577C625.138 460.307 625.138 461.495 624.407 462.225L619.591 467.035C619.225 467.4 618.747 467.588 618.269 467.588H618.26Z"
          fill="#6B3E3D"
        />
        <path
          d="M624.603 470.629C624.425 470.629 624.257 470.601 624.079 470.554C623.085 470.264 622.514 469.225 622.804 468.233L624.585 462.15C624.875 461.158 625.915 460.587 626.908 460.877C627.902 461.167 628.473 462.206 628.183 463.198L626.402 469.281C626.168 470.095 625.419 470.629 624.603 470.629Z"
          fill="#6B3E3D"
        />
        <path
          d="M638.51 475.664C639.896 478.415 637.001 482.655 632.053 485.135C627.106 487.615 621.98 487.39 620.593 484.639C619.206 481.887 622.102 477.648 627.049 475.168C631.997 472.688 637.123 472.912 638.51 475.664Z"
          fill="#F4627D"
        />
        <path
          opacity="0.48"
          d="M686.889 533.069C686.889 533.069 773.538 518.095 809.362 468.167"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.77"
          d="M704.341 501.132C724.936 493.175 736.087 472.41 729.247 454.753C722.407 437.096 700.167 429.233 679.572 437.191C658.977 445.148 647.826 465.913 654.666 483.57C661.506 501.227 683.746 509.09 704.341 501.132Z"
          fill="#FFF2E2"
        />
        <path
          d="M693.889 447.888C695.294 451.622 691.649 456.442 685.755 458.659C679.852 460.877 673.93 459.651 672.524 455.917C671.118 452.183 674.764 447.364 680.658 445.146C686.561 442.928 692.483 444.154 693.889 447.888Z"
          fill="#6B3E3D"
        />
        <path
          d="M669.892 476.684C664.185 476.684 659.416 474.653 659.116 474.522C658.169 474.11 657.729 473.006 658.141 472.061C658.553 471.116 659.659 470.676 660.606 471.087C660.709 471.134 670.117 475.102 677.191 471.237C681.277 469.001 683.891 464.499 684.969 457.855C685.138 456.834 686.094 456.142 687.115 456.31C688.136 456.479 688.83 457.433 688.661 458.453C687.387 466.305 684.126 471.714 678.963 474.522C676.011 476.132 672.815 476.674 669.882 476.674L669.892 476.684Z"
          fill="#6B3E3D"
        />
        <path
          d="M698.677 463.76C694.919 463.76 690.843 462.562 686.495 460.176C685.586 459.68 685.258 458.538 685.755 457.63C686.252 456.722 687.395 456.395 688.304 456.891C694.207 460.129 699.37 460.84 703.653 459.006C711.055 455.824 713.717 445.96 713.745 445.867C714.007 444.865 715.019 444.266 716.031 444.528C717.034 444.79 717.634 445.81 717.371 446.802C717.249 447.28 714.251 458.51 705.152 462.431C703.119 463.311 700.954 463.751 698.677 463.751V463.76Z"
          fill="#6B3E3D"
        />
        <path
          d="M729.122 421.226C729.029 421.226 728.935 421.226 728.841 421.207L723.762 420.449C722.741 420.299 722.038 419.345 722.188 418.324C722.338 417.304 723.303 416.593 724.315 416.752L729.385 417.51C730.406 417.66 731.109 418.615 730.959 419.635C730.818 420.561 730.022 421.226 729.113 421.226H729.122Z"
          fill="#6B3E3D"
        />
        <path
          d="M745.053 422.714C746.439 425.465 743.544 429.704 738.596 432.184C733.649 434.664 728.523 434.44 727.136 431.688C725.749 428.937 728.645 424.698 733.592 422.218C738.54 419.738 743.666 419.962 745.053 422.714Z"
          fill="#F4627D"
        />
        <path
          opacity="0.48"
          d="M753.541 362.492C753.541 362.492 762.705 365.823 766.875 374.143"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M770.203 366.647C770.203 366.647 777.7 372.468 780.201 377.465"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M772.705 385.785C772.705 385.785 776.041 389.95 778.534 396.604"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path id="hand"
          d="M786.928 538.16L758.732 524.084C754.572 533.593 745.773 540.443 736.946 546.479C726.039 555.875 705.592 565.702 667.773 572.496C652.592 575.229 645.799 620.786 686.457 625.175C719.095 628.703 754.572 604.221 782.074 570.914C784.127 566.834 786.057 562.698 787.425 558.355C789.636 551.336 789.355 544.692 786.928 538.15V538.16Z"
          fill="#DF8941"
        />
        <path
          opacity="0.48"
          d="M724.568 616.818C724.568 616.818 776.603 596.267 787.36 553.854"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M824.926 566.375C824.926 566.375 799.026 600.375 836.03 629.938C836.03 629.938 834.549 641.028 830.848 646.194"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M819.904 664.649C819.904 664.649 828.928 691.236 869.531 673.212"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M827.568 673.661C827.568 673.661 828.918 663.751 836.592 658.341"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M847.875 678.163C847.875 678.163 847.875 666.895 855.54 662.394"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M656.584 594.357C656.584 594.357 667.866 587.151 674.632 591.204"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.48"
          d="M666.508 615.086C666.508 615.086 673.273 606.074 683.656 607.88"
          stroke="#6B3E3D"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <mask
          id="mask34_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="691"
          y="290"
          width="27"
          height="36"
        >
          <path
            d="M692.015 325.62C691.706 317.992 694.133 307.492 697.515 300.642C699.221 297.188 701.498 293.829 704.852 291.938C708.217 290.048 712.864 290.029 715.6 292.734C717.634 294.746 718.262 297.881 717.774 300.698C717.287 303.515 715.825 306.079 714.176 308.419C709.51 315.016 703.091 320.369 695.744 323.767L692.024 325.61L692.015 325.62Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask34_201_27617)">
          <path
            d="M692.015 325.62C691.706 317.992 694.133 307.492 697.515 300.642C699.221 297.188 701.498 293.829 704.852 291.938C708.217 290.048 712.864 290.029 715.6 292.734C717.634 294.746 718.262 297.881 717.774 300.698C717.287 303.515 715.825 306.079 714.176 308.419C709.51 315.016 703.091 320.369 695.744 323.767L692.024 325.61L692.015 325.62Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M694.928 321.268C694.928 321.268 702.077 303.871 704.636 306.173C707.194 308.475 694.928 321.268 694.928 321.268Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M691.227 327.538C691.227 327.538 699.229 295.232 712.507 288.017"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M689.297 326.995C689.297 326.995 711.187 315.465 717.924 292.771"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask35_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="669"
          y="289"
          width="23"
          height="40"
        >
          <path
            d="M690.197 328.071C683.779 323.925 676.582 315.896 672.927 309.195C671.081 305.807 669.638 302.017 670.032 298.19C670.416 294.353 673.077 290.553 676.882 289.879C679.702 289.383 682.626 290.675 684.65 292.696C686.674 294.718 687.93 297.385 688.885 300.08C691.603 307.688 692.278 316.017 690.835 323.972L690.197 328.071Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask35_201_27617)">
          <path
            d="M690.197 328.071C683.779 323.925 676.582 315.896 672.927 309.195C671.081 305.807 669.638 302.017 670.032 298.19C670.416 294.353 673.077 290.553 676.882 289.879C679.702 289.383 682.626 290.675 684.65 292.696C686.674 294.718 687.93 297.385 688.885 300.08C691.603 307.688 692.278 316.017 690.835 323.972L690.197 328.071Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M688.324 323.186C688.324 323.186 678.204 307.323 681.558 306.565C684.913 305.807 688.324 323.186 688.324 323.186Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M691.323 329.821C691.323 329.821 669.499 304.675 671.242 289.683"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M689.757 331.075C689.757 331.075 692.933 306.565 678.24 287.989"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask36_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="662"
          y="324"
          width="28"
          height="35"
        >
          <path
            d="M689.222 324.543C689.222 332.171 686.392 342.577 682.747 349.287C680.91 352.675 678.502 355.941 675.072 357.701C671.643 359.46 666.995 359.301 664.362 356.484C662.404 354.388 661.907 351.234 662.507 348.436C663.106 345.637 664.662 343.139 666.405 340.865C671.324 334.454 677.959 329.354 685.427 326.247L689.222 324.553V324.543Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask36_201_27617)">
          <path
            d="M689.222 324.543C689.222 332.171 686.392 342.577 682.747 349.287C680.91 352.675 678.502 355.941 675.072 357.701C671.643 359.46 666.995 359.301 664.362 356.484C662.404 354.388 661.907 351.234 662.507 348.436C663.106 345.637 664.662 343.139 666.405 340.865C671.324 334.454 677.959 329.354 685.427 326.247L689.222 324.553V324.543Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M686.128 328.783C686.128 328.783 678.304 345.89 675.84 343.485C673.375 341.08 686.128 328.783 686.128 328.783Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M690.073 322.663C690.073 322.663 680.806 354.631 667.266 361.313"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M691.985 323.28C691.985 323.28 669.665 333.94 662.037 356.353"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask37_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="652"
          y="315"
          width="41"
          height="20"
        >
          <path
            d="M692.268 328.333C685.512 331.89 674.97 334.229 667.333 334.126C663.473 334.07 659.462 333.471 656.304 331.262C653.146 329.045 651.132 324.871 652.397 321.23C653.343 318.526 655.901 316.616 658.665 315.849C661.42 315.072 664.363 315.288 667.193 315.774C675.167 317.141 682.767 320.622 688.998 325.778L692.268 328.343V328.333Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask37_201_27617)">
          <path
            d="M692.268 328.333C685.512 331.89 674.97 334.229 667.333 334.126C663.473 334.07 659.462 333.471 656.304 331.262C653.146 329.045 651.132 324.871 652.397 321.23C653.343 318.526 655.901 316.616 658.665 315.849C661.42 315.072 664.363 315.288 667.193 315.774C675.167 317.141 682.767 320.622 688.998 325.778L692.268 328.343V328.333Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M687.086 327.575C687.086 327.575 668.289 328.624 669.263 325.32C670.247 322.026 687.086 327.575 687.086 327.575Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M694.338 328.212C694.338 328.212 661.701 334.903 649.463 326.041"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M694.676 330.187C694.676 330.187 674.839 315.419 651.422 319.115"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask38_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="687"
          y="318"
          width="41"
          height="20"
        >
          <path
            d="M687.303 325.769C693.89 321.913 704.32 319.096 711.957 318.853C715.808 318.731 719.847 319.153 723.098 321.221C726.35 323.289 728.561 327.379 727.456 331.066C726.631 333.808 724.157 335.829 721.44 336.728C718.722 337.626 715.77 337.542 712.931 337.186C704.901 336.185 697.151 333.041 690.695 328.184L687.312 325.769H687.303Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask38_201_27617)">
          <path
            d="M687.303 325.769C693.89 321.913 704.32 319.096 711.957 318.853C715.808 318.731 719.847 319.153 723.098 321.221C726.35 323.289 728.561 327.379 727.456 331.066C726.631 333.808 724.157 335.829 721.44 336.728C718.722 337.626 715.77 337.542 712.931 337.186C704.901 336.185 697.151 333.041 690.695 328.184L687.312 325.769H687.303Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M692.523 326.293C692.523 326.293 711.255 324.394 710.431 327.734C709.606 331.075 692.523 326.293 692.523 326.293Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M685.242 325.984C685.242 325.984 717.542 317.824 730.165 326.125"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M684.811 324.028C684.811 324.028 705.304 337.879 728.515 333.134"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask39_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="688"
          y="323"
          width="21"
          height="40"
        >
          <path
            d="M690.853 323.542C696.953 328.146 703.55 336.672 706.699 343.625C708.292 347.135 709.454 351.019 708.779 354.809C708.114 358.599 705.181 362.202 701.348 362.595C698.5 362.885 695.679 361.378 693.805 359.217C691.931 357.055 690.881 354.303 690.113 351.543C687.967 343.756 687.892 335.399 689.916 327.576L690.853 323.533V323.542Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask39_201_27617)">
          <path
            d="M690.853 323.542C696.953 328.146 703.55 336.672 706.699 343.625C708.292 347.135 709.454 351.019 708.779 354.809C708.114 358.599 705.181 362.202 701.348 362.595C698.5 362.885 695.679 361.378 693.805 359.217C691.931 357.055 690.881 354.303 690.113 351.543C687.967 343.756 687.892 335.399 689.916 327.576L690.853 323.533V323.542Z"
            fill="#F7ACC0"
          />
          <path
            opacity="0.35"
            d="M692.371 328.549C692.371 328.549 701.301 345.104 697.9 345.619C694.498 346.133 692.371 328.549 692.371 328.549Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M689.859 321.717C689.859 321.717 709.791 348.388 706.951 363.212"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M691.51 320.575C691.51 320.575 686.553 344.786 699.85 364.382"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask40_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="684"
          y="287"
          width="20"
          height="41"
        >
          <path
            d="M690.461 327.295C686.918 320.528 684.613 310 684.735 302.373C684.801 298.517 685.41 294.512 687.64 291.367C689.87 288.223 694.059 286.22 697.695 287.502C700.393 288.457 702.305 291.021 703.064 293.782C703.832 296.543 703.607 299.472 703.111 302.298C701.724 310.262 698.201 317.843 693.028 324.047L690.451 327.304L690.461 327.295Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask40_201_27617)">
          <path
            d="M690.461 327.295C686.918 320.528 684.613 310 684.735 302.373C684.801 298.517 685.41 294.512 687.64 291.367C689.87 288.223 694.059 286.22 697.695 287.502C700.393 288.457 702.305 291.021 703.064 293.782C703.832 296.543 703.607 299.472 703.111 302.298C701.724 310.262 698.201 317.843 693.028 324.047L690.451 327.304L690.461 327.295Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M702.913 298.929C703.11 299.088 703.307 299.256 703.494 299.415C703.644 297.506 703.579 295.597 703.072 293.763C702.304 291.002 700.402 288.438 697.703 287.483C694.067 286.201 689.879 288.204 687.649 291.348C686.637 292.771 685.962 294.371 685.521 296.056C691.359 294.689 698.237 295.101 702.923 298.919L702.913 298.929Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M690.684 326.303C688.501 322.147 687.067 315.69 687.133 311.02C687.161 308.662 687.536 306.21 688.885 304.282C690.244 302.364 692.802 301.138 695.032 301.933C696.691 302.523 697.862 304.095 698.331 305.789C698.809 307.483 698.678 309.279 698.378 311.001C697.544 315.877 695.407 320.51 692.249 324.309L690.684 326.303Z"
              fill="white"
            />
          </g>
          <path
            d="M691.238 322.11C691.238 322.11 690.245 303.327 693.543 304.319C696.842 305.311 691.238 322.11 691.238 322.11Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M690.573 329.363C690.573 329.363 683.976 296.748 692.878 284.554"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M688.596 329.69C688.596 329.69 703.439 309.925 699.812 286.529"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask41_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="676"
          y="325"
          width="20"
          height="41"
        >
          <path
            d="M689.916 325.32C693.458 332.086 695.763 342.614 695.641 350.242C695.576 354.097 694.967 358.103 692.737 361.247C690.506 364.392 686.318 366.394 682.682 365.112C679.983 364.158 678.072 361.593 677.313 358.833C676.544 356.072 676.769 353.143 677.266 350.316C678.653 342.352 682.167 334.772 687.348 328.567L689.925 325.311L689.916 325.32Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask41_201_27617)">
          <path
            d="M689.916 325.32C693.458 332.086 695.763 342.614 695.641 350.242C695.576 354.097 694.967 358.103 692.737 361.247C690.506 364.392 686.318 366.394 682.682 365.112C679.983 364.158 678.072 361.593 677.313 358.833C676.544 356.072 676.769 353.143 677.266 350.316C678.653 342.352 682.167 334.772 687.348 328.567L689.925 325.311L689.916 325.32Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M677.462 353.676C677.265 353.517 677.069 353.349 676.881 353.189C676.731 355.099 676.797 357.008 677.303 358.842C678.071 361.603 679.974 364.167 682.672 365.122C686.308 366.404 690.497 364.401 692.727 361.256C693.739 359.834 694.414 358.234 694.854 356.549C689.016 357.915 682.138 357.504 677.453 353.685L677.462 353.676Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M689.691 326.312C691.874 330.467 693.308 336.925 693.242 341.594C693.214 343.953 692.839 346.405 691.49 348.333C690.131 350.251 687.573 351.477 685.343 350.682C683.684 350.092 682.513 348.52 682.044 346.826C681.567 345.132 681.698 343.335 681.998 341.613C682.832 336.737 684.968 332.105 688.126 328.305L689.691 326.312Z"
              fill="white"
            />
          </g>
          <path
            d="M689.148 330.505C689.148 330.505 690.141 349.287 686.843 348.295C683.544 347.303 689.148 330.505 689.148 330.505Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M689.803 323.252C689.803 323.252 696.4 355.866 687.498 368.06"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M691.789 322.924C691.789 322.924 676.946 342.689 680.573 366.086"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask42_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="689"
          y="304"
          width="38"
          height="24"
        >
          <path
            d="M689.531 327.089C693.064 320.322 700.392 312.405 706.736 308.147C709.94 305.995 713.576 304.207 717.437 304.235C721.297 304.263 725.336 306.556 726.367 310.262C727.126 313.023 726.114 316.046 724.287 318.254C722.459 320.463 719.92 321.951 717.324 323.158C709.987 326.565 701.75 328.024 693.692 327.323L689.541 327.079L689.531 327.089Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask42_201_27617)">
          <path
            d="M689.531 327.089C693.064 320.322 700.392 312.405 706.736 308.147C709.94 305.995 713.576 304.207 717.437 304.235C721.297 304.263 725.336 306.556 726.367 310.262C727.126 313.023 726.114 316.046 724.287 318.254C722.459 320.463 719.92 321.951 717.324 323.158C709.987 326.565 701.75 328.024 693.692 327.323L689.541 327.079L689.531 327.089Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M719.949 321.09C719.93 321.343 719.902 321.595 719.874 321.848C721.523 320.884 723.06 319.733 724.278 318.273C726.106 316.065 727.127 313.042 726.359 310.281C725.328 306.566 721.289 304.282 717.428 304.254C715.686 304.245 713.98 304.6 712.35 305.199C716.81 309.205 720.399 315.072 719.94 321.099L719.949 321.09Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M690.478 326.714C692.643 322.55 697.122 317.683 701.001 315.063C702.96 313.743 705.181 312.639 707.542 312.648C709.894 312.658 712.368 314.062 712.986 316.345C713.445 318.039 712.827 319.901 711.702 321.258C710.587 322.615 709.032 323.532 707.439 324.281C702.96 326.387 697.928 327.285 692.999 326.864L690.469 326.714H690.478Z"
              fill="white"
            />
          </g>
          <path
            d="M694.236 324.768C694.236 324.768 709.098 313.219 710.176 316.495C711.253 319.77 694.236 324.768 694.236 324.768Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M687.902 328.371C687.902 328.371 710.926 304.319 726.04 304.647"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M686.496 326.93C686.496 326.93 711.225 327.8 728.364 311.451"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask43_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="653"
          y="325"
          width="38"
          height="24"
        >
          <path
            d="M690.844 325.526C687.311 332.292 679.983 340.209 673.64 344.467C670.435 346.62 666.799 348.407 662.938 348.379C659.078 348.351 655.039 346.058 654.008 342.352C653.249 339.592 654.261 336.569 656.088 334.36C657.916 332.152 660.455 330.664 663.051 329.456C670.388 326.05 678.625 324.59 686.683 325.292L690.834 325.535L690.844 325.526Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask43_201_27617)">
          <path
            d="M690.844 325.526C687.311 332.292 679.983 340.209 673.64 344.467C670.435 346.62 666.799 348.407 662.938 348.379C659.078 348.351 655.039 346.058 654.008 342.352C653.249 339.592 654.261 336.569 656.088 334.36C657.916 332.152 660.455 330.664 663.051 329.456C670.388 326.05 678.625 324.59 686.683 325.292L690.834 325.535L690.844 325.526Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M660.427 331.525C660.446 331.272 660.474 331.019 660.502 330.767C658.853 331.731 657.316 332.882 656.098 334.342C654.271 336.55 653.25 339.573 654.018 342.334C655.049 346.049 659.087 348.332 662.948 348.361C664.691 348.37 666.396 348.014 668.027 347.415C663.566 343.41 659.978 337.542 660.437 331.515L660.427 331.525Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M689.897 325.9C687.733 330.064 683.253 334.931 679.374 337.551C677.416 338.871 675.195 339.975 672.833 339.966C670.481 339.956 668.008 338.553 667.389 336.269C666.93 334.575 667.548 332.713 668.673 331.356C669.788 329.999 671.344 329.082 672.937 328.333C677.416 326.227 682.448 325.329 687.376 325.75L689.907 325.9H689.897Z"
              fill="white"
            />
          </g>
          <path
            d="M686.149 327.847C686.149 327.847 671.287 339.395 670.209 336.12C669.132 332.853 686.149 327.847 686.149 327.847Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M692.474 324.244C692.474 324.244 669.451 348.295 654.336 347.967"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M693.879 325.685C693.879 325.685 669.15 324.814 652.012 341.164"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask44_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="689"
          y="326"
          width="35"
          height="27"
        >
          <path
            d="M689.186 326.106C696.823 325.966 707.289 328.605 714.074 332.133C717.494 333.911 720.811 336.251 722.629 339.648C724.447 343.045 724.372 347.687 721.598 350.363C719.537 352.356 716.388 352.909 713.577 352.356C710.766 351.804 708.236 350.298 705.931 348.594C699.418 343.793 694.199 337.271 690.957 329.868L689.186 326.115V326.106Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask44_201_27617)">
          <path
            d="M689.186 326.106C696.823 325.966 707.289 328.605 714.074 332.133C717.494 333.911 720.811 336.251 722.629 339.648C724.447 343.045 724.372 347.687 721.598 350.363C719.537 352.356 716.388 352.909 713.577 352.356C710.766 351.804 708.236 350.298 705.931 348.594C699.418 343.793 694.199 337.271 690.957 329.868L689.186 326.115V326.106Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M709.024 349.904C708.789 350.007 708.555 350.11 708.33 350.204C709.97 351.196 711.713 351.982 713.578 352.347C716.389 352.899 719.537 352.347 721.599 350.354C724.372 347.677 724.447 343.035 722.63 339.638C721.805 338.103 720.671 336.784 719.369 335.633C717.982 341.463 714.543 347.415 709.024 349.904Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M689.973 326.752C694.667 326.658 701.095 328.258 705.256 330.401C707.355 331.487 709.388 332.91 710.503 334.978C711.619 337.046 711.562 339.882 709.857 341.519C708.592 342.736 706.652 343.082 704.928 342.745C703.204 342.408 701.648 341.491 700.233 340.452C696.242 337.523 693.037 333.546 691.05 329.026L689.973 326.733V326.752Z"
              fill="white"
            />
          </g>
          <path
            d="M693.486 329.11C693.486 329.11 710.747 336.615 708.395 339.123C706.033 341.631 693.486 329.11 693.486 329.11Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M687.283 325.282C687.283 325.282 719.452 333.967 726.386 347.368"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M687.873 323.373C687.873 323.373 698.949 345.468 721.523 352.684"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask45_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="656"
          y="300"
          width="36"
          height="27"
        >
          <path
            d="M691.191 326.509C683.554 326.649 673.087 324.01 666.303 320.482C662.882 318.704 659.565 316.364 657.747 312.967C655.929 309.57 656.004 304.928 658.778 302.252C660.84 300.258 663.988 299.706 666.799 300.258C669.61 300.81 672.14 302.317 674.446 304.02C680.958 308.821 686.177 315.344 689.42 322.747L691.191 326.499V326.509Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask45_201_27617)">
          <path
            d="M691.191 326.509C683.554 326.649 673.087 324.01 666.303 320.482C662.882 318.704 659.565 316.364 657.747 312.967C655.929 309.57 656.004 304.928 658.778 302.252C660.84 300.258 663.988 299.706 666.799 300.258C669.61 300.81 672.14 302.317 674.446 304.02C680.958 308.821 686.177 315.344 689.42 322.747L691.191 326.499V326.509Z"
            fill="#F4627D"
          />
          <g opacity="0.21">
            <path
              d="M671.353 302.701C671.588 302.598 671.822 302.495 672.047 302.401C670.407 301.409 668.664 300.623 666.799 300.258C663.988 299.706 660.84 300.258 658.778 302.252C656.004 304.928 655.929 309.57 657.747 312.967C658.572 314.502 659.706 315.821 661.008 316.972C662.395 311.142 665.834 305.19 671.353 302.701Z"
              fill="white"
            />
          </g>
          <g opacity="0.28">
            <path
              d="M690.405 325.863C685.71 325.957 679.282 324.356 675.121 322.213C673.022 321.128 670.989 319.705 669.874 317.637C668.759 315.569 668.815 312.733 670.521 311.095C671.786 309.879 673.725 309.533 675.449 309.869C677.174 310.197 678.729 311.124 680.144 312.162C684.136 315.092 687.341 319.069 689.327 323.589L690.405 325.882V325.863Z"
              fill="white"
            />
          </g>
          <path
            d="M686.9 323.505C686.9 323.505 669.64 315.999 671.992 313.491C674.344 310.983 686.9 323.505 686.9 323.505Z"
            fill="#F4627D"
          />
          <path
            opacity="0.69"
            d="M693.094 327.332C693.094 327.332 660.924 318.647 653.99 305.246"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.69"
            d="M692.503 329.241C692.503 329.241 681.427 307.146 658.854 299.931"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M119.503 903H745.428C742.317 884.33 734.474 851.491 718.703 840.242C696.289 824.239 664.729 823.65 641.78 838.801C641.387 800.085 624.417 760.695 593.644 739.124C562.871 717.553 518.314 717.234 490.202 742.455C474.591 707.651 456.29 672.491 426.698 649.647C388.85 620.421 334.595 616.546 293.29 640.111C251.984 663.685 225.775 713.463 228.848 762.539C196.126 736.064 144.382 740.237 115.943 771.663C87.5124 803.08 86.4067 857.303 113.544 889.954L119.494 903.01L119.503 903Z"
          fill="white"
        />
        <g opacity="0.17">
          <path
            d="M223.62 903H632.429C630.396 890.806 625.27 869.357 614.972 862.01C600.326 851.557 579.72 851.173 564.736 861.074C564.474 835.788 553.398 810.061 533.298 795.967C513.198 781.883 484.103 781.677 465.736 798.148C455.541 775.416 443.584 752.45 424.262 737.533C399.543 718.442 364.103 715.915 337.126 731.31C310.148 746.704 293.028 779.216 295.043 811.268C273.678 793.974 239.869 796.707 221.306 817.23C202.733 837.753 202.021 873.165 219.741 894.484L223.63 903.01L223.62 903Z"
            fill="#F7ACC0"
          />
        </g>
        <mask
          id="mask46_201_27617"
          mask="luminance"
          maskUnits="userSpaceOnUse"
          x="870"
          y="761"
          width="260"
          height="142"
        >
          <path
            d="M1129.97 761.921H870.326V903H1129.97V761.921Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask46_201_27617)">
          <path
            d="M1184.22 903H896.441C897.875 894.418 901.483 879.323 908.726 874.148C919.034 866.792 933.539 866.521 944.091 873.484C944.269 855.684 952.074 837.575 966.224 827.655C980.373 817.735 1000.86 817.595 1013.78 829.19C1020.96 813.187 1029.37 797.025 1042.98 786.525C1060.38 773.086 1085.32 771.308 1104.32 782.145C1123.31 792.982 1135.35 815.873 1133.95 838.427C1148.99 826.251 1172.78 828.179 1185.86 842.619C1198.93 857.069 1199.44 881.99 1186.97 897.001L1184.23 903H1184.22Z"
            fill="white"
          />
          <g opacity="0.17">
            <path
              d="M1136.35 903H948.4C949.337 897.394 951.689 887.531 956.422 884.152C963.15 879.351 972.633 879.173 979.52 883.722C979.642 872.098 984.73 860.269 993.969 853.793C1003.21 847.317 1016.59 847.224 1025.03 854.795C1029.72 844.341 1035.22 833.785 1044.1 826.925C1055.47 818.147 1071.76 816.986 1084.16 824.061C1096.57 831.136 1104.43 846.091 1103.51 860.822C1113.33 852.867 1128.88 854.13 1137.41 863.564C1145.95 872.997 1146.28 889.281 1138.14 899.079L1136.35 903H1136.35Z"
              fill="#F7ACC0"
            />
          </g>
        </g>
        {/* <animate
          target="#heart"
          begin="0s"
          type="scale"
          dur="2s"
          from="1"
          to=".5"
          repeatCount="indefinite"
        /> */}
        {/* <animateTransform
          target="#heart"

          attributeName="transform"
          begin="0s"
          type="scale"
          dur="2s"
          from="1"
          to=".5"
          repeatCount="indefinite"
        /> */}
      </svg>
    </>
  );
};
